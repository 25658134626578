<template>
    <AlertsComponent :errors="errors" />
    <MessageCard :message="messageCardInfo" type="danger" iconClass="iatse-icon-alarm"
        classes="info_card_full" />
    <LoaderComponent v-if="loading" />
        <div v-else class="plan-content justify-content-between">
            <div class="d-flex justify-content-center align-items-center w-100 mt-20 mb-20 pdl-5 pdr-5">
                <div class="d-flex gap-5 flex-wrap">
                    <p v-for="step in steps" :key="step.id"
                        :class="['fw-semibold', 'ribbon ribbon_secondary', currentStep === step.id ? 'active text-white' : 'text-dark', currentStep > step.id ? 'completed text-white' : 'text-dark']">
                        <span class="w-100 text-center">{{ step.label }}</span>
                    </p>
                </div>
            </div>
            <CoverageStep1 v-if="currentStep === 1" @handle-next="handleNext" />
            <CoverageStep2 v-if="currentStep === 2" :ipAddress="ipAddress" :download="download"
                @handle-back="handleBack" @handleNext="handleNext" />
            <DownloadStep v-if="currentStep === 3 && Number(download)" :requests="requests" />
            <CoverageStep3 v-if="currentStep === 3 && !Number(download)" @handle-back="handleBack"
                @handleNext="handleNext" />
            <CoverageStep4 v-if="currentStep === 4" @handle-back="handleBack" @handle-submit="handleSubmit"
                :ssnOption="ssnOption" :excelModel="excelModel" :submitting="submitting" />
    </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { HealthCoverageRequest } from '@/interfaces/employer';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import CoverageStep1 from '@/components/employer/coverage-data/CoverageStep1.vue'
import CoverageStep2 from '@/components/employer/coverage-data/CoverageStep2.vue'
import CoverageStep3 from '@/components/employer/coverage-data/CoverageStep3.vue'
import CoverageStep4 from '@/components/employer/coverage-data/CoverageStep4.vue'
import DownloadStep from '@/components/employer/coverage-data/DownloadStep.vue'
import axios from 'axios';

export default {
    components: {
        CoverageStep1,
        CoverageStep2,
        CoverageStep3,
        CoverageStep4,
        DownloadStep,
    },
    setup() {
        const loading = ref(true);
        const submitting = ref(false);
        const deleting = ref(false);
        const currentStep = ref(1);
        const success = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const ipAddress = ref('');
        const instructions = ref('#');
        const excelModel = ref('#');
        const ssnOption: Ref<string> = ref('0');
        const download = useModalStore().currentModal?.queryParams?.download;
        const requests: Ref<HealthCoverageRequest[]> = ref([]);
        const router = useRouter();
        const authStore = useAuthStore();
        const unreadAlerts: Ref<number> = ref(0);
        const steps = computed(() => {
            if(Number(download)) {
                return [
                    { id: 1, label: 'Step 01' },
                    { id: 2, label: 'Step 02' },
                    { id: 3, label: 'Step 03' },
                ];
            }
            return  [
                { id: 1, label: 'Step 01' },
                { id: 2, label: 'Step 02' },
                { id: 3, label: 'Step 03' },
                { id: 4, label: 'Step 04' },
            ];
        })

        const messageCardInfo = computed(() => {
            switch (currentStep.value) {
                case 1:
                    return `You must read and digitally sign the certification below in order to receive employee-related health
                        coverage information. </br> Please Note: You will have to digitally sign this certification every time you request
                        health coverage information. </br> Click <a href="${instructions.value}" target="_blank">here</a> to read the instructions for obtaining your employees' health
                        coverage
                        information.`;
                case 2:
                    return "You must read and digitally sign the certification below in order to receive employee-related health coverage information. <br />Please Note: You will have to digitally sign this certification every time you request health coverage information.";
                case 3:
                    return download ? "This page allows you to view all requests you have made regarding the employer mandate reporting for the past 24 months. You can also download the output files for each of these requests." : "Please choose how you will be providing your employees' social security numbers in order to obtain health coverage information.";
                case 4:
                    return "This page will guide you through uploading Excel file with your employees' social security numbers for obtaining health coverage information. Please note: Each Excel request file can contain up to 25,000 social security numbers. If you have more than 25,000 social security numbers then you will need to return to the site to upload each additional request file.";
                default:
                    return "Please follow the steps carefully.";
            }
        });

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/employer/health-coverage-data')
                .then(response => {
                    ipAddress.value = response.data.Ip;
                    instructions.value = response.data.Instructions;
                    excelModel.value = response.data.ExcelModel;
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const getUnreadAlerts = async () => {
            await axios.get('api/user/unread-alerts')
                .then(response => {
                    unreadAlerts.value = response.data;
                });
        }

        const handleSubmit = async (payload: object) => {
            submitting.value = true;
            success.value = null;
            errors.value = [];

            await axios.post('api/employer/health-coverage-data/generate-file', { ...payload })
                .then(response => {
                    success.value = response.data.success;

                    if (success.value) {
                        useModalStore().openModal('success-modal', null, 'modal-success', null, {
                            title: 'Thank You! Your request was successfully submitted.',
                            content: {
                                type: "text",
                                value: 'You will be notified via email when your file is ready for download. Please follow the instructions in the email to download the file.',
                                icon: 'iatse-icon-user-verified'
                            },
                        })

                        errors.value = [];
                    }
                    else {
                        errors.value = response.data.error;
                    }
                })
                .then(() => {
                    getUnreadAlerts();
                    authStore.updateUnreadAlerts(unreadAlerts.value);
                })
                .catch(error => {
                    const res = error.response.data;

                    if (res.error) {
                        errors.value = res.error;
                    }
                    else {
                        errors.value = Object.values(res.errors).flat() as string[];
                    }
                })
                .finally(() => {
                    submitting.value = false;
                    setTimeout(() => errors.value = [], 5000)
                });
        };

        const handleNext = (payload: HealthCoverageRequest[] | string | null) => {
            currentStep.value += 1;
            handlePayload(payload);
        }

        const handleBack = (payload: HealthCoverageRequest[] | string | null) => {
            currentStep.value -= 1;
            handlePayload(payload);
        }

        const handlePayload = (payload: HealthCoverageRequest[] | string | null) => {
            if (currentStep.value === 3) {
                requests.value = payload as HealthCoverageRequest[];
            }

            if (currentStep.value === 4) {
                ssnOption.value = payload as string;
            }
        }

        onMounted(async () => await fetchData());

        return {
            loading,
            submitting,
            deleting,
            steps,
            ipAddress,
            instructions,
            messageCardInfo,
            excelModel,
            ssnOption,
            success,
            errors,
            download,
            requests,
            currentStep,
            handleNext,
            handleBack,
            handleSubmit,
        };
    }
}
</script>