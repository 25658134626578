<template>
    <div class="filters mt-30 w-100 mb-20 ml-10 sm-ml-0 d-flex gap-20">
        <TextInput type="text" name="mixed" placeholder="Search by Document Name" :max=50 v-model:modelValue="searchTerm" customClass="search" />
        <SelectComponent v-if="isParticipant" v-model:selectedOption="fund" :options="funds" customClass="w-50" :readonly="fundProp"/>
    </div>
    <div class="bordered-card bordered-card_secondary gap-20 p-2 pdt-15 pdb-35 ml-10 sm-ml-0 overflow-auto">
        <h2 class="bg-light-green w-100 pdl-20 pdt-25 pdb-25 rounded"> Plan Forms & Documents</h2>
        <LoaderComponent v-if="loading" />
        <p v-else-if="!Object.values(filteredValues)?.length" class="w-100 text-center fs-sm">No resources have been found.</p>
        <template v-else>
            <template v-for="(resourceGroup, mainKey, index) in filteredValues" :key="mainKey">
                <div class="primary-group primary-group_page d-flex justify-content-between align-items-center w-100 plan-modal pdl-20" @click="toggleGroup(mainKey)">
                    <h5 class="fw-bold fs-base"> {{ mainKey }} {{ isLocal ? 'Documents' : '' }} </h5>
                    <!-- Toggle secondary group items on click -->
                    <button class="toggle-btn">
                        <span v-if="!showGroup[mainKey]" class="fs-xl lh-1">+</span>
                        <span v-else class="fs-xl lh-1">-</span>
                    </button>
                </div>
                <transition name="slide-fade">
                    <div v-if="showGroup[mainKey]"
                        class="secondary-group d-flex flex-md-row flex-column w-100 pdr-0 pdl-20">
                        <template v-for="(documents, secondaryKey, secondaryIndex) in resourceGroup" :key="secondaryKey">
                            <div class="secondary_group_item d-flex flex-column gap-20">
                                <h5 class="fw-semibold fs-base"> {{ secondaryKey }} </h5>
                                    <!-- Display limited documents initially -->
                                    <template  v-for="(doc, docIndex) in getVisibleDocuments(documents, mainKey, secondaryKey)"  :key="docIndex">
                                        <a :href="doc.file_url" target="_blank"
                                            :class="{'fade-in': isExpanded(mainKey, secondaryKey) && docIndex >= 3, 'fade-out': !isExpanded(mainKey, secondaryKey) && docIndex > 3}"
                                            class="document-item gap-10">
                                                <i class="iatse-icon-pdf d-flex">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                </i>
                                                <span class="fs-base">{{ doc.title }}</span>
                                        </a>
                                    </template>
                                <!-- Expand/Collapse Button -->
                                <button v-if="documents.length > 3" @click="toggleDocs(mainKey, secondaryKey)"
                                        class="btn btn-link p-0 justify-content-start" :class="{'bounce-up': isExpanded(mainKey, secondaryKey), 'bounce-down': !isExpanded(mainKey, secondaryKey)}">
                                        <i class="iatse-icon-select-arrow fs-xxs text-primary"></i>
                                    {{ isExpanded(mainKey, secondaryKey) ? 'View Less' : 'View More' }}
                                </button>

                            </div>
                            <div v-if="secondaryIndex < Object.entries(resourceGroup).length - 1" class="divider divider_vertical h-100 m-0"></div>
                        </template>
                    </div>
                    <!-- <div v-else-if="showGroup[mainKey]"
                        class="secondary-group secondary-group_columns flex-md-row flex-column w-100 pdr-0 pdl-20">
                        <template v-for="(doc, docIndex) in getVisibleDocuments(resourceGroup, mainKey, docIndex)"  :key="docIndex">
                            <div class="secondary_group_item d-flex flex-column gap-20">
                                <a :href="doc.file_url" target="_blank"
                                    :class="{'fade-in': isExpanded(mainKey, secondaryKey) && docIndex >= 3, 'fade-out': !isExpanded(mainKey, secondaryKey) && docIndex > 3}"
                                    class="document-item gap-10">
                                        <i class="iatse-icon-pdf d-flex">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                        <span class="fs-base">{{ doc.title }}</span>
                                </a>
                            </div>
                            <div v-if="secondaryIndex < Object.entries(resourceGroup).length - 1" class="divider divider_vertical h-100 m-0"></div>
                        </template>
                    </div> -->
                </transition>
                <div v-if="index < Object.entries(resources).length - 1" class="divider m-0"></div>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
import { SelectOption } from "@/interfaces/interfaces";
import { useModalStore } from "@/stores/modal";
import { onMounted, ref, watch, Ref, computed } from 'vue';
import { getFundResources, getFunds } from '@/services/publicService';
import { useOptionsFormatter } from "@/utils/optionFormatter";
import { useAuthStore } from '@/stores/auth';
import SelectComponent from '@components/form/SelectComponent.vue';
import TextInput from '@components/form/TextInput.vue';


export default {
    components: {
        TextInput,
        SelectComponent
    },
    setup() {
        const loading = ref(true);
        const resources: Ref<Record<string, any[]> | undefined> = ref({});
        const showGroup = ref<Record<string, boolean>>({});
        const visibleDocs = ref<{ [key: string]: { [key: string]: boolean } }>({});
        const searchTerm = ref('');
        const fundProp = useModalStore().currentModal?.fund
        const fund = ref<SelectOption>({key: 0, value: 'Select Fund'});
        const funds = ref<SelectOption[]>([{key: 0, value: 'Select Fund'}]);
        const filteredValues: Ref<Record<string, any[]> | undefined> = ref({});
        const authStore = useAuthStore();
        const isLocal = computed(() => authStore.isLocal);
        const isEmployer = computed(() => authStore.isEmployer);
        const isParticipant = computed(() => authStore.isParticipant);
        let searchTimeout: number;

        const fetchData = async (reload?: boolean) => {
            loading.value = true;
            filteredValues.value = await getFundResources();

            if(isParticipant.value) {
                const response = await getFunds();
                funds.value = useOptionsFormatter(response);
            }

            if (fundProp) {
                const matchedFund = funds.value.find(fundItem => fundItem.value.toLowerCase() === fundProp.toLowerCase());
                fund.value = matchedFund ?? { key: 0, value: 'Select Fund'};
            } else {
                loading.value = false;
            }
        };

        onMounted(async () => await fetchData());

        watch(fund, async () => {
            if (searchTimeout) clearTimeout(searchTimeout);

            searchTimeout = setTimeout(async () => {
                try {
                    if (fund.value.key) {
                        const queryParams = {
                            fund: fund.value.key,
                            search: searchTerm.value
                        };

                        filteredValues.value = await getFundResources(queryParams);
                    } else {
                        await fetchData(true);
                    }

                    toggleCategoriesAfterSearch();
                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
                finally {
                    loading.value = false; // Set loading to false after request completes
                }
            }, 300); // Debounce delay of 300ms
        });

        watch(searchTerm, async () => {
            if (searchTimeout) clearTimeout(searchTimeout);

            searchTimeout = setTimeout(async () => {
                try {
                    if (searchTerm.value.length > 2) {
                        const queryParams = {
                            fund: fund.value.key,
                            search: searchTerm.value
                        };

                        filteredValues.value = await getFundResources(queryParams);
                    } else {
                        await fetchData(true);
                    }

                    toggleCategoriesAfterSearch();
                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
            }, 300); // Debounce delay of 300ms
        });

        // Automatically expand categories containing search results
        const expandedCategories = new Set();

        const toggleCategoriesAfterSearch = () => {
            if (!filteredValues.value) return;

            Object.entries(filteredValues.value).forEach(([categoryKey, items]) => {
                if (categoryKey && items.length > 0 && !expandedCategories.has(categoryKey)) {
                    toggleGroup(categoryKey, true);
                    expandedCategories.add(categoryKey);
                }
            });

            // Remove keys that no longer exist in results
            for (const key of expandedCategories) {
                if (!filteredValues.value[key as string] || filteredValues.value[key as string].length === 0) {
                    expandedCategories.delete(key);
                }
            }
        };
        // Toggle the visibility of secondary items for each group
        const toggleGroup = (mainKey: string, visible?: boolean) => {
            if(visible) {
                showGroup.value[mainKey] = true;
            } else  {
                showGroup.value[mainKey] = !showGroup.value[mainKey];
            }
        };

        // Get visible documents (limit to 3 or all based on toggle state)
        const getVisibleDocuments = (documents: any[], mainKey: string, secondaryKey: string) => {
            const expanded = isExpanded(mainKey, secondaryKey);

            console.log('expanded', expanded, mainKey, secondaryKey, documents);

            return expanded ? documents : documents?.slice(0, 3);
        };

        // Check if a category is expanded
        const isExpanded = (mainKey: string, secondaryKey?: string) => {
            if(secondaryKey) {
                return visibleDocs.value[mainKey]?.[secondaryKey] || false;
            }

            return visibleDocs.value[mainKey] || false;
        };

        // Toggle documents visibility for a category
        const toggleDocs = (mainKey: string, secondaryKey: string) => {
            if (!visibleDocs.value[mainKey]) visibleDocs.value[mainKey] = {};

            visibleDocs.value[mainKey][secondaryKey] = !isExpanded(mainKey, secondaryKey);
        };

        return {
            fund,
            fundProp,
            funds,
            isLocal,
            isEmployer,
            isParticipant,
            loading,
            showGroup,
            searchTerm,
            resources,
            filteredValues,
            toggleGroup,
            getVisibleDocuments,
            toggleDocs,
            isExpanded
        };
    }
};
</script>

