<template>
    <div :class="['info_card', cardTypeClass, classes]">
        <div class="icon_wrapper">
            <i :class="iconClass"> </i>
        </div>
        <p v-html="message"></p>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: {
        message: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'info',
        },
        iconClass: {
            type: String,
            default: 'iatse-icon-alarm',
        },
        classes: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const cardTypeClass = computed(() => `info_card__${props.type}`);

        return { cardTypeClass }
    }
});
</script>
