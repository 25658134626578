<template>
    <div id="fund-alert-overlay" class="overlay" :class="classNames">
        <div class="overlay-content">
            <div class="alert-icon"> <i class="iatse-icon-alert"></i> </div>
            <h2>{{ alerts.length }} Unread Alerts</h2>
            <a href="" class="btn btn-primary plan-modal" @click.prevent="openModal('alerts', 'Alerts', null)">
                View Alerts
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import { useModalStore } from '@/stores/modal';
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        alerts: {
            type: Array,
            required: true
        },
        classNames: {
            type: String,
            required: false
        }
    },
    setup() {
        const modalStore = useModalStore();

        return {
            openModal: modalStore.openModal
        }
    }
})
</script>