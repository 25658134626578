<template>
    <div>
        <form>
            <TextInput type="text" name="mixed" placeholder="Search by NBF Participant ID, First or Last Name" :max=100
                v-model="password" />
            <button class="btn btn-primary">Reset</button>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, watch, defineAsyncComponent, Component, computed } from 'vue';
import axios from 'axios';
import TextInput from '@components/form/TextInput.vue';

export default {
    setup() {
        // Setup variables
        const password = ref('');

        return {
            password
        }
    },

    components: {
        TextInput
    }
};
</script>
