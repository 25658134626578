<template>
    <!-- Contribution Tables -->
    <div class="contributions-tables cobra-payments">
        <div class="contributions-table-wrapper">
            <div class="contributions-table-item no-striped">
                <h4>{{ title }}</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Invoice #</th>
                            <th>Type</th>
                            <th>Coverage Period</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Balance</th>
                            <th>View Invoice</th>
                        </tr>
                    </thead>
                    <tbody v-if="cobraPayments.length > 0">
                        <tr v-for="cobraPayment in cobraPayments" :key="cobraPayment.InvoiceID">
                            <td>{{ cobraPayment.InvoiceID }}</td>
                            <td>{{ 'N/A' }}</td>
                            <td>{{ cobraPayment.CoveragePeriod }}</td>
                            <td>{{ cobraPayment.DueDate }}</td>
                            <td>
                                {{ cobraPayment.Status }}
                                <a 
                                    class="link-text text-300 d-block"
                                    @click.prevent="openModal('hw-cobra-statements', 'Make COBRA Payment', 'plan-upload plan-card-info plan-c-mrp-claims', null, null, null, {InvoiceID: cobraPayment.InvoiceID})"
                                    href="#" 
                                    v-if="cobraPayment.Status == 'Unpaid'"
                                >Pay Invoice</a>
                            </td>
                            <td>{{ formatCurrency(cobraPayment.Amount) }}</td>
                            <td>{{ formatCurrency(cobraPayment.Balance) }}</td>
                            <td>
                                <a href="javascript;"
                                    @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: cobraPayment.DocumentID })">
                                    <img src="@assets/images/icons/pdf_primary.svg" alt="pdf" />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="8">No invoices found.</td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex w-100 justify-content-center justify-content-md-end mt-3">
                    <a href="#" class="btn btn-primary plan-modal"
                        v-if="showMore"
                        @click.prevent="openModal('hw-cobra-payments-history', 'Payment History', 'plan-upload plan-card-info plan-c-mrp-claims')">
                        More
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
import { CobraPayment, CobraFilters } from '@/interfaces/health';
import SelectComponent from '@/components/form/SelectComponent.vue';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from '@/composable/useCurrencyFormatter';

export default defineComponent({
    name: 'CobraPayments',

    props: {
        cobraPayments: {
            type: Array as PropType <CobraPayment[]> ,
            required: true,
            default: () => []
        },
        showMore: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: 'Transaction History'
        }
    },

    setup() {
        const openModal = useModalStore().openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const filters = ref<CobraFilters>({
            InvoiceID: null,
            CheckID: null,
            Year: null,
            Limit: 3
        });

        return {
            openModal,
            formatCurrency,
            filters
        }
    }
});
</script>

<style>
    .cobra-payments {
        width: 100%;
        overflow-y: scroll;
    }
</style>