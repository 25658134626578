import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "checkbox-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "disabled"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['checkbox', _ctx.className])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showLabel && _ctx.left)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: _normalizeClass(["body-text", {'text-dark-grey': _ctx.readonly}]),
            for: _ctx.id
          }, _toDisplayString(_ctx.label), 11, _hoisted_2))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        id: _ctx.id,
        name: _ctx.name,
        class: _normalizeClass(['links', _ctx.className]),
        disabled: _ctx.readonly,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, null, 42, _hoisted_3), [
        [_vModelCheckbox, _ctx.localValue]
      ]),
      (_ctx.showLabel && !_ctx.left)
        ? (_openBlock(), _createElementBlock("label", {
            key: 1,
            class: _normalizeClass(["body-text", {'text-dark-grey': _ctx.readonly}]),
            for: _ctx.id
          }, _toDisplayString(_ctx.label), 11, _hoisted_4))
        : _createCommentVNode("", true)
    ])
  ], 2))
}