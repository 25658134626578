<template>
    <div class="plan-content">
        <h2 class="sm-fs-base"> Terms and Conditions </h2>
        <div class="bordered-card bordered-card_tertiary">
            <div class="card-body d-flex flex-column gap-4 fw-light sm-fs-sm">
                <span>By digitally signing, the undersigned hereby certifies that:</span>
                <span>
                    (i) the company submitting this request is an "applicable large employer" (ALE) or ALE Member
                    (within
                    the meaning of the Affordable Care Act (ACA)); and
                </span>
                <span>
                    (ii) the employees for whom the company is requesting health coverage information from the I.A.T.S.E. National Health & Welfare Fund (the Fund) were “full-time employees” of the company (within the meaning of the ACA) for at least one month of calendar year 2024; and
                </span>
                <span>
                    (iii) the company made contributions to the Fund for such employees during calendar year 2024 pursuant to a collective bargaining or participation agreement; and
                </span>
                <span>
                    (iv) the company is submitting this request for information from the Fund because the
                    information is
                    necessary for the company to comply with the employer reporting requirements imposed by Section
                    6056
                    of
                    the Internal Revenue Code, and the company agrees that it will use the information provided by
                    the
                    Fund
                    solely for that purpose; and
                </span>
                <span>
                    (v) the undersigned understands and acknowledges that the company is solely responsible for
                    making
                    determinations as to its status as an ALE and any employee’s status as a full-time employee for
                    whom
                    the
                    company contributed to the Fund, and the Fund does not verify social security numbers or any
                    other
                    information for purposes of confirming the accuracy of such determinations by the company.
                </span>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-between gap-20 w-100">
        <h4 class="fw-light sm-fs-xs">Click here to digitally sign if you agree to the terms and conditions presented above</h4>
        <button type="button" class="btn btn-primary border-radius-8 lh-1" @click="handleNext">Sign for Terms and Conditions</button>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        instructions: String
    },
    setup(_, { emit }) {
        const handleNext = () => {
            emit('handle-next');
        }

        return { handleNext }
    }
})
</script>