<template>
    <div class="modal-body">
      <div class="d-flex justify-start flex-column w-100 gap-20">
        <small v-if="errors && errors.General" class="text-danger">{{ errors?.General }}</small>
        <PhoneField type="tel" label="Add Phone Number" placeholder="x (xxx) xxx-xxxx" :max=17 v-model:modelValue="phone" />
        <a href="" class="btn btn-primary w-100 mt-20" @click.prevent="handleSubmit">Update</a>
      </div>
    </div>
  </template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import { useEmployerStore } from '@/stores/employer';
import { useLocalStore } from '@/stores/local';
import { useAuthStore } from '@/stores/auth';
import PhoneField from '@components/form/PhoneField.vue';
import axios from 'axios';
import { computed } from 'vue';

export default defineComponent({
    components: {
        PhoneField,
    },

    setup() {
        const authStore = useAuthStore();
        const modalStore = useModalStore();
        const employerStore = useEmployerStore();
        const participantStore = useParticipantStore();
        const localStore = useLocalStore();
        const errors = ref<Record<string, string>>({ General: '' });
        const success = ref('');
        const phone = ref('');
        const preferedMethod = computed(() => participantStore.userTwoFaMethod)

        const validatePhoneNumber = (number: string) => {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            errors.value.General = 'Invalid phone number. Please check and ensure it meets the required validation criteria.';

            if(number === useParticipantStore().userPhone) {
                errors.value.General = 'Phone number should be different from the old one.';
                return false;
            }

            return number.length > 10 && number.length <= 17;
        }

        const handleSubmit = () => {
            if (validatePhoneNumber(phone.value)) {
                errors.value.General = '';

                useModalStore().openModal(
                    'validate-user-2fa',
                    'Validate Phone Number',
                    'modal-success',
                    null,
                    {
                        onConfirm: () => {
                            submitData();
                            useModalStore().closeAbove(0);
                        }
                    },
                    null,
                    null,
                    {phone: phone.value, method: 'P'}
                );
            }
        }

        const submitData = async () => {
            const formData = {
                phone: phone.value,
            };

            axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
                .then(response => {
                    success.value = response.data.success;
                    errors.value = { General: '' };

                    setTimeout(() => {
                        success.value = '';
                    }, 3500);

                    modalStore.openModal(
                        "success-modal",
                        null,
                        "modal-success",
                        null,
                        {
                            title: "Updated Successfully",
                            content: {
                                type: "text",
                                value: 'Your Phone number has been updated successfully!',
                                icon: "iatse-icon-user-verified"
                            },
                            onConfirmButton: "Close",
                            onConfirm: () => {
                                participantStore.getUserEmail();
                            },
                        },);
                })
                .catch((error: any) => {
                    if (error.response) {
                        errors.value = error.response.data.errors;
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        if(errors.value && errors.value?.General) {
                            errors.value.General = '';
                        }
                    }, 3500);
                })
        };

        return {
            success,
            errors,
            phone,
            preferedMethod,
            handleSubmit,

        };
    },
});
</script>
