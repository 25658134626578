np<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- General Plan Detail Cards -->
        <div class="plan-details">
            <ProfileInfo :items="[
        { label: 'Participant ID', value: participantId },
        { label: data?.Label, value: data?.Date },
        { label: 'Days Worked', value: '0' },
    ]" :has-divider="false" :use-container="false" />

            <ProfileInfo :items="[
        { label: 'Check Destination ', value: data?.CheckDestination },
        { label: 'Contributions', value: data?.Total },
    ]" :has-divider="false" :use-container="false" />
        </div>

        <div class="card">
            <h4 class="pdb-10"> Payments and Contributions </h4>
            <table class="striped small toggle equal-widths no-underline">
                <thead>
                    <tr>
                        <th
                            v-for="(header, key) in history.headers"
                            :key="key"
                            :class="{ 'pdl-50': key === 0 }"
                            >
                            {{ header.title }}
                        </th>
                    </tr>
                </thead>

                <tbody v-if="history?.rows?.length">
                    <template v-for="(row, mainKey) in history?.rows " :key="mainKey">
                        <tr class="parent-row">
                            <td colspan="7">
                                <div class="table-row_wrapper d-flex flex-column gap-4">
                                    <div class="d-flex justify-content-between gap-4">
                                        <div v-for="(item, key) in row.Parent" :key="key" class="cell d-flex gap-2"
                                            @click="row.Children && key.toString() === 'Date' ? toggleCell(mainKey) : null">
                                            <p v-if="row.Children && key.toString() === 'Date'" class="toggle-cell view-details">
                                                {{ toggleStates[mainKey] ? '-' : '+' }}
                                            </p>
                                            <p class="cell-header hide-desktop"> {{ key }} </p>
                                            <p class="cell-text" :class="{ 'toggle-cell': key.toString() === 'Date' }">{{ isNumeric(item) && key.toString() !== 'Date'? formatCurrency(item) : item }}</p>
                                        </div>
                                    </div>
                                    <transition name="slide-fade">
                                        <div v-if="toggleStates[mainKey] && row.Children"
                                            class="subitems-columns d-flex flex-column gap-4 p-15">
                                            <div class="d-flex subitems-columns_wrapper"
                                                v-for="(child, index) in  Object.values(row.Children) " :key="index">
                                                <div class="d-flex flex-column gap-2">
                                                    <h5 class="text-dark fw-bold"> Details </h5>
                                                    <div class="d-flex subitems-columns_details">
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Date Issued </p>
                                                            <h5> {{ child?.Date }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Payment Type </p>
                                                            <h5> {{ child?.PaymentDestination ?? '-' }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Clear Date </p>
                                                            <h5> {{ child?.ClearDate }} </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :class="['divider', { 'hide-mobile': index % 2 === 1 }]">
                                                </div>
                                                <div class="d-flex flex-column gap-2">
                                                    <h5 class="text-dark fw-bold"> Deductions </h5>
                                                    <div class="d-flex subitems-columns_details">
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Net </p>
                                                            <h5> {{ formatCurrency(child?.Net) }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Gross </p>
                                                            <h5> {{ formatCurrency(child?.Gross) }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Voids </p>
                                                            <h5> {{ formatCurrency(child?.Void) }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Interest </p>
                                                            <h5> {{ formatCurrency(child?.Interest) }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Admin Fee </p>
                                                            <h5> {{ formatCurrency(child?.AdminFee) }} </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :class="['divider', { 'hide-mobile': index % 2 === 1 }]">
                                                </div>
                                                <div class="d-flex flex-column gap-2">
                                                    <h5 class="text-dark fw-bold"> Destination </h5>
                                                    <div class="d-flex flex-column gap-2"
                                                        v-if="child?.PaymentDestination === 'Check'">
                                                        <p> Address </p>
                                                        <h5> {{ JSON.parse(child?.Destination)?.FullAddress }}
                                                        </h5>
                                                    </div>
                                                    <div v-else class="d-flex subitems-columns_details">
                                                        <div class="d-flex flex-column gap-2">
                                                            <p>Address</p>
                                                            <h5>{{ JSON.parse(child?.Destination).BankClassDescription }}
                                                            </h5>
                                                        </div>
                                                        <transition name="fade">
                                                            <div class="d-flex subitems-columns_details"
                                                                v-if="toggleBanks[mainKey]">
                                                                <div class="d-flex flex-column gap-2">
                                                                    <p>Account Number</p>
                                                                    <h5>{{ JSON.parse(child?.Destination).BankAcctNo }}
                                                                    </h5>
                                                                </div>
                                                                <div class="d-flex flex-column gap-2">
                                                                    <p>Routing Number</p>
                                                                    <h5>{{ JSON.parse(child?.Destination).RoutingCode }}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </transition>
                                                        <button @click.prevent="toggleBank(mainKey)"
                                                            class="ml-2 text-underline fw-bold text-dark button-slide"
                                                            :class="{ 'bounce-right': !isVisible }">
                                                            {{ toggleBanks[mainKey] ? 'Hide' : 'Show' }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="parent-row">
                        <td class="cell text-center" colspan="6">
                            <p>No data available</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Related Links -->
        <div class="account-actions">
            <h4> Related Links </h4>
            <div class="action-buttons">
                <button class="btn btn-secondary plan-modal" @click="openModal('beneficiaries',
                'My Beneficiaries',
                'plan-tables-striped beneficiary',
                null,
                null,
                null,
            )">
                    Beneficiaries
                </button>
                <button class="btn btn-secondary plan-modal"
                    @click="openModal('check-replacement', 'Check Replacement', 'plan-form', 'vacation')"> Check
                    Replacement </button>
                <button class="btn btn-secondary plan-modal"
                    @click="openModal('check-destination', 'Check Destination', 'plan-form', 'vacation')"> ACH/Check
                    Destination </button>
                <a href="" class="btn btn-secondary"> Forms & Documents </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useVacationStore } from '@/stores/vacation';
import { useParticipantStore } from '@/stores/participant';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";

import ProfileInfo from '@/components/cards/ProfileInfo.vue';

export default defineComponent({
    components: {
        ProfileInfo,
    },
    setup() {
        const participantStore = useParticipantStore();
        const vacationStore = useVacationStore();
        const historyRoute = '/api/participant/vacation/history';
        const loading = ref(true);
        const isVisible = ref(true);
        const toggleStates = ref<Record<number, boolean>>({});
        const toggleBanks = ref<Record<number, boolean>>({});
        const { formatCurrency } = useCurrencyFormatter();

        const toggleCell = (index: number) => {
            Object.keys(toggleStates.value).forEach(key => {
                toggleStates.value[Number(key)] = Number(key) === index ? !toggleStates.value[Number(key)] : false;
            });
        }

        const toggleBank = (index: number) => {
            isVisible.value = !isVisible.value;
            Object.keys(toggleStates.value).forEach(key => {
                toggleBanks.value[Number(key)] = Number(key) === index ? !toggleBanks.value[Number(key)] : false;
            });

            setTimeout(() => isVisible.value = !isVisible.value, 300);
        }

        const isNumeric = (value: any) => {
            return !isNaN(value - parseFloat(value));
        }

        const fetchData = async () => {
            try {
                if (!vacationStore.history.rows.length) {
                    loading.value = true;
                    await vacationStore.fetchHistory(historyRoute);
                }

                loading.value = false;
            } catch (error) {
                console.error('Error fetching history data:', error);
            }

            if (vacationStore.history.rows) {
                vacationStore.history.rows.forEach((_, index) => {
                    toggleStates.value[index] = false;
                    toggleBanks.value[index] = false;
                });
            }
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            openModal: useModalStore().openModal,
            participantId: participantStore.general?.ParticipantID,
            data: vacationStore.generalInfo,
            history: vacationStore.history,
            loading,
            isVisible,
            toggleStates,
            toggleBanks,
            isNumeric,
            toggleCell,
            toggleBank,
            formatCurrency
        };
    }
});
</script>