<template>
    <form class="d-flex flex-column gap-20">
        <div class="radio-group primary small bold">
            <div class="radio-buttons">
                <div v-for="(option, index) in options" :key="index" class="radio-button bordered-card bordered-card_secondary bg-light-green w-fit flex-column align-items-start">
                    <input type="radio" :id="`LocalType${index + 1}`"  name="LocalType" :value="option.value"
                        v-model="filters.Local" />
                    <label class="body-text" :for="`LocalType${index + 1}`" >
                        {{option.label}}
                    </label>
                </div>
            </div>
        </div>
        <div class="d-flex gap-20 flex-column flex-md-row">
            <div class="d-flex align-items-end gap-20 w-100">
                <DateInput label="Work Period" v-model:modelValue="filters.StartDate" :isRequired="true" :error="errors.StartDate" />
                <DateInput v-model:modelValue="filters.EndDate" :error="errors.EndDate" />
            </div>
            <div class="custom-select links w-100">
                <label class="body-text">  Employer(s): </label>
                <Multiselect v-model="filters.Employers" :options="data.employers" placeholder="Type to search"
                    :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                    @select="handleEmployerChange" label="Name" track-by="ID" @search-change="handleEmployersSearch"
                    @remove="handleProductions" :loading="loading.employers">
                </Multiselect>
            </div>
        </div>
        <div class="d-flex gap-20">
            <div class="custom-select links">
                <label class="body-text"> Production(s) <span v-if="hasEmployers"> for selected
                        Employer(s)</span>: </label>
                <Multiselect v-model="filters.Productions" :options="data.productions" placeholder="Type to search"
                    :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                    @select="handleProductionChange" label="Name" track-by="ID" @open="handleProductionsOpen"
                    @search-change="handleProductionsSearch" :loading="loading.productions">
                </Multiselect>
            </div>
            <div class="custom-select links">
                <label class="body-text">Payor(s): </label>
                <Multiselect v-model="filters.Payors" :options="data.payors" placeholder="Type to search"
                    :multiple="true" :searchable="true" :close-on-select="true" label="Name" track-by="ID"
                    @select="handlePayorChange" @search-change="handlePayorsSearch" :loading="loading.payors">
                </Multiselect>
            </div>
        </div>
        <div class="d-flex gap-20" v-if="filters.Local === 0">
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
            v-model:modelValue="filters.FirstName" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=12
            v-model:modelValue="filters.LastName" />
        </div>
        <div class="d-flex gap-20">
            <TextInput v-if="filters.Local === 0" type="text" name="number" label="NBF Participant ID or SSN" placeholder="Enter NBF Participant ID or SSN" :max=25
            v-model:modelValue="filters.ParticipantID" />
            <div class="custom-select links">
                <label class="body-text"> Fund(s): </label>
                <Multiselect v-model="filters.Funds" :options="data.funds" placeholder="Type to search"
                    :multiple="true" label="Name" track-by="ID" :searchable="true" :close-on-select="true"
                    @select="handleFundChange">
                </Multiselect>
            </div>
            <div class="text-input" v-if="filters.Local !== 0"></div>
        </div>
        <div class="d-flex gap-2 align-self-end justify-content-between w-100">
            <button type="button" class="btn btn-secondary h-1" @click="resetFilters">Clear Filters</button>
            <button class="btn btn-primary h-1" @click.prevent="searchData" @keypress.enter="searchData">
                {{ loadingSearch ? 'Searching...' : 'Apply Filters' }}
            </button>
        </div>
    </form>
</template>

<script lang="ts">
import { defineComponent, reactive, watch, computed, onMounted } from 'vue';
import { ContributionData } from '@/interfaces/local';
import { RadioOption } from '@/interfaces/interfaces';
import { useAuthStore } from '@/stores/auth';

import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import axios from 'axios';

interface Entity {
    ID: string | number;
    Name: string;
}

interface ContributionFilters {
    StartDate?: string;
    EndDate?: string;
    FirstName?: string;
    ParticipantID?: string;
    LastName?: string;
    Employers: Entity[] | null;
    Productions: Entity[] | null;
    Funds: Entity[] | null;
    Payors: Entity[] | null;
    Page?: number;
    Local?: number;
}

export default defineComponent({
    props: {
        reset: Boolean,
        loadingSearch: Boolean,
        errors: Array
    },

    components: {
        Multiselect,
        DateInput,
        TextInput,
        // RadioButton
    },

    setup(props, { emit }) {
        const allOption: Entity = { ID: 0, Name: 'ALL' };
        const localName = useAuthStore().userName;
        const options: RadioOption[] = [
            { label: 'Work by ' + localName + ' Members', value: 0 },
            { label: 'Work in Local Jurisdiction ', value: 1 },
        ];
        const initialFilters = {
            StartDate: '',
            EndDate: '',
            FirstName: '',
            LastName: '',
            ParticipantID: '',
            Local: 0,
            Employers: [allOption],
            Productions: [allOption],
            Funds: [allOption],
            Payors: [allOption],
        };

        const loading = reactive({
            employers: false,
            productions: false,
            payors: false,
            funds: true,
        })

        const data: ContributionData = reactive({
            employers: [],
            productions: [],
            payors: [],
            funds: [],
        })

        const filters: ContributionFilters = reactive({ ...initialFilters })

        watch(() => props.reset, (newValue) => {
            if (newValue) {
                Object.assign(filters, initialFilters);
            }
        });

        watch(() => filters?.Local, (newValue) => {
            if (newValue) {
               filters.FirstName = '';
               filters.LastName = '';
               filters.ParticipantID = '';
            }
        });

        const hasEmployers = computed(() => filters.Employers?.some(employer => employer.ID !== 0));

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            await handleSelectionSearch('employers', query);
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            await handleSelectionSearch('productions', query);
        };

        // Handle funds search
        const handlePayorsSearch = async (query: string) => {
            await handleSelectionSearch('payors', query);
        };

        const handleFundChange = async (newSelection: Entity) => {
            await handleSelectionChange('Funds', newSelection);
        }

        const handlePayorChange = async (newSelection: Entity) => {
            await handleSelectionChange('Payors', newSelection);
        }

        const handleEmployerChange = async (newSelection: Entity) => {
            await handleSelectionChange('Employers', newSelection);
        }

        const handleProductionChange = async (newSelection: Entity) => {
            await handleSelectionChange('Productions', newSelection);
        }

        // Handle Search in Multiselect
        const handleSelectionSearch = async (type: string, query: string) => {
            loading[type as keyof ContributionData] = true;
            let route = `api/local/${type}?query=${query}`

            if (type === 'productions' || type === 'payors') {
                const employerIds = filters.Employers?.map((employer: Entity) => {
                    if (employer.ID !== 0)
                        return employer.ID
                });

                route += `&&EmployerIDs=${employerIds}`;

                if (type === 'payors') {
                    const productionIDs = filters.Productions?.map((prod: Entity) => {
                        if (prod.ID !== 0)
                            return prod.ID
                    });

                    route += `&&ProductionIDs=${productionIDs}`;
                }
            }

            try {
                const response = await axios.get(route);
                data[type as keyof ContributionData] = response.data;
            }
            catch (error) {
                console.error(`Error searching ${type}:`, error);
            }

            loading[type as keyof ContributionData] = false;
        }

        // Handle Change Selection
        const handleSelectionChange = async (type: string, newSelection: Entity) => {
            if (type === 'StartDate' || type === 'EndDate' || type === 'Page') {
                return;
            }

            let types: Entity[] = newSelection.ID == 0 ? [allOption] : JSON.parse(JSON.stringify(filters[type as keyof typeof filters])).filter((type: Entity) => type.ID != 0);

            if (type === 'Employers') {
                filters.Employers = [...types];

                await handleProductions();
            }

            if (type === 'Productions') {
                filters.Productions = [...types];
            }

            if (type === 'Payors') {
                filters.Payors = [...types];
            }

            if (type === 'Funds') {
                filters.Funds = [...types];
            }
        };

        // Filter selected productions for selected employers
        const handleProductions = async () => {
            await handleProductionsSearch('');

            // if is not selected ALL option, filter selected productions
            if (filters.Productions && filters.Productions[0]?.ID !== 0) {
                const filteredProductions = filters.Productions?.filter(prod => data.productions.some(p => p.ID === prod.ID)) || [];
                filters.Productions = filteredProductions;
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = filters.Employers?.map((employer: Entity) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                await handleProductionsSearch(keyword);
            }
        };

        const fetchData = async () => {
            loading.funds = true;

            try {
                const response = await axios.get('api/local/funds');
                const newData: Entity[] = Object.entries(response.data).map(([ID, value]) => {
                    const Name = value as string;
                    return { ID, Name };
                });

                data.funds = [allOption, ...newData];
            }
            catch (error) {
                console.error(`Error searching funds:`, error);
            }

            loading.funds = false;
        };

        onMounted(async () => await fetchData());

        // Get Contribution History
        const searchData = async () => {
            emit('search', filters, false, true);
        }

        // Get Contribution History
        const resetFilters = async () => {
            Object.assign(filters, initialFilters);
        }

        return {
            filters,
            data,
            options,
            loading,
            hasEmployers,
            searchData,
            resetFilters,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleFundChange,
            handlePayorChange,
            handleProductionsOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handlePayorsSearch,
        }
    }
})
</script>