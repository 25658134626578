<template>
    <div :class="['custom-select links', customClass, { 'error': error }]" ref="dropdownRef">
        <label v-if="label" class="body-text" :for="label">
            {{ label }}
            <span v-if="isRequired" class="text-danger"> * </span>
        </label>
        <Multiselect v-if="displayedOptions?.length && type !== 'filters'" v-model="internalSelectedOption" :options="displayedOptions" placeholder="Type to search"
            :searchable="true" :close-on-select="true" track-by="key" @search-change="handleSearch"
            label="value" :disabled="readonly"
        >
        </Multiselect>
        <!-- <div class="select-items" v-if="isDropdownOpen && !readonly && type !== 'filters'">
            <div v-if="!displayedOptions.length" class="select-option">
                No options
            </div>
            <template v-for="(option, index) in displayedOptions" :key="option.key">
                <div class="select-option" :class="{ active: option.key == internalSelectedOption.key }"
                    @click="selectOption(option)">
                    {{ option.value }}
                </div>
                <div v-if="index < displayedOptions.length - 1" class="divider"></div>
            </template>
        </div> -->

        <div v-if="type === 'filters'" :class="['select-selected filter-arrows', { 'error': error }]" @click="toggleDropdown">
            <p class="text">{{ internalSelectedOption.value }}</p>
            <div v-if="singleArrow && !isSearchable" class="arrows" :class="{ 'single': singleArrow }">
                <i class="iatse-icon-arrow-down"></i>
            </div>
            <div v-else-if="!isSearchable" class="arrows">
                <i class="iatse-icon-select-arrow"></i>
            <i class="iatse-icon-select-arrow"></i>
            </div>
        </div>
        <div class="select-items checkbox-items" v-if="isDropdownOpen && !readonly && type === 'filters'">
            <div v-if="!displayedOptions.length" class="select-option">
                No options
            </div>
            <template v-for="(option, index) in displayedOptions" :key="option.key">
                <div class="select-option">
                    <input type="checkbox" :id="`option-${option.key}`" v-model="checkedOptions"
                        @change="handleCheckboxChange" :value="option.key" />
                    <label :for="`option-${option.key}`">{{ option.value }}</label>
                </div>
                <div v-if="index < displayedOptions.length - 1" class="divider"></div>
            </template>
        </div>
        <small v-if="error?.length" class="text-secondary-red"> {{ Array.isArray(error)  ? error?.join('. ') : error }} </small>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { SelectOption } from '@/interfaces/interfaces'
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        selectedOption: {
            type: Object as () => SelectOption,
            default: null,
        },
        options: {
            type: Array as () => SelectOption[] | [],
            default: () => [],
        },
        hasDefaultOption: {
            type: Boolean,
            default: false,
        },
        defaultOption: {
            type: Object as () => SelectOption,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        singleArrow: {
            type: Boolean,
            default: false,
        },
        isSearchable: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: '',
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        // allowCreate: {
        //     type: Boolean,
        //     default: false,
        // }
    },
    components: {
        Multiselect
    },
    setup(props, { emit }) {
        const isDropdownOpen = ref(false);
        const internalSelectedOption = ref(props.selectedOption || props.defaultOption || { key: 0, value: '-Select Option' });
        const checkedOptions = ref<string[]>([]);
        const dropdownRef = ref<HTMLElement | null>(null);
        const optionsRef = ref<SelectOption[]>(props.options);

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const selectOption = (option: SelectOption) => {
            internalSelectedOption.value = option;
            emit('update:selectedOption', internalSelectedOption.value);
            emit('change', internalSelectedOption.value);
            isDropdownOpen.value = false;
        };

        const handleSearch = (query: string) => {
            emit('search-change', query);
        };

        const displayedOptions = computed(() => {
            const defaultOption: SelectOption = { key: -0, value: '-' };

            if (props.hasDefaultOption) {
                return [defaultOption, ...props.options];
            }

            if(!props.options || !props.options?.length) {
                return [{ key: 0, value: '-' }];
            }

            return props.options;
        });

        // const createTag = (newTag: string) => {
        //     const baseKey = newTag.substring(0, 2).toUpperCase();
        //     // const uniqueKey = `${baseKey}_${Date.now()}`;
        //     const newOption = { key: baseKey, value: newTag };
        //     emit('create-tag', newOption);
        // };

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.value && !dropdownRef.value.contains(event.target as Node)) {
                isDropdownOpen.value = false;
            }
        };

        watch(() => props.selectedOption,
            (newOption) => {
                internalSelectedOption.value = newOption || { key: 0, value: '-Select Option' }
            },
            { deep: true }
        );

        watch(() => internalSelectedOption,
            (newOption) => {
                emit('update:selectedOption', internalSelectedOption.value);
                emit('change', internalSelectedOption.value);
            },
            { deep: true }
        );


        const handleCheckboxChange = () => {
            emit('update:options', checkedOptions.value);
        };

        onMounted(() => {
            document.addEventListener('mousedown', handleClickOutside);
        });

        onBeforeUnmount(() => {
            document.removeEventListener('mousedown', handleClickOutside);
        });

        return {
            dropdownRef,
            isDropdownOpen,
            checkedOptions,
            internalSelectedOption,
            displayedOptions,
            toggleDropdown,
            handleCheckboxChange,
            selectOption,
            handleSearch,
            // createTag,
        };
    },
});
</script>