<template>
    <div>
        <!-- Verification Code Form -->
        <div class="container mt-5">
            <div class="row justify-content-center align-items-center vh-100">
                <!-- Form Column -->
                <div class="col-md-6 d-flex flex-column justify-content-center">
                    <h2>Check Your Email</h2>
                    <div class="form-group d-flex flex-column gap-4 mb-4">
                        <label for="code">We've sent you a code to {{ sentTo }}</label>
                        <VerificationCodeInput v-model="verificationCode" extra-classes="centered" />
                        <div class="d-flex gap-4 mt-4">
                            <button @click="verifyCode" class="btn btn-primary w-100">Submit Code</button>
                        </div>
                        <div class="mt-3 d-flex justify-content-center">
                            <div>
                                <a href="#" @click.prevent="regenerateCode">Resend Code</a>
                                <span v-if="timer > 0"> ({{ timer }} seconds remaining)</span>
                            </div>
                        </div>
                        <div v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</div>
                    </div>
                </div>

                <!-- Image Column -->
                <div class="col-md-6 d-flex justify-content-center align-items-center">
                    <img style="max-width: 100%; max-height: 90vh;" src="@/assets/images/2fa-picture.jpg" alt="2FA Image" />
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
    import { ref, onMounted, onUnmounted } from 'vue';
    import axios from 'axios';
    import { useAuthStore } from '@/stores/auth';
    import { useRouter } from 'vue-router';
    import VerificationCodeInput from '@components/form/VerificationCodeField.vue';

    export default {
    components: {
        VerificationCodeInput,
    },
      setup() {
        const router = useRouter();
        const authStore = useAuthStore();

        const verificationCode = ref('');
        const expirationTime = ref(120); // Default to 60 seconds
        const timer = ref(0);
        const timerInterval = ref<number | null>(null);
        const errorMessage = ref('');

        const sentTo = ref('');

        const startTimer = () => {
          timer.value = expirationTime.value;
          if (timerInterval.value) {
            clearInterval(timerInterval.value);
          }
          timerInterval.value = setInterval(() => {
            if (timer.value > 0) {
              timer.value--;
            } else {
              clearInterval(timerInterval.value!);
              timerInterval.value = null;
            }
          }, 1000);
        };

        const verifyCode = async () => {
          try {
            const payload = {
              verification_code: verificationCode.value
            };
            const response = await axios.post('/api/authorization/submit-code', payload);
            if (response.data.success) {
              // Verification successful
              // Redirect or perform necessary actions
               authStore.set2FaValidated(true);
               navigateToDashboard();
            } else {
              // Handle error
              errorMessage.value = response.data.error || 'Invalid verification code.';
            }
          } catch (error: any) {
            console.error(error);
            errorMessage.value = error.response?.data?.error || 'An error occurred.';
          }
        };

        const regenerateCode = async () => {
            try {
                const response = await axios.get('/api/authorization/regenerate-code');

                if (response.data.success) {
                    // Use new expiration time if provided
                    if (response.data.expirationTime) {
                        expirationTime.value = response.data.expirationTime;
                    }
                    else {
                        expirationTime.value = 60; // Default to 60 seconds
                    }

                    startTimer();
                }
                else {
                // Handle error
                errorMessage.value = response.data.message || 'An error occurred while resending the code.';
                }
            }
            catch (error: any) {
                console.error(error);
                errorMessage.value = error.response?.data?.message || 'An error occurred.';
            }
        };


        const generateCode = async () => {
            try {
                const response = await axios.get('/api/authorization/generate-code');

                if (response.data.success) {
                    if (response.data.expirationTime) {
                        expirationTime.value = response.data.expirationTime;
                    }
                    else {
                        expirationTime.value = 120;
                    }

                    sentTo.value = response.data.PreferredMethod.value;
                    startTimer();
                }
            }
            catch (error: any) {
                errorMessage.value = error.response?.data?.message || 'An error occurred while generating the code.';
            }
        };


        // Clean up the timer when the component is unmounted
        onUnmounted(() => {
          if (timerInterval.value) {
            clearInterval(timerInterval.value);
          }
        });

        onMounted(generateCode);

        const navigateToDashboard = () => {
          // Assuming getDashboardRoute returns the name of the route
          const dashboardRouteName = authStore.getDashboardRoute;

          router.push({ name: dashboardRouteName }); // Push to the named route
      };

        return {
          verificationCode,
          expirationTime,
          sentTo,
          timer,
          verifyCode,
          regenerateCode,
          errorMessage,
        };
      },
    };
</script>