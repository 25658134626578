<template>
    <AlertsComponent :error="errors?.General" />
    <form class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Productions (Reports)</h2>
        <div class="d-flex flex-column gap-4">
            <div class="d-flex flex-column gap-20">
                <div class="d-flex align-items-end gap-20 w-100">
                    <DateInput label="Work Period Start" v-model:modelValue="filters.StartDate" v-model:reset="reset" :isRequired="true" :error="errors.StartDate"/>
                    <DateInput label="Work Period End" v-model:modelValue="filters.EndDate" v-model:reset="reset" :isRequired="true" :error="errors.EndDate"/>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center gap-20 w-100 mt-10">
            <div class="d-flex justify-content-between gap-20">
                <button type="button" class="btn btn-secondary" @click.prevent="resetFilters">Clear Filters</button>
                <button class="btn btn-primary" @click.prevent="searchData" @keydown.enter="searchData">
                    {{ loading ? 'Searching...' : 'Search' }}
                </button>
            </div>

            <i class="iatse-icon-printer fs-xxl text-primary plan-modal sm-fs-xl" v-if="productions.length"  @click="printList"></i>
        </div>
        <div v-if="!loading && productions.length" style="width: 100%;">
            <table class="small striped toggle no-underline mt-30" id="table-productions">
                <thead class="large large_padding">
                    <tr class="text-center align-middle">
                        <th>Productions/Venue</th>
                        <th>Payor</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="production, index in productions" :key="production.ProductionName">
                        <tr class="large">
                            <td class="col-md-1">{{ production.ProductionName }}</td>
                            <td class="col-md-1" v-html="production.Payor"></td>
                        </tr>
                        <!-- Row for top page space (temp method) -->
                        <tr v-if="(index + 1) % 28 === 0" class="hidden">
                            <td colspan="7" style="background-color: white; height: 130px;"></td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </form>
</template>

<script lang="ts">
import { reactive, ref, Ref, computed, watch } from 'vue';
import { usePrint } from '@/composable/usePrint';
import { useFormattedDate } from '@/composable/useFormattedDate';
import { useAuthStore } from '@/stores/auth';
import { PrintData } from '@/interfaces/interfaces';
import DateInput from '@components/form/DateInput.vue';
import axios from 'axios';

interface Production {
    ProductionID?: number;
    ProductionName: string;
    Payor: string;
}

export default {
    components: {
        DateInput
    },
    setup() {
        const loading = ref(false);
        const reset = ref(false);
        const productions: Ref<Production[]> = ref([]);
        const { printElementById } = usePrint();
        const {formatDate} = useFormattedDate();
        const errors = ref([]);
        const filters = reactive({
            StartDate: null,
            EndDate: null,
        })

        watch(() => filters, (newVal) => {
            if(printData.Filters?.DateRange) {
                printData.Filters.DateRange = newVal
                ?  formatDate(newVal.StartDate ?? '') + ' - ' +  formatDate(newVal.EndDate ?? '')
                : '';
            }
        }, { deep: true });


        const printData: PrintData = reactive({
            Header: {
                EmployerName: useAuthStore()?.user?.name ?? '',
                ReportDate: formatDate(new Date().toDateString()),
                PrintDate: formatDate(new Date().toDateString()),
            },
            Filters: {
                DateRange: filters.StartDate + ' - ' + filters.EndDate
            }
        });

        const printList = printElementById('table-productions', 'Production(s) Report', printData);

        const resetFilters = () => {
            productions.value = [];

            Object.keys(filters).forEach((key) => {
                filters[key as keyof typeof filters] = null;
            });

            reset.value = true;
        }

        const searchData = async () => {
            loading.value = true;

            await axios
                .get('api/employer/productions-list', {
                    params: { ...filters }
                })
                .then(response => {
                    productions.value = Object.entries(response.data).map(([ProductionName, Payor]) => ({ ProductionName, Payor })) as Production[];
                })
                .catch(errorRes => {
                    errors.value = errorRes.response.data.errors;

                    setTimeout(() => errors.value = [], 3500);
                })

            loading.value = false;
        }

        return {
            reset,
            loading,
            errors,
            filters,
            productions,
            resetFilters,
            searchData,
            printList
        };
    }
}
</script>