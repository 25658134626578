<template>
    <div class="plan-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <p>
                        In order to enroll in Plan C Medical Reimbursement Program...
                    </p>
                    <p>
                        Please be careful when choosing and uploading documents...
                    </p>
                    <p>
                        Please note: This website supports the following file types...
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <SelectComponent
                        label="Choose Document Type"
                        v-model:selectedOption="selectedDocType"
                        :options="documentTypes"
                        customClass="full-width mt-20"
                    />

                    <DragDrop
                        customClasses="grey large w-100 mt-20"
                        @change="handleFileSelection"
                        :reset="reset"
                        :loading="loadingUpload"
                        key="drag-drop-mrp"
                    />

                    <div class="bordered-card w-100 mt-20">
                        <h4>Documents Currently On File/Pending Admin Preview</h4>
                        <table class="small mb-4">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody v-if="uploadedDocs.length > 0">
                                <tr v-for="document in uploadedDocs" :key="document.DocumentID">
                                    <td>
                                        <a href="javascript;"
                                           @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document?.DocumentID, FilePreview: document?.DocumentID, Type: 'dependent' })">
                                            {{ document.Description }}
                                        </a>
                                    </td>
                                    <td>{{ document.EffectiveDate }}</td>
                                    <td :class="{ 'text-danger': document.Status === 'Pending' }">{{ document.Status }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3">
                                        No documents have been uploaded to your account.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="action-buttons pdt-100 w-100 d-flex justify-content-between">
                        <button type="button" class="btn btn-secondary" :disabled="loadingUpload">
                            Back 
                        </button>
                        <button type="button" class="btn btn-primary" @click="handleSubmit" :disabled="loadingUpload || !documentUploaded">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { Document, SelectOption } from '@/interfaces/interfaces';
import DragDrop from '@components/form/DragDrop.vue';
import SelectComponent from '@components/form/SelectComponent.vue';

interface MrpDocument {
    DocumentID: number;
    Description: string;
    EffectiveDate: string;
    Status: string;
}

export default {
    components: {
        DragDrop,
        SelectComponent
    },
    setup() {
        const modalStore = useModalStore();
        const { openModal } = useModalStore();
        const documentTypes = [{ key: 120, value: 'C-MRP Cert/Proof' }];
        const uploadedDocs = ref<MrpDocument[]>([]);
        const fileNames = ref('');
        const fileInput = ref<HTMLInputElement | null>(null);
        const error: Ref<string | null> = ref(null);
        const selectedDocType = ref<SelectOption | null>(null);
        selectedDocType.value = documentTypes[0];
        const reset = ref(false);
        const loadingUpload = ref(false);
        const documentUploaded = ref(false);

        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/health-welfare/mrp-documents');
                uploadedDocs.value = response.data.Documents;
            } catch (err) {
                console.error('There was an error!', err);
            }
        }

        onMounted(fetchData);

        // Adjusted method signature: now it receives an array of File objects
        const handleFileSelection = async (files: File[]) => {
            loadingUpload.value = true;
            reset.value = false; // We'll reset after the attempt

            if (!selectedDocType.value?.key) {
                error.value = 'The selected document type is invalid.';
                loadingUpload.value = false;
                reset.value = true;
                setTimeout(() => { error.value = ''; }, 3000);
                return;
            }

            if (files.length) {
                const formData = new FormData();
                formData.append('DocumentType', String(selectedDocType.value.key));

                for (let i = 0; i < files.length; i++) {
                    formData.append('Files[]', files[i]);
                }

                try {
                    const response = await axios.post('api/smartsearch/mrp-document', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data.success) {
                        documentUploaded.value = true;

                        fetchData();
                    }

                    // Assuming response returns updated documents list
                    // uploadedDocs.value = response.data.Documents;
                    error.value = null;
                } catch (err: any) {
                    console.error('Upload failed:', err);
                    error.value = 'There was a problem uploading your documents.';
                } finally {
                    loadingUpload.value = false;
                    // Reset the drag & drop to clear any files
                    reset.value = true;
                }
            }
        };

        const handleSubmit = () => {
            modalStore.closeAllModals();

            setTimeout(() => {
                modalStore.openModal(
                    "success-modal",
                    null,
                    "modal-success",
                    null,
                    {
                        title: "Thank You, we have received your request.",
                        content: {
                            type: "text",
                            value: 'Please allow up to 48 hours for our admin team to review your request. Data will not be updated until the review is completed. Once complete you will receive an email letting you know to login and visit your dashboard for further updates regarding your enrollment selection.',
                            icon: "iatse-icon-user-verified"
                        },
                        onConfirmButton: "Close",
                    }
                );
            }, 300);
        };

        return {
            openModal,
            handleSubmit,
            documentTypes,
            uploadedDocs,
            selectedDocType,
            fileNames,
            fileInput,
            error,
            reset,
            loadingUpload,
            documentUploaded,
            handleFileSelection
        };
    }
};
</script>
