<template>
    <div class="plan-content">
        <div class="plan-cards-wrapper w-100">
            <div class="card-header">
                <h5>Retiree Profile</h5>
                <div class="card-control plus">
                    <i class="plus iatse-icon-plus"></i>
                    <span class="line"></span>
                </div>
            </div>

            <!-- Toggle Link -->


            <div class="data-section">
                <div class="d-flex justify-content-between w-100" v-if="dataLoaded">
                    <h3>Demographics</h3>
                    <a href="" class="links" @click.prevent="toggleDetails" v-if="planSummary.SpouseDetails !== null">
                        {{ showSpouseDetails ? 'View Participant Details' : 'View Spouse Details' }}
                    </a>
                </div>
                <!-- Wrap details in a transition. Using 'fade' as an example. -->
                <transition name="fade" mode="out-in">
                    <!-- A single root element under <transition> -->
                    <div v-if="dataLoaded" :key="showSpouseDetails ? 'spouse' : 'participant'">
                            <!-- Conditionally show Participant vs. Spouse details inside this single root element -->
                        <div v-if="!showSpouseDetails" class="fund-details">
                            <!-- Participant Details -->
                            <div class="fund-detail">
                                <h5>Name</h5>
                                <h4>{{ planSummary.PersonDetails.Name }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Participant ID</h5>
                                <h4>{{ planSummary.PersonDetails.PersonID }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>SSN</h5>
                                <h4>{{ planSummary.PersonDetails.SSN }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Gender</h5>
                                <h4>{{ planSummary.PersonDetails.Gender }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Marital Status</h5>
                                <h4>{{ planSummary.PersonDetails.MaritalStatus }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Date of Marriage</h5>
                                <h4>{{ planSummary.PersonDetails.MarriageDate ?? 'N/A' }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Date of Birth</h5>
                                <h4>{{ planSummary.PersonDetails.DateOfBirth }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Street</h5>
                                <h4>{{ planSummary.PersonDetails.Street }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>City</h5>
                                <h4>{{ planSummary.PersonDetails.City }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>State</h5>
                                <h4>{{ planSummary.PersonDetails.State ?? 'N/A' }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Country</h5>
                                <h4>{{ planSummary.PersonDetails.Country?.value ?? 'N/A' }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>ZIP</h5>
                                <h4>{{ planSummary.PersonDetails.Zip ?? 'N/A' }}</h4>
                            </div>
                        </div>

                        <div v-else-if="planSummary.SpouseDetails !== null" class="fund-details">
                            <!-- Spouse Details -->
                            <div class="fund-detail">
                                <h5>Name</h5>
                                <h4>{{ planSummary.SpouseDetails.Name }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Participant ID</h5>
                                <h4>{{ planSummary.SpouseDetails.PersonID }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>SSN</h5>
                                <h4>{{ planSummary.SpouseDetails.SSN }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Gender</h5>
                                <h4>{{ planSummary.SpouseDetails.Gender }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Marital Status</h5>
                                <h4>{{ planSummary.SpouseDetails.MaritalStatus }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Date of Marriage</h5>
                                <h4>{{ planSummary.SpouseDetails.MarriageDate ?? 'N/A' }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Date of Birth</h5>
                                <h4>{{ planSummary.SpouseDetails.DateOfBirth }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Street</h5>
                                <h4>{{ planSummary.SpouseDetails.Street }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>City</h5>
                                <h4>{{ planSummary.SpouseDetails.City }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>State</h5>
                                <h4>{{ planSummary.SpouseDetails.State ?? 'N/A' }}</h4>
                            </div>
                            <div class="fund-detail">
                                <h5>Country</h5>
                                <h4>{{ planSummary.SpouseDetails.Country?.value ?? 'N/A' }}</h4>
                            </div>
                            <div class="fund-detail">
                            <h5>ZIP</h5>
                            <h4>{{ planSummary.SpouseDetails.Zip }}</h4>
                        </div>
                    </div>
                </div>
                </transition>
            </div>

            <div class="d-flex w-100 justify-content-between">
                <div class="person-toggler">
                    <button
                        class="toggle-button"
                        :class="{ active: selectedOption === 'X' }"
                        @click="selectedOption = 'X'"
                    >
                        X
                    </button>
                    <button
                        class="toggle-button"
                        :class="{ active: selectedOption === 'Y' }"
                        @click="selectedOption = 'Y'"
                    >
                        Y
                    </button>
                </div>
                <a
                    class="btn btn-primary"
                    @click.prevent="openModal('hw-retiree-payments-history', 'Payments History', 'plan-retiree')"
                >
                    Payments History
                </a>
            </div>

            <!-- Account Actions -->
            <div class="plan-summary__footer row w-100">
                <div class="col-12 col-md-4">
                    <h4>Other Coverages x</h4>
                    <div class="account-actions">
                        <OtherCoverages :otherCoverages="planSummary.OtherCoverages"/>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <h4>Account Actions</h4>
                    <div class="action-buttons mt-10">
                        <a href="" class="btn btn-secondary plan-modal"
                            @click.prevent="openModal('beneficiaries', 'My Beneficiaries', 'plan-tables-striped beneficiary', null, null, null)">
                            Beneficiaries
                        </a>
                        <a 
                            href="#"
                            class="btn btn-secondary btn-large plan-modal"
                            @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')">
                            Claims
                        </a>
                        <a href="" class="btn btn-secondary btn-large plan-modal"
                            @click.prevent="openModal('dependents', 'My Dependents', null)">
                            My Dependents
                        </a>
                    </div>
                </div>
            </div> <!-- end actions -->
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { PlanRetireeSummary } from '@/interfaces/health';
import OtherCoverages from '@/components/participant/health-welfare/OtherCoverages.vue';

export default {
    components: { OtherCoverages },

    setup() {
        const dataLoaded = ref(false);
        const showSpouseDetails = ref(false);
        const openModal = useModalStore().openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const planSummary = ref<PlanRetireeSummary>({
            PersonDetails: {
                ParticipantID: '',
                SSN: '',
                Gender: '',
                Name: '',
                DateofBirth: '',
                Age: 0,
                AddressFormatted: ''
            },
            SpouseDetails: null,
            RetireeBenefits: []
        });

        const fetchData = async() => {
            try {
                const response = await axios.get('/api/participant/health-welfare/plan-retiree-summary');
                planSummary.value = response.data;
                dataLoaded.value = true;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const toggleDetails = () => {
            showSpouseDetails.value = !showSpouseDetails.value;
        };

        onMounted(() => {
            fetchData();
        });

        return {
            openModal, planSummary, dataLoaded, showSpouseDetails, toggleDetails
        };
    },
};
</script>

<style scoped>
/* Example fade transition styles */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
