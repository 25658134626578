<template>
    <div class="bordered-card bordered-card_secondary members-card">
        <h4 class="fw-bold">Employer Actions</h4>
        <div class="d-flex flex-column align-items-start gap-20 w-100">
            <div class="d-flex align-items-center gap-10 w-100">
                <i class="iatse-icon-search w-5"> </i>
                <router-link class="fs-sm" :to="{ name: 'local.contract-information' }">
                   Summarized Contract Info
                </router-link>
            </div>
            <div class="d-flex align-items-center gap-10 w-100">
                <i class="iatse-icon-calendar w-5"> </i>
                <router-link class="fs-sm" :to="{ name: 'user.demographic' }">
                    Missing Demographic Info
                </router-link>
            </div>
            <div class="d-flex align-items-center gap-10 w-100 plan-modal">
                <i class="iatse-icon-Hand-Pills fs-lg w-5"> </i>
                <router-link class="fs-sm" :to="{ name: 'employer.productions.list' }">
                    List of Productions
                </router-link>
            </div>
            <div class="d-flex align-items-center gap-10 w-100">
                <i class="iatse-icon-note fs-lg w-5"> </i>
                <a href="#" @click.prevent="openModal('employer-e-remittance-report', 'Submit eRemittance Report', null)" class="plan-modal fs-sm">
                    Submit eRemittance Report
                </a>
            </div>
            <div class="d-flex align-items-center gap-10 w-100">
                <i class="iatse-icon-note fs-lg w-5"> </i>
                <a href="#" @click.prevent="openModal('employer-aca-report', 'Employee Health Coverage Information Request', null)" class="plan-modal fs-sm">
                    ACA Mandate Related Reports
                </a>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="text-center mb-4">Payment & Reporting Resources</h3>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column align-items-start gap-4 w-100">
                        <h6>eRemittance/ Reporting</h6>
                        <div class=" d-flex flex-column align-items-start gap-2 w-100">
                            <span>eRemittance / Reporting</span>
                            <span>Submit eRemittance Report</span>
                            <span>Schedule Payment</span>
                            <router-link :to="{ name: 'user.demographic' }">Missing Demographic
                                Information</router-link>
                        </div>
                    </div>
                </div>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column gap-2 w-100">
                        <h6>Reports from Fund Office</h6>
                        <span>Most Recent</span>
                        <a href="#">Demographic Request Report</a>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body d-flex flex-column gap-2 w-100">
                        <h6>ACA Mandate Related Reports</h6>
                        <div class="d-flex flex-column gap-2 w-100">
                            <router-link :to="{ name: 'employer.request.health.data' }">
                                Request Employee Health Coverage Data
                            </router-link>
                            <router-link :to="{ name: 'employer.request.health.data', params: { download: 1 } }">
                                Download requested Health Coverage Data
                            </router-link>
                            <a href="#">Information About ACA Mandate</a>
                            <a target="_BLANK" :href="links?.ACA_EmployerMandateInstructionsLetter">Instructions for obtaining Coverage Data</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { PaymentLinks } from '@/interfaces/employer';
import { useModalStore } from '@/stores/modal';

export default defineComponent({
    props: {
        links: Object as PropType<PaymentLinks | null>
    },
    setup () {
        return {
            openModal: useModalStore().openModal
        }
    }
})
</script>