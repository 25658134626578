<template>
    <table class="small striped toggle no-underline overflow-auto">
        <thead class="large large_padding">
            <tr>
                <th class="col-md-1"> EIN  <br /> / Tax ID </th>
                <th class="col-md-1"> Company <br /> Name </th>
                <th class="col-md-1"> Employer <br /> / Payor </th>
                <th class="col-md-1"> First <br /> Name </th>
                <th class="col-md-1"> Last <br />  Name </th>
                <th class="col-md-1"> Title </th>
                <th class="col-md-1"> Email <br />  Address </th>
                <th class="col-md-1"> Phone </th>
                <th class="col-md-1"> Type </th>
                <th class="col-md-1" v-if="processed == 0"> Account <br />  actions</th>
            </tr>
        </thead>
        <tbody>
            <tr class="large" v-for="employer in employers" :key="employer.TaxID">
                <td class="col-md-1">{{ employer.TaxID }} </td>
                <td class="col-md-1">{{ employer.CompanyName }}</td>
                <td class="col-md-1">{{ employer.Type }}</td>
                <td class="col-md-1">{{ employer.FirstName }}</td>
                <td class="col-md-1">{{ employer.LastName }}</td>
                <td class="col-md-1">{{ employer.Title }}</td>
                <td class="col-md-2">{{ employer.Email }}</td>
                <td class="col-md-1">{{ employer.Phone }}</td>
                <td class="col-md-1">{{ employer.Role }}</td>
                <td class="col-md-1" v-if="processed == 0">
                    <div class="actions">
                        <span class="tooltip-wrapper" data-tooltip="Send PIN">
                            <i class="plan-modal iatse-icon-lock-check" @click="sendPin(employer.UIEmployerRequestID)"></i>
                        </span>
                        <p> / </p>
                        <span class="tooltip-wrapper" data-tooltip="Reject Request">
                            <i class="plan-modal iatse-icon-lock" @click="rejectRequest(employer.UIEmployerRequestID)"></i>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { PendingEmployer, Paginator } from '@/interfaces/admin';

export default defineComponent({
    props: {
        employers: {
            type: Object as PropType<PendingEmployer[]>,
            required: true
        },
        actions: Object,
        processed: Number
    },
    setup(_, { emit }) {
        const sendPin = (requestID: number) => {
            emit('send-pin', requestID);
        }

        const rejectRequest = (requestID: number) => {
            emit('reject-request', requestID);
        }

        return {
            sendPin,
            rejectRequest,
        };
    }
});
</script>