<template>
  <!-- Plan Header -->
  <div class="plan-buttons hide-desktop">
    <a href="" class="btn btn-primary"> Missing Contributions Form </a>
    <p class="btn btn-secondary plan-modal" data-modal-name="pension-contribution-history"> View Details </p>
  </div>
  <MessageCard message="This page allows you to look for specific employer contributions received on your behalf based on different search parameters." type="danger" iconClass="iatse-icon-alarm"
  classes="info_card_full" />
  <div class="plan-content">
    <WorkHistoryFilters :filters="filters" @update-filters="updateFilters" />
    <WorkHistoryListAlternative :data="workHistoryData" :loading="loading"/>
  </div>
</template>

<script lang="ts">
import { reactive, ref, onMounted, watch } from 'vue';
import axios from 'axios';
// import WorkHistoryList from '../../components/participant/WorkHistoryList.vue';
import WorkHistoryListAlternative from '../../components/participant/WorkHistoryListAlternative.vue';
import WorkHistoryFilters from '../../components/participant/WorkHistoryFilters.vue';
import { WorkHistoryData, WorkHistoryFilterKeys } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';

export default {
  setup() {
    const modalStore = useModalStore();
    const workHistoryData = ref<WorkHistoryData>({ Years: {}, GrandTotals: {}, GrandTotalUnits: null });
    const filters = reactive<WorkHistoryFilterKeys>({
      period: null,
      from_date: modalStore.currentModal?.queryParams?.FromDate as string ?? '',
      to_date: modalStore.currentModal?.queryParams?.ToDate as string ?? '',
      employers: [],
      productions: [],
      funds: modalStore.currentModal?.queryParams?.Funds as number[] ?? [],
    });

    const fromDate = modalStore.currentModal?.queryParams?.FromDate;
    const toDate = modalStore.currentModal?.queryParams?.ToDate;
    const loading = ref(true);

    const fetchData = async () => {
      loading.value = true;

      try {
        const response = await axios.get('api/participant/work-history', { params: filters });
        workHistoryData.value = response.data; // Assign to ref's value
      } catch (error) {
        console.error('Error:', error);
      }

      loading.value = false;
    };

    onMounted(async() => {
      await fetchData();
    });

    const updateFilters = (newFilters: WorkHistoryFilterKeys) => {
      Object.assign(filters, newFilters);
    };

    watch(filters, fetchData, { deep: true });

    return {
      workHistoryData,
      filters,
      loading,
      updateFilters
    };
  },
  components: {
    WorkHistoryListAlternative,
    WorkHistoryFilters
  }
};
</script>