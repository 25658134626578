<template>
  <div :class="['drag-drop', customClasses]" ref="dragDropArea" @dragover.prevent @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop">
    <!-- Overlay when loading -->
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>

    <div class="choose-files" v-if="!files.length">
      <i :class="icon"></i>
      <h5 class="browse" v-if="text" @click.prevent="triggerFileInput"> {{ text }} </h5>
      <h5 v-else> Drag and Drop or
        <span class="browse" @click.prevent="triggerFileInput">{{browseText}}</span>
      </h5>
      <input type="file" :accept="accept" ref="fileInput" style="display: none;" multiple @change="handleFileChange" />
      <p v-if="accept !== ''" class="fs-xs text-grey">
        Supported: {{ accept }}
      </p>
    </div>


    <div class="drag-drop-files">
      <div v-for="(file, index) in files" :key="index" class="file-preview">
        <img src="../../assets/images/doc.png" :alt="file.name" class="file-image-preview" width="95" height="55" />
        <small class="file-name">{{ file.name }}</small>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, toRefs, watch } from 'vue';

export default defineComponent({
  props: {
    customClasses: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '*',
    },
    browseText: {
      type: String,
      default: 'Choose From Device',
    },
    icon: {
      type: String,
      default: 'iatse-icon-document-upload',
    },
    text: String,
    reset: Boolean,
    loading: Boolean,
  },
  setup(props, { emit }) {
    const files = ref<File[]>([]);
    const fileInput = ref<HTMLInputElement | null>(null);
    const dragDropArea = ref<HTMLDivElement | null>(null);
    const { reset } = toRefs(props);

    watch(() => reset.value, (newValue) => {
      if (newValue) {
        files.value = [];
        fileInput.value = null;
        emit('reset');

        // if (fileInput.value) {
        //   fileInput.value.value = "";
        //   console.log("File input has been reset.");
        // }
      }
    });

    const handleDragEnter = (event: DragEvent) => {
      if (dragDropArea.value) {
        dragDropArea.value.style.border = '2px dashed #8EC986';
      }
    };

    const handleDragLeave = (event: DragEvent) => {
      if (dragDropArea.value) {
        dragDropArea.value.style.border = '1px dashed #8EC986';
      }
    };

    const handleDrop = (event: DragEvent) => {
      if (event.dataTransfer && event.dataTransfer.files) {
        files.value = [...files.value, ...Array.from(event.dataTransfer.files)];
        console.log('dropped', files.value)

        emit('change', event);
        // emit('change', files.value);
      }
    };

    const handleFileChange = async (event: Event) => {
      const target = event.target as HTMLInputElement;

      if (target.files) {
        files.value = [...files.value, ...Array.from(target.files)];
        console.log('cahnged', files.value)
        emit('change', files.value);
      }
    };

    const triggerFileInput = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    }

    return {
      files,
      fileInput,
      dragDropArea,
      handleDrop,
      handleDragLeave,
      handleDragEnter,
      triggerFileInput,
      handleFileChange
    };
  },
});
</script>