import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form account-settings" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex w-full gap-2"
}
const _hoisted_3 = {
  key: 0,
  class: "d-flex align-items-end w-full gap-2"
}
const _hoisted_4 = {
  key: 0,
  class: "d-flex w-full justify-content-center align-items-end gap-2"
}
const _hoisted_5 = {
  key: 1,
  class: "d-flex w-full justify-content-center align-items-end gap-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectComponent = _resolveComponent("SelectComponent")!
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.localSelectedQuestion.ExistingQuestion && !_ctx.localSelectedQuestion.IsEditable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.localSelectedQuestion.IsEditable)
            ? (_openBlock(), _createBlock(_component_SelectComponent, {
                key: 0,
                label: 'Security Question' + (_ctx.index > 1 ? ' ' + _ctx.index : ''),
                selectedOption: _ctx.selectedOption,
                "onUpdate:selectedOption": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOption) = $event)),
                options: _ctx.options,
                readonly: true
              }, null, 8, ["label", "selectedOption", "options"]))
            : _createCommentVNode("", true),
          _createVNode(_Transition, { name: "slide-fade" }, {
            default: _withCtx(() => [
              (!_ctx.localSelectedQuestion.IsEditable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("a", {
                      href: "",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleEditable && _ctx.toggleEditable(...args)), ["prevent"])),
                      class: "btn btn-primary action-btn lh-1"
                    }, " Change "),
                    _createElementVNode("a", {
                      href: "",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeQuestion && _ctx.removeQuestion(...args)), ["prevent"])),
                      class: "btn btn-secondary action-btn lh-1"
                    }, " Remove ")
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        (_ctx.localSelectedQuestion.IsEditable && _ctx.localSelectedQuestion.ExistingQuestion)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_SelectComponent, {
                label: 'Edit Security Question' + (_ctx.index > 1 ? ' ' + _ctx.index : ''),
                selectedOption: _ctx.selectedOption,
                "onUpdate:selectedOption": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedOption) = $event)),
                options: _ctx.options,
                onChange: _ctx.handleQuestion,
                error: _ctx.errors ? _ctx.errors[`security_questions.${_ctx.index - 1}.Question`] : null
              }, null, 8, ["label", "selectedOption", "options", "onChange", "error"]),
              _createVNode(_component_TextInput, {
                modelValue: _ctx.localSelectedQuestion.Answer,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localSelectedQuestion.Answer) = $event)),
                type: "text",
                name: "mixed",
                label: 'Edit Security Answer' + (_ctx.index > 1 ? ' ' + _ctx.index : ''),
                placeholder: "Edit Security Answer",
                error: _ctx.errors ? _ctx.errors[`security_questions.${_ctx.index - 1}.Answer`] : null
              }, null, 8, ["modelValue", "label", "error"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.localSelectedQuestion.IsEditable && !_ctx.localSelectedQuestion.ExistingQuestion)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_SelectComponent, {
            label: 'Add New Security Question' + (_ctx.index > 1 ? ' ' + _ctx.index : ''),
            selectedOption: _ctx.selectedOption,
            "onUpdate:selectedOption": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedOption) = $event)),
            options: _ctx.options,
            onChange: _ctx.handleQuestion,
            error: _ctx.errors ? _ctx.errors[`security_questions.${_ctx.index - 1}.Question`] : null
          }, null, 8, ["label", "selectedOption", "options", "onChange", "error"]),
          _createVNode(_component_TextInput, {
            modelValue: _ctx.localSelectedQuestion.Answer,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localSelectedQuestion.Answer) = $event)),
            type: "text",
            name: "mixed",
            label: 'Add New Security Answer' + (_ctx.index > 1 ? ' ' + _ctx.index : ''),
            placeholder: "Enter New Security Answer",
            error: _ctx.errors ? _ctx.errors[`security_questions.${_ctx.index - 1}.Answer`] : null
          }, null, 8, ["modelValue", "label", "error"])
        ]))
      : _createCommentVNode("", true)
  ]))
}