<template>
    <div class="card" :id="cardId">
        <div class="card-header-wrapper">
            <div class="card-header-fund">
                <slot name="icon"></slot>
                <h2>
                    <slot name="title"></slot>
                </h2>
            </div>
            <div class="card-control" :class="{ 'plus': !visibleContent }" @click="toggleVisible">
                <span class="line" v-if="visibleContent"></span>
                <i class="plus iatse-icon-plus" v-if="!visibleContent"></i>
            </div>
        </div>
        <transition name="slide-fade">
            <div v-if="visibleContent" class="card-content card-toggle row">
                <slot name="fund-cover"></slot>

                <div class="col-md-3 related-links-wrapper sm-pdt-10">
                    <div class="related-links-header">
                        <p>Related Links</p>
                        <div class="card-control" :class="{ 'plus': !visibleLinks }" @click="toggleRelatedLinks">
                            <span class="line" v-if="visibleLinks"></span>
                            <i class="plus iatse-icon-plus" v-if="!visibleLinks"></i>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="visibleLinks" class="related-links">
                            <slot name='related-links'></slot>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>

        <!-- Alert Overlay -->
        <FundAlert v-if="alerts && !hasGeneralAlerts" :alerts="alerts" @open="openModal" />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import FundAlert from '@/components/cards/FundAlert.vue';

export default defineComponent({
    props: {
        cardId: {
            type: String,
            required: true
        },
        initialVisibility: {
            type: Boolean,
            default: true
        },
        alerts: Array
    },
    components: {
        FundAlert
    },
    setup(props) {
        const modalStore = useModalStore();
        const visibleContent = ref(props.initialVisibility);
        const visibleLinks = ref(true);

        const hasGeneralAlerts = computed(() => useParticipantStore().alerts?.fundData?.General?.length);


        const toggleRelatedLinks = () => {
            visibleLinks.value = !visibleLinks.value;
        }

        const toggleVisible = () => {
            visibleContent.value = !visibleContent.value;
        }

        return {
            hasGeneralAlerts,
            visibleContent,
            visibleLinks,
            toggleRelatedLinks,
            toggleVisible,
            openModal: modalStore.openModal
        };
    }
});
</script>