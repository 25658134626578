<template>
    <MessageCard message="By completing the information below, a request will be sent to the Fund’s Administrative team for processing." type="danger" iconClass="iatse-icon-alarm" classes="info_card_full" />
    <AlertsComponent :errors="generalErrors" />
    <LoaderComponent v-if="loading"/>
    <div v-else class="plan-content">
        <!-- Form -->
        <form id="pension-estimate-request-form" class="form" method="POST" @submit.prevent="submitData">
            <SelectComponent label="Request Type" v-model:selectedOption="formData.type" :options="requestTypes"
                :singleArrow="true" :readonly="true" />
            <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
                v-model:modelValue="formData.participant_id" :readonly="true" />
            <div class="text-input hide-mobile"> </div>

            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="formData.first_name" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="formData.last_name" :readonly="true" />
            <DateInput label="DOB" v-model:modelValue="formData.date_of_birth" :readonly="true" />
            <DateInput v-model:reset="reset" label="Anticipated Retirement Date" v-model:modelValue="formData.retirement_date" :icon="false" :future-allow="true" :past-allow="false" :is-required="true" v-model:error="errors.retirement_date"/>

            <Textarea label="Notes" message="Notes field accepts up to 500 characters" v-model:modelValue="formData.notes" :max="500"/>

            <div class="action-buttons">
                <button @click.prevent="resetModal" class="btn btn-secondary" :disabled="loadingSubmit"> Discard </button>
                <button type="submit" class="btn btn-primary" :disabled="loadingSubmit"> {{ loadingSubmit ? 'Submitting...' : 'Submit' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, toRefs, reactive, onMounted, watch, defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';

export default defineComponent({
    components: {
        TextInput,
        DateInput,
        Textarea,
        SelectComponent,
    },
    props: {
        fund: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const requestTypes = [
            { key: 'estimate', value: 'Estimate Request' },
            { key: 'application', value: 'Pension Application' },
        ];

        const reset = ref(false);

        const formData = reactive({
            type: requestTypes[0],
            participant_id: '',
            first_name: '',
            last_name: '',
            date_of_birth: '',
            retirement_date: '',
            notes: ''
        });

        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors = ref<string[]>([]);
        const generalErrors = ref<string[]>([]);
        const { fund } = toRefs(props);

        const closeModal = () => {
            emit('close');
        }

        const resetModal = () => {
            errors.value = [];
            formData.notes = '';
            formData.retirement_date = '';
            reset.value = true;
        }

        const updateRequestTypeFromQuery = () => {
            const queryParamValue = fund.value;

            if (typeof queryParamValue === 'string' && ['estimate', 'application'].includes(queryParamValue)) {
                formData.type = requestTypes.find(type => type.key === queryParamValue) || requestTypes[0];
            }
        };

        watch(() => fund.value, () => {
            updateRequestTypeFromQuery();
        });

        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/pension/estimate-request');
                if (response.data) {
                    formData.participant_id = response.data.PersonID;
                    formData.first_name = response.data.FirstName;
                    formData.last_name = response.data.LastName;
                    formData.date_of_birth = response.data.DateOfBirth;
                    loading.value = false;
                }
            }
            catch (error) {
                console.error('Error:', error);
            }
        };

        const submitData = async () => {
            const route = 'api/participant/pension/estimate-request';
            const method = 'POST'
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit, method, generalErrors, reset: false });
            await handleSubmit();

            if (success.value) {
                useModalStore().openModal('success-modal', null, 'modal-success', null, {
                    title: "Successful",
                    content: {
                        type: "text",
                        value: success.value,
                        icon: 'iatse-icon-user-verified'
                    },
                    onConfirmButton: 'Ok',
                    onConfirm: () => resetModal()
                })
            }
        };

        onMounted(async () => {
            await fetchData();
            updateRequestTypeFromQuery();
        });

        return {
            reset,
            formData,
            requestTypes,
            success,
            errors,
            generalErrors,
            loading,
            loadingSubmit,
            submitData,
            closeModal,
            resetModal
        };
    }
});
</script>
