<template>
    <div class="plan-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 col-md-8">
                    <h3>Pending COBRA Invoices for {{ personName }}</h3>
                    <p class="pdt-10">COBRA elections can only be made by timely submitting the COBRA application to the Fund Office. COBRA coverage elections cannot be made on this website. This screen is available to make VISA or MasterCard payments once your payment invoice has been issued after your election has been received.</p>
                    <div class="cobra-statements mt-30 row row-cols-1 row-cols-md-2">
                        <template  v-if="cobraStatements.length > 0">
                            <div class="col" v-for="statement in cobraStatements" :key="statement.InvoiceID">
                                <div class="cobra-statement">
                                    <div class="cobra-statement__header">
                                        <h4>{{ statement.PlanName }}</h4>
                                        <div class="checkbox alert-checkbox">
                                            <div class="checkbox-input">
                                                <input
                                                type="checkbox"
                                                class="links alert-checkbox"
                                                :checked="invoicesStore.isInvoiceSelected(statement.InvoiceID)"
                                                @change="invoicesStore.toggleInvoiceSelection(statement.InvoiceID)"
                                                />
                                            </div>
                                        </div>
                                    </div> <!-- #header -->
                                    <div class="cobra-statement__content">
                                        <div class="row align-items-center row-cols-1 row-cols-md-2">
                                            <div class="col">
                                                <div class="cobra-statement__info">
                                                    <h6>For Coverage Period:</h6>
                                                    <p><strong>{{ statement.InsuranceStartDate }}</strong> to<br/><strong>{{ statement.InsuranceEndDate }}</strong></p>
                                                </div>
                                                <div class="cobra-statement__info">
                                                    <h6>Due Date:</h6>
                                                    <p><strong>{{ statement.DueDate }}</strong></p>
                                                </div>
                                                <div class="cobra-statement__info">
                                                    <h6>But no longer than:</h6>
                                                    <p><strong>{{ 'N/A' }}</strong></p>
                                                </div>
                                            </div>
                                            <div class="col d-flex flex-column align-items-center">
                                                <div class="cobra-statement__content__amount">
                                                    <p>Amount Due</p>
                                                    <h5>{{ formatCurrency(statement.Amount) }}</h5>
                                                </div>
                                                <a @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: statement.InvoiceID })" class="btn btn-invoice btn-small mt-20">View Invoice</a>
                                            </div>
                                        </div>
                                    </div> <!-- #content -->
                                    <div class="cobra-statement__footer">
                                        <button class="btn btn-primary w-100" @click="invoicesStore.toggleInvoiceSelection(statement.InvoiceID)">{{ invoicesStore.isInvoiceSelected(statement.InvoiceID) ? 'Selected' : 'Select this invoice'  }}</button>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <!-- Additional Payment -->
                        <div class="col">
                            <div class="cobra-statement">
                                <div class="cobra-statement__header">
                                    <h4>Additional Amount</h4>
                                    <div class="checkbox alert-checkbox">
                                        <div class="checkbox-input">
                                            <input
                                            type="checkbox"
                                            class="links alert-checkbox"
                                            :checked="invoicesStore.payInAdvanceSelected"
                                            @change="invoicesStore.togglePayInAdvance()"
                                            />
                                        </div>
                                    </div>
                                </div> <!-- #header -->
                                <div class="cobra-statement__content">
                                    <div class="row align-items-center row-cols-1 row-cols-md-2">
                                        <div class="text-input">
                                            <input type="text" v-model.number="invoicesStore.payInAdvance" placeholder="Enter Amount" />
                                        </div>
                                    </div>
                                </div> <!-- #content -->
                                <div class="cobra-statement__footer">
                                    <button class="btn btn-primary w-100" @click.prevent="invoicesStore.togglePayInAdvance()">{{ invoicesStore.payInAdvanceSelected ? 'Selected' : 'Select' }}</button>
                                </div>
                            </div>
                        </div>
                    </div> <!-- #end statements -->

                    <div class="row mt-20">
                        <div class="d-flex w-100 justify-content-center justify-content-md-end mt-3">
                            <a href="#" @click.prevent="proceedToCheckout" class="btn btn-primary plan-modal">
                                Make COBRA Payment
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { CobraDueStatement } from '@/interfaces/health';
import { useInvoicesStore } from '@/stores/cobraInvoices';

export default {
    setup() {
        const dataLoaded = ref(false);
        const modalStore = useModalStore();
        const openModal = modalStore.openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const invoicesStore = useInvoicesStore();

        const cobraStatements = ref<CobraDueStatement[]> ([]);
        const personName = ref<string> ('');

        // Fetch data from the API, sending a flag if periods are already fetched
        const fetchData = () => {
            const params: Record<string, any> = {};

            // @TBD !!! We need to change InvoiceID to come as integer and make sure everything works good
            axios
                .get('/api/participant/health-welfare/cobra-statements', { params })
                .then((response) => {
                    // Store other plan summary details
                    personName.value = response.data.PersonName;
                    cobraStatements.value = response.data.CobraStatements;
                    cobraStatements.value.forEach((statement) => {
                        invoicesStore.addInvoice({
                            InvoiceID: statement.InvoiceID,
                            PlanName: statement.PlanName,
                            InsuranceStartDate: statement.InsuranceStartDate,
                            InsuranceEndDate: statement.InsuranceEndDate,
                            DueDate: statement.DueDate,
                            Amount: statement.Amount,
                        });
                    });
                    dataLoaded.value = true;

                    // Check for invoideID passed as param to preselect it, usually comes from payments history table
                    const invoiceID = Number(modalStore.currentModal?.queryParams?.InvoiceID);
                    if (invoiceID) {
                        const matchingInvoice = cobraStatements.value.find(statement => statement.InvoiceID === invoiceID);
                        if (matchingInvoice) {
                            invoicesStore.toggleInvoiceSelection(matchingInvoice.InvoiceID);
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        const proceedToCheckout = () => {
            const oldestInv = invoicesStore.oldestInvoice;

            if (oldestInv) {
                if (!invoicesStore.isInvoiceSelected(oldestInv.InvoiceID)) {
                alert('Please select the oldest invoice first to proceed to checkout.');
                return;
                }

                if (
                invoicesStore.payInAdvanceSelected &&
                invoicesStore.invoices.length > 0 &&
                !invoicesStore.isInvoiceSelected(oldestInv.InvoiceID)
                ) {
                alert('Please select the oldest invoice before selecting pay in advance.');
                return;
                }
            }
            openModal('hw-cobra-checkout', 'Checkout', 'plan-upload plan-card-info plan-c-mrp-claims');
        };

        onMounted(() => {
            fetchData(); // Initial fetch on mount
        });

        return {
            personName,
            openModal,
            cobraStatements,
            dataLoaded,
            formatCurrency,
            invoicesStore,
            // pay in advance
            proceedToCheckout
        };
    }
};
</script>