<template>
    <div :class="['plan', { [classNames]: true, [modalType]: true, 'active': isVisible, 'slide-out': !isVisible && modalType !== 'slide-out' }]"
        :style="modalStyles" @click.self="handleClickOutside">
        <div class="plan-header" v-if="title">
            <div class="plan-breadcrumbs">
                <div class="plan-breadcrumbs-left">
                    <i class="iatse-icon-left-arrow close-plan-modal" @click="closeModal"></i>
                    <h3 v-if="!secondTitle">{{ title }}</h3>
                    <h3 v-else @click="closeModal">
                        <p>{{ title }} </p>
                    </h3>
                    <h3 class="active pdl-10" v-if="secondTitle"> > {{ secondTitle }} </h3>
                </div>
                <div class="plan-switch">
                    <slot name="steps"> </slot>
                    <a v-for="(button, index) in headerButtons" :key="index" href="#"
                        :class="['btn plan-modal', { 'btn-primary': button?.type === 'primary', 'btn-secondary': button?.type === 'secondary' }]"
                        @click.prevent="handleButtonModal(button)">
                        {{ button.name }}
                    </a>
                    <div class="close-btn close-plan-modal" @click="closeModal"> <i class="iatse-icon-close"></i></div>
                </div>
            </div>
        </div>
        <component :is="getModalContent(modalType)" @close="closeModal" @close-above="closeAbove"
            @handle-submit="handleSubmit" @toggle-header-button="toggleHeaderButton" :fund="fund"
            :modalProps="modalProps" :index="index" />
    </div>
    <div v-if="modalType === 'success-modal'" class="modal-overlay success-modal-overlay"></div>
    <div v-if="modalType === 'smartsearch'" @click.self="closeOnOutsideClick" class="modal-overlay success-modal-overlay smartsearch"></div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { ModalProps, HeaderButton } from "@/interfaces/interfaces";
import { useParticipantStore } from '@/stores/participant';

export default defineComponent({
    name: 'ModalComponent',
    props: {
        isVisible: {
            type: Boolean,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        modalType: {
            type: String,
            default: ''
        },
        classNames: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        secondTitle: {
            type: String || null,
            default: null
        },
        fund: {
            type: String,
            default: null,
        },
        modalProps: {
            type: Object as PropType<ModalProps | null>,
            default: null,
        },
        headerButtons: {
            type: Array as PropType<HeaderButton[] | null>,
            default: null,
        },
    },
    setup(props, { emit }) {
        const modalStore = useModalStore();
        const localHeaderButton = ref<HeaderButton[] | null>(props.headerButtons);

        const closeModal = () => {
            useParticipantStore().currentStep = 1;
            emit('close', props.index);
        };

        const closeAbove = (index: number) => {
            emit('close-above', index);
        };

        const closeOnOutsideClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            if (!target.closest('.plan.smartsearch')) {
                modalStore.closeLastModal();
            }
        }

        const handleClickOutside = () => {
            modalStore.closeAbove(props.index);
        }

        const handleButtonModal = (button: HeaderButton) => {
            if (button.name === 'Add Beneficiary') {
                useBeneficiaryStore().resetNewBene();
                useBeneficiaryStore().isAddAction = true;
                useBeneficiaryStore().isEditAction = false;
            }

            modalStore.openModal(button.action, button.name, button.classes)
        }

        const toggleHeaderButton = (payload: HeaderButton[]) => {
            localHeaderButton.value = payload;
        }

        const modalStyles = computed(() => {
            const offset = 290 + Number(props.index * 20);

            return {
                "--modal-width": `calc(100% - ${offset}px)`,
            };
        });

        return {
            modalStyles,
            localHeaderButton,
            closeModal,
            closeAbove,
            handleClickOutside,
            closeOnOutsideClick,
            toggleHeaderButton,
            handleButtonModal,
            handleSubmit: modalStore.handleSubmit,
            getModalContent: modalStore.getModalContent,
        };
    }
});
</script>