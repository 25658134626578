<template>
    <div class="plan-content">
        <h2 class="sm-fs-base">Upload And Enter List of Employees </h2>
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
            <div v-show="success" class="alert alert-success">{{ success }}</div>
            <div v-show="error" class="alert alert-danger">{{ error }}</div>
        </div>
        <div class="d-flex flex-column gap-30 w-100">
            <SelectComponent label="Year of Coverage (Required, Applies to Enter Form)" v-model:selectedOption="year" :options="years" />
            <div v-if="ssnOption === '0'" class="bordered-card bordered-card_secondary">
                <h2 class="sm-fs-base">Upload Documents</h2>
                <small>
                    There is only one acceptable Excel file format. It must contain a single column with
                    "SSN" as the header. <br />
                    Each SSN must be in number format with hyphens (No other special characters or space)
                    and must contain 9 digits.<br />
                    For Example: XXX-XX-XXXX. Click <a :href="excelModel" target="_blank">here</a> for sample format.
                </small>
                <DragDrop accept=".xlsx, .csv" customClasses="blue large w-100" @change="handleFileSelection" browseText="Browse" icon="iatse-icon-Upload-icon" key="drag-drop-aca" :reset="reset" @reset="handleReset" :loading="loading"/>

                <div v-if="loading || fileHashes.length" class="w-100 d-flex flex-column gap-15">
                    <h5 class="fw-semibold">Uploaded - <span> files</span></h5>
                    <div v-for="(fileHash, index) in fileHashes" :key="index" class="bordered-card bordered-card_secondary flex-row fs-xs align-items-center p-2">
                        <span> {{ fileHash }} </span>
                        <div class="btn-delete small plan-modal" @click="handleDelete(index)">
                            <i class="iatse-icon-trash icon_delete plan-modal"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="bordered-card bordered-card_secondary">
                <h2 class="sm-fs-base">Employees </h2>

                <label class="sm-fs-sm">
                    # SSN
                    <span class="text-danger">*</span>
                    <span class="tooltip-wrapper tooltip-wrapper_secondary" data-tooltip="Social Security Number should be 9 digit numeric only. After entry of an SSN hit Enter or click the ‘Add
                        Another Employee’ button to enter the next SSN">
                        <i class="iatse-icon-info-circle pdl-10"></i>
                    </span>
                </label>

                <div v-for="(ssn, index) in ssnList" :key="index" class="d-flex gap-4 align-items-center w-100">
                    <TextInput type="text" name="ssn" :max=11 v-model:modelValue="ssnList[index]" :is-required="true" pattern="\d{3}-\d{2}-\d{4}"
                        placeholder="***-**-****"  @keyup.enter="addAnotherEmployee" custom-class="w-100"/>

                    <div v-if="index > 0" class="btn-delete">
                        <i class="iatse-icon-trash icon_delete plan-modal fs-4" @click="deleteEmployee(index)"></i>
                    </div>
                </div>

                <button class="btn btn-primary" @click="addAnotherEmployee">Add Another Employee</button>
            </div>
        </div>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-between gap-4">
        <div class="d-flex gap-4">
            <button class="btn btn-secondary" @click="handleBack" :disabled="loading || deleting">Back</button>
        </div>
        <div class="d-flex gap-4">
            <button class="btn btn-secondary" @click.prevent="handleCancel" :disabled="loading || deleting">Cancel</button>
            <button class="btn btn-secondary" @click="handleSubmit" :disabled="loading">
            {{ submitting ? 'Submitting...' : 'Submit Request' }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { SelectOption } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';

import TextInput from '@components/form/TextInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import DragDrop from '@components/form/DragDrop.vue';


export default defineComponent({
    props: {
        ssnOption: String,
        excelModel: String,
        submitting: Boolean
    },
    components: {
        TextInput,
        SelectComponent,
        DragDrop,
    },
    setup(props, { emit }) {
        const ssnList: Ref<string[]> = ref(['']);
        const year = ref({key: new Date().getFullYear(), value: new Date().getFullYear()});
        const years: Ref<SelectOption[]> = ref([]);
        const fileHashes = ref<string[]>([]);
        // const formData = ref(new FormData());
        const loading = ref(false);
        const deleting = ref(false);
        const success = ref(null);
        const error: Ref<string | null> = ref(null);
        const fileInput = ref<HTMLInputElement | null>(null);
        const files: Ref<FileList | null> = ref(null);
        const reset = ref(false);

        const handleBack = () => {
            emit('handle-back');
        }

        const handleReset = () => {
            reset.value = false;
        }

        const handleSubmit = () => {
            const formData = {
                'Year': year.value?.key,
                'SSNs': ssnList.value.every(item => item.trim() === '') ? null : ssnList.value.filter(item => item.trim() !== ''),
                'Type': props.ssnOption === '0' ? 'excel' : 'web',
                'FileHash': fileHashes.value
            }

            emit('handle-submit', formData);
        }

        const handleDelete = async (index: number) => {
            deleting.value = true;
            success.value = null;
            error.value = null;

            await axios.delete(`api/employer/health-coverage-data/delete-file/${fileHashes.value[index]}`)
                .then(response => {
                    success.value = response.data.success;
                    // fileHashes[index].value = '';
                    fileHashes.value.splice(index, 1);
                })
                .catch(() => {
                    error.value = 'There was an error deleting document!'
                });

            deleting.value = false;
            files.value = null;
            fileInput.value = null;

            setTimeout(() => {
                success.value = null;
                error.value = null;
            }, 3500)
        };

        const browseFiles = async () => {
            if (fileInput.value) {
                fileInput.value.value = '';
            }

            fileInput.value?.click();
        }

        const addAnotherEmployee = () => {
            ssnList.value.push('');
        };

        const deleteEmployee = (index: number) => {
            ssnList.value.splice(index, 1);
        };

        const handleCancel = () => {
            useModalStore().closeLastModal();
        }

        const handleFileSelection = async (event: Event | DragEvent) => {
            loading.value = true;
            // files.value = (event.target as HTMLInputElement)?.files || Array.from(event as DragEvent);
            const formData = new FormData();
            const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;
            // fileHashes.value = [];
            success.value = null;
            error.value = null;

            if (files) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('Files[]', files[i]);
                }

                await axios.post('api/employer/health-coverage-data/add-file', formData)
                    .then(response => {
                        success.value = response.data.success;
                        // fileHashes.value = response.data.FileHashes;
                        fileHashes.value = [...fileHashes.value, ...response.data.FileHashes];
                        console.log('test')
                        reset.value = true;
                    })
                    .catch(error => {
                        error.value = 'There was an error uploading document!'
                    });

                setTimeout(() => {
                    success.value = null;
                    error.value = null;
                    reset.value = true;
                    // formData.value = new FormData();
                }, 3500)
            }

            loading.value = false;
        };

        onMounted(() => {
            // Dynamically generate years to 10 years in the future
            const currentYear = new Date().getFullYear();
            for (let i = currentYear - 10; i <= currentYear + 10; i++) {
                years.value.push({key: i, value: i.toString()});
            }
        });

        return {
            ssnList,
            year,
            years,
            loading,
            deleting,
            success,
            error,
            fileInput,
            fileHashes,
            reset,
            handleReset,
            browseFiles,
            handleBack,
            handleSubmit,
            handleCancel,
            addAnotherEmployee,
            deleteEmployee,
            handleDelete,
            handleFileSelection
        }
    }
})
</script>