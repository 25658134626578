<template>
  <AlertsComponent :errors="errors" :success="success" />
  <div class="pdt-30">
    <div class="d-flex justify-content-between align-items-center pdb-20">
      <h2>Employers</h2>
      <div v-if="selectedType?.key == 0" class="form-group row mb-4">
        <div class="col-md-2 d-flex gap-2 justify-content-between align-items-center">
          <span class="tooltip-wrapper" data-tooltip="Pending Requests">
            <i class="plan-modal iatse-icon-noun-reset fs-xl" :class="{ 'icon-active': processed === 0 }"
            @click="filterRequests(0)"> </i>
          </span>
          <p> / </p>
          <span class="tooltip-wrapper" data-tooltip="Rejected Requests">
            <i class="plan-modal iatse-icon-reset fs-xl" :class="{ 'icon-active': processed === 3 }"
            @click="filterRequests(3)"> </i>
          </span>
        </div>
      </div>
    </div>
    <div class="bordered-card bordered-card_secondary">
      <div class="filters d-flex gap-4 flex-wrap flex-md-nowrap justify-content-between w-100">
        <div class="d-flex gap-10 col-md-8 col-sm-12">
          <SelectComponent v-model:selectedOption="selectedType" :options="types" :single-arrow="true"
            customClass="fs-xs w-50" />
          <SelectComponent v-model:selectedOption="filters.TaxID" :options="taxOptions" :single-arrow="true"
            customClass="fs-xs w-50" :defaultOption="{ key: 0, value: 'Filter by EIN/Tax ID #' }" />
        </div>
        <TextInput type="text" name="mixed" placeholder="Search by Company Name" :max=50 v-model:modelValue="filters.CompanyName"
          customClass="search" />
      </div>

      <LoaderComponent v-if="loading" />
      <template v-else>
        <EmployerRequests v-if="selectedType?.key == 0" :employers="pendingEmployers" :processed="filters.Processed"
          @filter-requests="filterRequests" @send-pin="sendPin" @reject-request="rejectRequest" />
        <SearchEmployersList v-else :employers="employers" @reset-pin="resetPin" @activate="activateAccount"
          @deactivate="deactivateAccount" />
      </template>
    </div>
    <PaginationComponent v-if="!loading" :paginator="paginator" @fetch-data="fetchPaginationData" />
  </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, onMounted, watch } from "vue";
import {
  SearchEmployer,
  SearchEmployersFormFilters,
  PendingEmployer,
  Paginator,
} from "@/interfaces/admin";
import { SelectOption } from '@/interfaces/interfaces';
import { useOptionsFormatter } from '@/utils/optionFormatter';
import SearchEmployersList from "@/components/superadmin/SearchEmployersList.vue";
import EmployerRequests from "@/components/superadmin/EmployerRequests.vue";
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";
import PaginationComponent from '@components/utils/PaginationComponent.vue';
import axios from "axios";

export default {
  components: {
    SearchEmployersList,
    EmployerRequests,
    TextInput,
    SelectComponent,
    PaginationComponent
  },

  setup() {
    const loading = ref(true);
    const searching = ref(false);
    const employers = ref<SearchEmployer[]>([]);
    const pendingEmployers = ref<PendingEmployer[]>([]);
    const paginator: Paginator = reactive({
      per_page: 15,
      total: 100,
      last_page: 100,
      current_page: 1,
    });
    const page = ref(1);
    const route = "api/administrator/users/employers";
    const success = ref(null);
    const errors: Ref<string[]> = ref([]);
    const filters = reactive<SearchEmployersFormFilters>({
      TaxID: null,
      CompanyName: "",
      Processed: 0,
    });

    const typesArray: SelectOption[] = useOptionsFormatter(['Pending Request', 'Manage All Accounts']);
    const types = ref<SelectOption[]>(typesArray);
    const selectedType = ref({ key: 1, value: 'Manage All Accounts'});
    const taxOptions = ref<SelectOption[]>([]);

    watch(filters, async () => {
      searching.value = true;
      page.value = 1;
      await fetchData(false);
      searching.value = false;
    }, { deep: true });

    watch(selectedType, async (newVal, oldValue) => {
      if (newVal !== oldValue) {
        searching.value = true;
        filters.CompanyName = '';
        filters.Processed = 0;
        page.value = 1;
        await fetchData(true);
        filters.TaxID = taxOptions.value[0];
        searching.value = false;
      }
    }, { deep: true });

    const fetchData = async (isLoading: boolean) => {
      try {
        if (isLoading) loading.value = true;

        const response = await axios.get(route, {
          params: { ...filters, Type: selectedType.value ?? 0, page: page.value },
        });

        employers.value = response.data.Employers;
        pendingEmployers.value = response.data.PendingEmployers;
        const options: string[] = response.data.TaxOptions;
        taxOptions.value = useOptionsFormatter(options);

        Object.assign(paginator, response.data.Paginator);
      }
      catch (error) {
        console.error("Error:", error);
      }

      if (isLoading) loading.value = false;
    };

    // Handle Send Pin
    const sendPin = async (requestID: number) => {
      const index = pendingEmployers.value.findIndex(req => req.UIEmployerRequestID === requestID);

      try {
        pendingEmployers.value[index].SendingPin = true;
        const response = await axios.post(
          `${route}/send-pin/${requestID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error: any) {
        errors.value = Object.values(error.response.data.error).flat() as string[];
      }

      await refreshData();
      pendingEmployers.value[index].SendingPin = false;
    };

    // Handle Reset Pin
    const resetPin = async (userID: number) => {
      const index = employers.value.findIndex(employer => employer.UserID === userID);

      try {
        employers.value[index].ResettingPin = true;
        const response = await axios.post(
          `api/administrator/users/reset-pin/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;

        if (response.data.error) {
          errors.value = Object.values(
            response.data.error
          ).flat() as string[];
        }
      }
      catch (error: any) {
        errors.value = Object.values(error.response.data.error).flat() as string[];
      }

      employers.value[index].ResettingPin = false;
      await refreshData();
    };

    // Handle Activate Account
    const activateAccount = async (userID: number) => {
      const index = employers.value.findIndex(employer => employer.UserID === userID);
      employers.value[index].Activating = true;

      try {
        const response = await axios.post(
          `api/administrator/users/activate/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error activating account:", error);
      }

      await refreshData();
      employers.value[index].Activating = false;
    };

    // Handle Deactivate Account
    const deactivateAccount = async (userID: number) => {
      const index = employers.value.findIndex(employer => employer.UserID === userID);
      employers.value[index].Deactivating = true;

      try {
        const response = await axios.post(
          `api/administrator/users/deactivate/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error deactivating account:", error);
      }

      await refreshData();
      employers.value[index].Deactivating = false;
    };

    // Handle Reject Request
    const rejectRequest = async (requestID: number) => {
      try {
        const index = pendingEmployers.value.findIndex(req => req.UIEmployerRequestID === requestID);
        pendingEmployers.value[index].Rejecting = true;
        const response = await axios.post(
          `${route}/reject/${requestID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
        await refreshData();
        pendingEmployers.value[index].Rejecting = false;
      }
      catch (error) {
        console.error("Error rejecting request:", error);
      }
    };

    const refreshData = async () => {
      await fetchData(false);
      setTimeout(() => {
        success.value = null;
        errors.value = [];
      }, 3500);
    };

    // Filter Employers
    const updateFilters = async (
      newFilters: SearchEmployersFormFilters
    ) => {
      page.value = 1;
      Object.assign(filters, newFilters);
    };

    // Filter Employer Requests
    const filterRequests = async (processed: number) => {
      page.value = 1;
      filters.Processed = processed;
    };

    // Fetch Data For Selected Page
    const fetchPaginationData = (pageNumber: number) => {
      page.value = pageNumber;
      fetchData(false);
    };

    onMounted(async () => await fetchData(true));

    return {
      types,
      selectedType,
      filters,
      employers,
      pendingEmployers,
      taxOptions,
      loading,
      searching,
      paginator,
      success,
      errors,
      sendPin,
      rejectRequest,
      resetPin,
      activateAccount,
      deactivateAccount,
      updateFilters,
      filterRequests,
      fetchPaginationData,
    };
  },
};
</script>
