<template>
    <LoaderComponent v-if="loading" />
    <div class="plan-content gap-20" v-else>
        <DocumentsSection v-if="documents && documents.length" :documents="documents" />

        <div v-if="formRoute === 'participant.dependents.add'">
            <ReviewDependent :data="formData" />
        </div>
        <div v-else-if="formRoute === 'participant.personal.info' && formData?.Person">
            <!-- <AlertComponent :errors="errors" /> -->
            <ReviewUpdateInfo :data="formData" />
        </div>
        <ReviewInfo :approveButton="approveButton" :comments="formData?.Comments ?? ''" :status="status"
            @handle-review="handleReview" :loading-review="loadingSubmit"/>
    </div>
</template>

<script lang="ts">
import { watch, ref, onMounted, Ref, reactive, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useSuperadminStore } from '@/stores/superadmin';
import { useDependentStore } from '@/stores/dependent';
import { useParticipantStore } from '@/stores/participant';
import { ReviewDependentData, Document } from '@interfaces/interfaces'
import ReviewUpdateInfo from '@components/profile/personal-info/ReviewUpdateInfo.vue'
import ReviewInfo from '@components/superadmin/ReviewInfo.vue'
import DocumentsSection from '@components/participant/dependents/DocumentsSection.vue';
import ReviewDependent from '@components/participant/dependents/ReviewDependent.vue';
import axios from 'axios';

export default {
    components: {
        DocumentsSection,
        ReviewDependent,
        ReviewUpdateInfo,
        ReviewInfo,
    },

    setup() {
        const modalStore = useModalStore();
        const superadminStore = useSuperadminStore();
        const dependentStore = useDependentStore();
        const participantStore = useParticipantStore();
        const success = ref(null);
        const loading = ref(false);
        const formRoute = ref('');
        const approveButton: Ref<Record<string, boolean> | null> = ref(null);
        const formData: Ref<ReviewDependentData | null> = ref(null);
        const reviewSection = ref(false);
        const title = ref('');
        const status: Ref<string | number> = ref(1);
        const documents: Ref<Document[] | []> = ref([]);
        const message = ref('');
        const successModalRef = ref(null);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const data = reactive({ Comments: '' });
        const action = ref('');
        const openModal = modalStore.openModal;
        let adminID = computed(() => superadminStore.reviewReq);

        // Display Confirmation Modal
        const handleReview = (type: string, commentsInput: string) => {
            data.Comments = commentsInput;
            action.value = type;

            openModal('success-modal', null, 'modal-success', null, {
                title: 'Confirmation',
                content: {
                    type: "text",
                    value: `Are you sure you want to ${type}?`,
                    icon: 'iatse-icon-user-verified'
                },
                onConfirm: handleSubmit
            })
        }

        // Resolve Request
        const handleSubmit = async() => {
            loadingSubmit.value = true;

            await axios.post(`api/administrator/resolve-request/${adminID.value}`, { ...data, _method: "PATCH" }, { params: { Action: action.value } })
                .then((response) => {
                    const res = response.data;
                    modalStore.closeLastModal();
                    superadminStore.fetchRequests();
                    dependentStore.fetchDependents();
                    dependentStore.clearDependent();
                    participantStore.fetchAlerts();
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = error.response.data.error;
                        loadingSubmit.value = false;
                    }
                })
                .finally(() => loadingSubmit.value = false)
        };

        const fetchData = async (isLoading: boolean) => {
            if (isLoading) {
                loading.value = true;
            }

            await axios.get(`api/administrator/review-request/${adminID?.value}`)
                .then(response => {
                    const data = response.data;
                    formRoute.value = data.FormRoute;
                    approveButton.value = data.ApproveButton;
                    formData.value = data.Data;
                    reviewSection.value = data.ReviewSection;
                    title.value = data.Title;
                    documents.value = data.Documents;
                    status.value = data.Status;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => await fetchData(true));

        return {
            formData,
            formRoute,
            title,
            approveButton,
            reviewSection,
            documents,
            status,
            message,
            success,
            loading,
            errors,
            successModalRef,
            loadingSubmit,
            handleReview,
            handleSubmit,
        };
    },
};

</script>