<template>
    <AlertsComponent m:errors="errors" :success="success" />
    <div class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Member Current Coverage Search</h2>
        <div v-if="!loadingSearch && !hasBAAOnFile" class="bordered-card mt-40">
            <p>
                You have no Business Associate Agreement (BAA) on file.
            </p>
            <p v-html="localStore.getBAAMessage()"></p>
        </div>
        <template v-if="hasBAAOnFile">
            <form class="d-flex flex-column gap-20">
                <div class="d-flex gap-20 align-items-end w-100">
                    <div class="custom-select links w-100">
                        <Multiselect v-model="filters.PlanType" :options="planTypes" placeholder="Type to search"
                            :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                            @select="handleTypeChange" label="description" track-by="code">
                        </Multiselect>
                    </div>
                    <TextInput type="text" name="number" placeholder="Add Member’s NBF ID/SSN" :max=25 v-model:modelValue="filters.ParticipantID" customClass="w-100" />
                    <TextInput type="text" placeholder="Add Member’s First Name" :max=25 v-model:modelValue="filters.FirstName" customClass="w-100" />
                    <TextInput type="text" placeholder="Add Member’s Last Name" :max=25 v-model:modelValue="filters.LastName" customClass="w-100" />
                </div>
                <div class="d-flex gap-20 justify-content-between w-100 mt-30 mb-30">
                    <button type="button" class="btn btn-secondary" @click.prevent="resetFilters">Clear Filters</button>
                    <button type="button" v-if="false" class="btn btn-primary" @click.prevent="printList">Print List</button>
                    <button type="submit" class="btn btn-primary" @click.prevent="searchData" @keydown.enter="searchData">
                        {{ loadingSearch ? 'Searching...' : 'Search' }}
                    </button>
                </div>
            </form>
            <LoaderComponent v-if="loadingSearch" />
            <div v-else>
                <table class="small striped toggle no-underline mt-30" id="table-contract-info">
                    <thead class="large large_padding">
                        <tr>
                            <th>NBF ID</th>
                            <th>Name</th>
                            <th>Current Coverage</th>
                            <th>Coverage <br> <small> (Effective Date - Term Date) </small> </th>
                            <th>Bad Address </th>
                            <th>Upcoming Coverage </th>
                            <th>Plan C Balance </th>
                            <th>Plan A Days</th>
                        </tr>
                    </thead>
                    <tbody v-if="coverageList.length">
                        <tr v-for="person in coverageList" :key="person.PersonID" class="large">
                            <td> <a href="javascript;" class="plan-modal"
                                    @click.prevent="openModal('local-member-profile', 'Member Profile', 'plan-profile plan-tables profile', null, { PersonID: person.PersonID })">
                                    {{ person.PersonID }}
                                </a></td>
                            <td>{{ person.FullName }}</td>
                            <td>{{ person.Plan }}</td>
                            <td>{{ person.Coverage }}</td>
                            <td>{{ person.BadAddress }}</td>
                            <td> - </td>
                            <td> - </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" class="text-center"> No records found</td>
                        </tr>
                    </tbody>
                </table>
                <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, computed, onMounted} from 'vue';
import { Coverage, PlanType } from "@/interfaces/local";
import { Paginator } from "@/interfaces/interfaces";
import { usePrint } from '@/composable/usePrint';
import { useModalStore } from '@/stores/modal';
import { useLocalStore } from '@/stores/local';
import axios from 'axios';

import PaginationComponent from '@components/utils/PaginationComponent.vue';
import TextInput from '@components/form/TextInput.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Filters {
    PlanType: PlanType[];
    FirstName: string;
    LastName: string;
    ParticipantID: string;
}

export default {
    components: {
        Multiselect,
        TextInput,
        PaginationComponent
    },

    setup() {
        const loadingSearch = ref(false);
        const localStore = useLocalStore();
        const hasBAAOnFile = computed(() => localStore.dashboard?.Profile?.BusinessAgreementFile);

        const planTypes: PlanType[] = [
            { code: 'ALL', description: 'ALL' },
            { code: 'PLAN_A', description: 'Plan A' },
            { code: 'PLAN_C', description: 'Plan C' },
            { code: 'COBRA', description: 'COBRA' },
            { code: 'C_MRP', description: 'C-MRP' },
            { code: 'RMRP', description: 'RMRP' },
            { code: 'RETIREE', description: 'Retiree' }
        ];
        const filters: Filters = reactive({
            PlanType: [planTypes[0]],
            FirstName: '',
            LastName: '',
            ParticipantID: '',
        });
        const paginator: Paginator = reactive({
            per_page: 0,
            total: 0,
            last_page: 0,
            current_page: 0,
        });
        const page = ref(1);
        const coverageList: Ref<Coverage[]> = ref([]);
        const error = ref(null);
        const { printElementById } = usePrint();

        const resetFilters = () => {
            coverageList.value = [];

            Object.keys(filters).forEach((key) => {
                if (key === 'PlanType') {
                    (filters[key as keyof typeof filters] as PlanType[]) = [planTypes[0]];
                }
                else {
                    (filters[key as keyof typeof filters] as string) = '';
                }
            });
        }

        const handleTypeChange = (newSelection: PlanType) => {
            if (newSelection.code === 'ALL') {
                filters.PlanType = [planTypes[0]];
            }
            else {
                const filteredTypes: PlanType[] = JSON.parse(JSON.stringify(filters.PlanType)).filter((type: PlanType) => type.code !== 'ALL')

                filters.PlanType = [...filteredTypes];
            }
        }

        const searchData = async () => {
            loadingSearch.value = true;
            coverageList.value = [];

            await axios.get('api/local/search-coverage', {
                params: { ...filters, page: page.value }
            })
                .then(response => {
                    const res = response.data;
                    coverageList.value = res.Paginator.data.length > 0 ? res.Paginator.data : res.Coverages;
                    Object.assign(paginator, res.Paginator);
                    page.value = paginator.current_page;
                })
                .catch(errorRes => {
                    error.value = errorRes.response.data.error;
                    setTimeout(() => error.value = null, 3500);
                })

            loadingSearch.value = false;
        }

        const printList = printElementById('table-coverage-search', 'Member Coverage List');


        // Fetch Data For Selected Page
        const fetchPaginationData = (pageNumber: number) => {
            page.value = pageNumber;

            searchData();
        };

        onMounted(async () => {
            loadingSearch.value = true;

            if (!localStore.dashboard) {
                await localStore.fetchDashboard();
            }

            loadingSearch.value = false;
        })

        return {
            loadingSearch,
            localStore,
            hasBAAOnFile,
            error,
            planTypes,
            paginator,
            filters,
            coverageList,
            resetFilters,
            handleTypeChange,
            fetchPaginationData,
            searchData,
            printList,
            openModal: useModalStore().openModal
        };
    }
}
</script>