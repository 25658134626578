<template>
    <!-- Dependent Relationship -->
    <h3> Select Dependent Relationship </h3>
    <SelectComponent customClass="full-width pdt-5 h-auto" v-model:selectedOption="dependentStore.Person.Relationship"
        :options="dependentStore.relationships" :single-arrow="true" :readonly="isEditAction"
        :error="errors.Relationship" :hasDefaultOption=false  :is-required="true"/>

    <!-- Dependent Information -->
    <h3 class="w-100 pdt-15"> Add Dependent Information </h3>
    <div class="w-100 d-flex gap-20 align-items-end">
        <SelectComponent label="Country" v-model:selectedOption="dependentStore.Person.CitizenshipCode"
            :options="dependentStore.countries" :single-arrow="true" :readonly="isEditAction" />
        <div class="w-100 d-flex gap-20 align-items-end">
            <TextInput type="text" :name="ssnType" :label="ssnLabel" :placeholder="ssnPlaceholder" :max=ssnMaxLength :readonly="isEditAction"
                v-model:modelValue="dependentStore.Person.SSN" :required="true" :error="errors.SSN" :is-required="dependentStore.Person.Relationship?.key == '181' || (dependentStore.Person.Relationship?.key == '162' && !dependentStore.Person.NoSSN)"/>
            <CheckBox v-if="isNoSSNVisible" :readonly="isEditAction" label="Please check the box if you have not yet acquired a SSN for this Dependent." className="ssn-checkbox fs-sm" id="ssn" @change="handleChildSSN" v-model:modelValue="dependentStore.Person.NoSSN" :showLabel="true" />
        </div>
    </div>

    <div class="w-100 d-flex gap-4">
        <SelectComponent label="Salutation" v-model:selectedOption="dependentStore.Person.Salutation"
            :options="dependentStore.salutations" :single-arrow="true" :hasDefaultOption="true"
            :readonly="isEditAction" />
        <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
            v-model:modelValue="dependentStore.Person.FirstName" :readonly="isEditAction" :required="true"
            :error="errors.FirstName"  :is-required="true"/>
        <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12
            v-model:modelValue="dependentStore.Person.MiddleName" :readonly="isEditAction" :error="errors.MiddleName" />
        <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
            v-model:modelValue="dependentStore.Person.LastName" :readonly="isEditAction" :error="errors.LastName"  :is-required="true"/>
        <SelectComponent label="Suffix" v-model:selectedOption="dependentStore.Person.NameSuffix"
            :options="dependentStore.nameSuffixes" :single-arrow="true" :hasDefaultOption="true"
            :readonly="isEditAction" />
    </div>

    <DateInput label="Date of Birth" v-model:modelValue="dependentStore.Person.DateofBirth"
        :placeholder="isEditAction ? 'dd/mm/yyyy' : ''" input-type="date" :readonly="isEditAction"
        :error="errors.DateofBirth"  :is-required="true" :futureAllow="false"/>
    <SelectComponent label="Gender" v-model:selectedOption="dependentStore.Person.Sex" :options="dependentStore.genders"
        :single-arrow="true" :readonly="isEditAction"/>

    <!-- Spouse Related Fields -->
    <SelectComponent v-if="isMaritalStatusVisible" label="Marital Status"
        v-model:selectedOption="dependentStore.Person.MaritalStatus" :options="dependentStore.maritalStatuses"
        :single-arrow="true" :error="errors.MaritalStatus" :is-required="true" :readonly="isEditAction"/>
    <DateInput v-if="isMarriageDateVisible" label="Marriage/Affidavit Date"
        v-model:modelValue="dependentStore.Person.MarriageDate" :placeholder="isEditAction ? 'dd/mm/yyyy' : ''"
        input-type="date" :readonly="isEditAction" :error="errors.MarriageDate" :is-required="true" :futureAllow="false"/>
    <DateInput v-if="isDivorceDateVisible" label="Divorce/Affidavit Date"
        v-model:modelValue="dependentStore.Person.DivorceDate" :placeholder="isEditAction ? 'dd/mm/yyyy' : ''"
        input-type="date" :readonly="isEditAction" :error="errors.DivorceDate" :is-required="true" :futureAllow="false"/>
    <DateInput v-if="isDateofDeathVisible" label="Date of Death/Affidavit Date"
        v-model:modelValue="dependentStore.Person.DateofDeath" :placeholder="isEditAction ? 'dd/mm/yyyy' : ''"
        input-type="date" :readonly="isEditAction" :error="errors.DateofDeath" :is-required="true" :futureAllow="false"/>
    <!-- <div class="w-50"> </div> -->

    <!-- Address Options -->
    <div v-if="!isEditAction" class="radio-group bold highlight">
        <div class="radio-buttons w-50">
            <div v-for="(option, index) in options" :key="index" :class="{'highlight bg-light-green': dependentStore.CreateAddressType == option.value, 'disabled bg-dark-grey': isEditAction}" class="radio-button bordered-card bordered-card_secondary pdt-20 pdb-20 align-items-start">
                <input type="radio" :id="`PaymentOption${index + 1}`"  name="PaymentOption" :value="option.value"
                    v-model="dependentStore.CreateAddressType" :disabled="isEditAction"/>
                <label class="body-text" :for="`PaymentOption${index + 1}`" >
                    {{option.label}}
                </label>
            </div>
        </div>
    </div>

    <!-- Address Fields -->

    <!-- <div v-if="!dependentStore.ShowAddress && !isEditAction" class="d-flex gap-4 pdt-30">
        <button type="button" class="btn btn-primary" @click.prevent="handleSetAddress(true)"> Use My Primary
            Address</button>
        <button type="button" class="btn btn-secondary" @click.prevent="handleSetAddress(false)">
            Add New Address
        </button>
    </div> -->
    <!-- <button v-else-if="!isEditAction" type="button" class="btn btn-secondary" @click.prevent="handleSetAddress(false, true)">
        Discard Address Changes
    </button> -->

    <template v-if="(isEditAction && dependentStore.Address?.Type.key) || (!isEditAction && dependentStore.CreateAddressType)">
        <h3 class="w-50"> Address </h3>
        <div class="form w-100">
            <SelectComponent label="Address Type" v-model:selectedOption="dependentStore.Address.Type"
                :options="profileStore.addressTypes" :single-arrow="true" :hasDefaultOption="true" :error="errors.Type" :is-required="true" :readonly="isEditAction"/>
            <TextInput type="text" name="mixed" label="Address" placeholder="Enter Address 1" :max=30
                v-model:modelValue="dependentStore.Address.Street1" :required="true" :error="errors.Street1" :is-required="true" :readonly="isEditAction"/>
            <TextInput type="text" name="mixed" placeholder="Enter City" :label="['US', 'CA', 'MX'].includes(dependentStore.Address.Country?.key) ? 'City' : 'City/State'" :max=25 v-model:modelValue="dependentStore.Address.City"
                :required="true" :error="errors.City" :isRequired="true" :readonly="isEditAction"/>
            <SelectComponent label="State" v-model:selectedOption="dependentStore.Address.State" :options="filteredStates"
                :error="errors?.State" :isRequired="['US', 'CA', 'MX'].includes(dependentStore.Address.Country?.key) && !isEditAction" :readonly="!['US', 'CA', 'MX'].includes(dependentStore.Address.Country?.key) || isEditAction" />
            <SelectComponent label="Country" v-model:selectedOption="dependentStore.Address.Country"
                :options="profileStore.countries" :single-arrow="true" :readonly="isEditAction" />

            <TextInput type="text" name="number" label="Postal Code" placeholder="Enter Postal Code" :max=9
                :error="errors.Zip" v-model:modelValue="dependentStore.Address.Zip" :required="true" :is-required="true" :readonly="isEditAction"/>
        </div>
    </template>

    <!-- Contact Methods -->
    <TextInput type="email" label="Email" placeholder="Enter Email here" :max=50
        v-model:modelValue="dependentStore.Email" :required="true" :readonly="isEditAction" />
    <TextInput type="tel" name="phone" label="Phone no" placeholder="Enter Phone Number here" :max=50
        v-model:modelValue="dependentStore.PhoneNumber" :required="true" :readonly="isEditAction" />

    <div class="action-buttons">
        <a href="" @click.prevent="closeModal" class="btn btn-secondary">Back</a>
        <button type="button" class="btn btn-secondary" @click="handleStep2"> Step 2 </button>
    </div>
</template>

<script lang="ts">
import { Ref, ref, defineComponent, computed, watch, onMounted } from 'vue';
import { useDependentStore } from '@/stores/dependent';
import { useParticipantStore } from '@/stores/participant';
import { useProfileStore } from '@/stores/profile';
import { CountryStates, RadioOption } from '@/interfaces/interfaces';
import { useSSNValidator } from '@/composable/useSSNValidator';
import { useModalStore } from '@/stores/modal';

import TextInput from '@components/form/TextInput.vue';
import CheckBox from '@components/form/CheckBox.vue';
import DateInput from '@components/form/DateInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';

export default defineComponent({
    components: {
        CheckBox,
        DateInput,
        SelectComponent,
        TextInput,
    },
    setup(_, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const initialRequiredFields = ['Relationship', 'FirstName', 'LastName', 'DateofBirth'];
        const initialRequiredAddressFields = ['Type', 'Street1', 'Zip', 'City'];
        const requiredFields = ref([...initialRequiredFields]);
        const requiredAddressFields = ref([...initialRequiredAddressFields]);
        const dependentStore = useDependentStore();
        const profileStore = useProfileStore();
        const isNoSSNVisible = computed(() => dependentStore.Person?.Relationship?.key == '162');
        const isMaritalStatusVisible = computed(() => dependentStore.Person.Relationship?.key == '181');
        const isMarriageDateVisible = computed(() => dependentStore.Person.MaritalStatus?.key == 1 && dependentStore.Person.Relationship?.key == '181');
        const isDivorceDateVisible = computed(() => dependentStore.Person.MaritalStatus?.key == 218 && dependentStore.Person.Relationship?.key == '181');
        const isDateofDeathVisible = computed(() => dependentStore.Person.MaritalStatus?.key == 217 && dependentStore.Person.Relationship?.key == '181');
        const errors: Ref<{ [key: string]: string|null }> = ref({});
        const isValidStep1 = ref(true);
        const isEditAction = computed(() => dependentStore.isEditAction);
        const ssnLabel = ref('SSN');
        const ssnType = ref('ssn');
        const ssnPlaceholder = ref('Enter SSN here');
        const ssnMaxLength = ref(11);
        const options: RadioOption[] = [
            { value: 0, label: 'Use My Primary Addres' },
            { value: 1, label: 'Add New Address' }
        ];

        watch(() => dependentStore.Person.CitizenshipCode, (newValue) => {
            dependentStore.Person.SSN = '';
            ssnType.value = 'number';
            ssnMaxLength.value = 9;

            if (newValue?.key == 1031) {
                ssnLabel.value = 'SSN';
                ssnPlaceholder.value = 'Enter SSN here';
                ssnType.value = 'ssn';
                ssnMaxLength.value = 11;

            } else if (newValue?.key == 623) {
                ssnLabel.value = 'SIN';
                ssnPlaceholder.value = 'Enter SIN here';
            } else {
                ssnLabel.value = 'OtherID';
                ssnPlaceholder.value = 'Enter Other ID here';
            }
        });

        watch(() => dependentStore.Person.Relationship, (newValue) => {
            if (newValue?.key == 162) {
                errors.value.SSN = '';
                dependentStore.Person.NoSSN = false;
            } else {
                if (!requiredFields.value.includes('SSN')) {
                    requiredFields.value.push('SSN');
                }
            }
        });

        watch(() => dependentStore.Address?.Country, (newValue, oldValue) => {
            if(newValue && dependentStore.Address && newValue.key !== oldValue?.key) {
                dependentStore.Address.State = filteredStates.value[0] || null;
            }

            checkStateIsRequired();
        });

        const checkStateIsRequired = () => {
            if(!['US', 'CA', 'MX'].includes(dependentStore.Address?.Country?.key as string)) {
                errors.value.State = null;
                requiredAddressFields.value = [...initialRequiredAddressFields];

                console.log('>>doesnt include', dependentStore.Address?.Country?.key, errors.value, requiredAddressFields.value, requiredFields.value.includes('State'));
            } else if (!requiredFields.value.includes('State')) {
                requiredAddressFields.value.push('State');
            }
        }

        const closeModal = () => {
            useModalStore().openModal('success-modal', null, 'modal-success', null, {
                title: 'Discard Changes?',
                content: {
                    type: "text",
                    value: 'Are you sure you want to discard the changes? This action cannot be undone.',
                    icon: 'iatse-icon-user-verified'
                },
                confirmButtons: ["Cancel", "Confirm"],
                onConfirm: () => emit('close')
            })
        }

        const handleChildSSN = () => {
            if (dependentStore.Person.NoSSN) {
                requiredFields.value = requiredFields.value.filter(field => field !== 'SSN');
                errors.value.SSN = '';
            } else {
                if (!requiredFields.value.includes('SSN')) {
                    requiredFields.value.push('SSN');
                }
            }
        };

        // const handleSetAddress = (status: boolean, remove?: boolean) => {
        //     dependentStore.ShowAddress = true;
        //     dependentStore.Address = status ? JSON.parse(JSON.stringify(dependentStore.ParticipantPrimaryAddress)) : { ...initialAddress };

        //     if (remove) {
        //         dependentStore.ShowAddress = false;
        //         dependentStore.Address = null;
        //         dependentStore.Address = JSON.parse(JSON.stringify(dependentStore.ParticipantPrimaryAddress));
        //     }
        // }

        // SSN validation function
        const validateSSN = (ssn: string) => {
            const { error } = useSSNValidator().formatSSN(ssn);
            return error; // Return the error message if any, otherwise undefined or null
        };

        const handleStep2 = () => {
            isValidStep1.value = true;

            // Reset all errors to false
            for (const field in errors.value) {
                errors.value[field] = null;
            }

            if(!isEditAction.value) {
                checkStateIsRequired();

                requiredFields.value.forEach(field => {
                    const value = dependentStore.Person[field as keyof typeof dependentStore.Person];

                    if (value === 0 || !value || (typeof value === 'string' && value.trim() === '')) {
                        errors.value[field] = `${field.replace(/([a-z])([A-Z])/g, '$1 $2')} is required`;

                        isValidStep1.value = false;
                    }

                    if ((typeof value === 'object' && Number(value?.key) === 0)) {
                        errors.value[field] = `${field.replace(/([a-z])([A-Z])/g, '$1 $2')} is required`;

                        isValidStep1.value = false;
                    }
                });

                if(dependentStore.CreateAddressType) {
                    requiredAddressFields.value.forEach(field => {
                        const value = dependentStore?.Address ? dependentStore?.Address[field as keyof typeof dependentStore.Address] : null;

                        if (!value || (typeof value === 'string' && value.trim() === '') || (typeof value === 'object' && !value.key)) {
                            errors.value[field] = `${field} is required`;
                            isValidStep1.value = false;
                        }
                    });
                }

                // SSN validation
                if (!dependentStore.Person.NoSSN) {
                    const ssnError = validateSSN(dependentStore.Person.SSN ?? '');

                    if (ssnError) {
                        errors.value['SSN'] = 'Invalid Security Number';
                        isValidStep1.value = false;
                    }
                }

                if (isMaritalStatusVisible.value && !dependentStore.Person.MaritalStatus?.key) {
                    errors.value['MaritalStatus'] = 'Marital Status is required.';
                    isValidStep1.value = false;
                }

                if (isMarriageDateVisible.value && !dependentStore.Person.MarriageDate) {
                    errors.value['MarriageDate'] = 'Marriage date is required';
                    isValidStep1.value = false;
                }

                if (isDivorceDateVisible.value && !dependentStore.Person.DivorceDate) {
                    errors.value['DivorceDate'] = 'Divorce date is required';
                    isValidStep1.value = false;
                }

                if (isDateofDeathVisible.value && !dependentStore.Person.DateofDeath) {
                    errors.value['DateofDeath'] = 'Date of Death is required';
                    isValidStep1.value = false;
                }

                if (dependentStore.Person.Relationship?.key == '181' && !dependentStore.Person.SSN) {
                    errors.value['SSN'] = 'SSN is required';
                    isValidStep1.value = false;
                }
            }

            // emit event if is Valid
            if (isValidStep1.value) {
                emit("check-data", isValidStep1.value);
                useParticipantStore().currentStep = 2;
            }
        }

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = dependentStore.Address?.Country?.key;
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        onMounted(() => {
            useParticipantStore().currentStep = 1;
        })

        return {
            errors,
            ssnLabel,
            ssnPlaceholder,
            ssnType,
            ssnMaxLength,
            relationships: dependentStore.relationships,
            profileStore,
            filteredStates,
            isEditAction,
            dependentStore,
            isNoSSNVisible,
            options,
            data: dependentStore.data,
            isMaritalStatusVisible,
            isMarriageDateVisible,
            isDivorceDateVisible,
            isDateofDeathVisible,
            // handleSetAddress,
            handleStep2,
            closeModal,
            handleChildSSN,
        }
    }
})
</script>