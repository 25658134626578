<template>
    <Alerts :success="success" :info="info" :errors="errors?.General" />
    <LoaderComponent v-if="loading" />
    <div v-else-if="formData" class="plan-content">
        <form id="update-address-form" class="form update-address gap-0" method="post">
            <!--Member Details -->
            <h4 class="fs-base w-100 fw-semibold pdb-10">Member ID </h4>
            <TextInput type="text" label="NBF ID " placeholder="Enter Address 1" :max=30
                v-model:modelValue="formData.PersonID" :readonly="true" :error="inputErrors.PersonID"
            />
            <h4 class="fs-base w-100 fw-semibold pdb-10 pdt-20">Member Name </h4>
            <TextInput type="text" label="First Name " placeholder="Enter Address 1" :max=30
                v-model:modelValue="formData.FirstName" :readonly="true" :error="inputErrors.FirstName"
            />
            <TextInput type="text" label="Last Name" placeholder="Enter Address 1" :max=30
                v-model:modelValue="formData.LastName" :readonly="true" :error="inputErrors.LastName"
            />

            <!-- Most Recent Employer/ Production -->
            <h4 class="fs-base w-100 fw-semibold pdb-10 pdt-20">Most Recent Employer/ Production </h4>
            <TextInput type="text" placeholder="Enter Last Production" :max=100
                v-model:modelValue="formData.Employer" :readonly="true" :error="inputErrors.Employer" customClass="h-fit gap-0 pdb-20"
            />
            <TextInput type="text" placeholder="Enter Last Production" :max=100
                v-model:modelValue="formData.Production" :readonly="true" :error="inputErrors.Production" customClass="h-fit gap-0 pdb-20"
            />
            <DateInput label="Date Of Birth" :max=30
                v-model:modelValue="formData.DateofBirth" :error="inputErrors.DateofBirth"
            />
            <SelectComponent label="Gender" v-model:selectedOption="formData.Sex" :options=profileStore.genders
                :singleArrow="true" :error="inputErrors.Gender" />

            <!-- Address Details -->
            <h4 class="fs-base w-100 fw-semibold pdb-10 pdt-30">Addresses </h4>
            <TextInput type="text" label="Address Line 1 " placeholder="Enter Address 1" :max=30
                v-model:modelValue="formData.Street1" :required="true" :is-required="true" :error="errors.Street1"
                customClass="pdb-20" name="mixed" />
            <TextInput type="text" label="Address Line 2" placeholder="Enter Address 2" :max=30
                v-model:modelValue="formData.Street2" :required="true"  customClass="pdb-20" name="mixed"/>
            <TextInput type="text" label="City" placeholder="Enter City" :max=25 v-model:modelValue="formData.City"
                :required="true" :is-required="true" :error="errors.City" customClass="pdb-20" name="mixed"/>

            <SelectComponent label="State/Province" v-model:selectedOption="formData.State" :options="filteredStates"
                :single-arrow="true" :is-required="true" :error="errors.State" customClass="pdb-20" />
            <SelectComponent label="Country" v-model:selectedOption="formData.Country"
                :options="countries" :single-arrow="true" :is-required="true" :error="errors.Country" />

            <TextInput type="text" label="Postal Code" placeholder="Enter Postal Code" :max=9
                v-model:modelValue="formData.ZipCode" name="number" :required="true" :is-required="true" :error="errors.ZipCode"
            />

            <div class="action-buttons pdt-40">
                <button @click.prevent="discardChanges" class="btn btn-secondary"> Discard</button>
                <button @click.prevent="handleSubmit" class="btn btn-primary">
                    {{ loadingSubmit ? 'Saving...' : 'Save' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, Ref, defineComponent, computed, watch, onMounted } from 'vue';
import { CountryStates, SelectOption } from '@/interfaces/interfaces';
import { PersonDemographicInfo } from '@/interfaces/employer';
import { useProfileStore } from '@/stores/profile';
import { useLocalStore } from '@/stores/local';
import axios from 'axios';

import useFormSubmit from '@composable/useFormSubmit';
import Alerts from '@components/utils/AlertsComponent.vue';
import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";

export default defineComponent({
    components: {
        Alerts,
        TextInput,
        DateInput,
        SelectComponent
    },
    props: {
        modalProps: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const profileStore = useProfileStore();
        const localStore = useLocalStore();
        const formData = ref<PersonDemographicInfo | null>(null);
        const initialFormData = ref<PersonDemographicInfo | null>(null);
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const info = ref('');
        const routeString = 'api/user/demographic-information';
        const requiredInputs = {
            Street1: false,
            City: false,
            State: false,
            Country: false,
            ZipCode: false,
        };

        const countries = [{ key: 'US', value: 'United States'}];
        const inputErrors = ref<Record<string, boolean>>({ ...requiredInputs });

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = (formData.value?.Country as SelectOption)?.key ?? '';
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });


        const discardChanges = () => {
            formData.value = JSON.parse(JSON.stringify(initialFormData.value));
            console.log('>>> form', formData.value, initialFormData.value)
        }

        // Handle Form Submit
        const handleSubmit = async () => {
            const route = `${routeString}/${props.modalProps.PersonID}`;
            loadingSubmit.value = true;
            let isValid = true;

            if (isValid) {
                const { handleSubmit } = useFormSubmit({ formData: formData.value, route, success, info, errors, loadingSubmit, generalErrors: ref([]), reset: false });
                const successSubmit = await handleSubmit();

                if (successSubmit) {
                    await localStore.fetchDemographics();
                    emit('close');
                }
            }
        };

        const fetchData = async () => {
            loading.value = true;

            await axios.get(`${routeString}/${props.modalProps.PersonID}`)
                .then(response => {
                    formData.value = response.data[0];
                    initialFormData.value = JSON.parse(JSON.stringify(response.data[0]));
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            if(!profileStore.countries) {
                await profileStore.fetchGeneralData();
                await profileStore.fetchCountriesStates();
            }

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            countries,
            formData,
            profileStore,
            filteredStates,
            inputErrors,
            errors,
            success,
            info,
            loading,
            loadingSubmit,
            handleSubmit,
            discardChanges,
        };
    }
})
</script>