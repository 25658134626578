<template>
    <LoaderComponent v-if="loading" />

    <div v-else class="pdt-30">
        <div class="d-flex justify-content-between align-items-center pdb-20">
            <h2 class="">Alerts $ Notifications</h2>
        </div>
        <form class="bordered-card bordered-card_secondary pdb-10" @submit.prevent>
            <div class="filters d-flex gap-4 flex-wrap flex-md-nowrap justify-content-between w-100">
                <div class="d-flex gap-4 col-md-3 col-sm-12">
                    <SelectComponent v-model:selectedOption="filters.FundType" :options="fundTypes"
                        :single-arrow="true" customClass="fs-xs" />
                </div>
                <TextInput name="mixed" type="text" placeholder="Search by Alert" :max=50 v-model:modelValue="filters.Alert"
                    customClass="search" />
            </div>
            <table class="small striped toggle no-underline">
                <thead class="large">
                    <tr>
                        <th colspan="6">
                            <div class="pdl-10 pdr-10 d-flex justify-content-between w-100">
                                <div class="col-md-1"> Alert ID </div>
                                <div class="col-md-2"> Document Type ID </div>
                                <div class="col-md-1"> Fund Type </div>
                                <div class="col-md-2"> Type</div>
                                <div class="col-md-2"> Message </div>
                                <div class="col-md-1"> Action </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="alertTypes.length > 0">
                    <template v-for="alertType in alertTypes" :key="alertType.AlertTypeID">
                        <tr class="large">
                            <td colspan="6">
                                <div class="d-flex flex-column w-100">
                                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap w-100 sm-gap-20">
                                        <div class="cell col-md-1 col-sm-5">{{ alertType.AlertTypeID }}</div>
                                        <div class="cell col-md-2 col-sm-5 d-flex flex-wrap gap-2">
                                            <small class="label label_small label_green_secondary w-fit"
                                                v-for="doc, index in alertType.Documents"
                                                :class="{ 'label_blue': index % 2 }" :key="doc.CodeID">
                                                <span class="bullet"></span>
                                                {{ doc.Description }}
                                            </small>
                                        </div>

                                        <!-- Fund Type select dropdown -->
                                        <div class="cell col-md-1 col-sm-5">
                                            {{ alertType.FundType != '0' ? alertType.FundType : '' }}
                                        </div>

                                        <div class="cell col-md-2 col-sm-5">{{ alertType.Name }}</div>

                                        <!-- Message -->
                                        <div class="cell col-md-2 col-sm-5">
                                            {{ getTruncatedNotes(alertType.Message) }}
                                        </div>

                                        <!-- Edit button -->
                                        <div class="cell col-md-1 col-sm-5">
                                            <i class="plan-modal iatse-icon-edit fs-6 fw-semibold"
                                                @click="editAlert(alertType)"> </i>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="text-center py-5">
                        <td colspan="6"> No Alert Types found </td>
                    </tr>
                </tbody>
            </table>
        </form>

        <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
    </div>
</template>

<script lang="ts">
import { SelectOption } from '@/interfaces/interfaces';
import { AlertType, AlertFilters } from '@/interfaces/admin';
import { onMounted, ref, reactive, Ref, watch, computed } from 'vue';
import { Paginator } from '@/interfaces/admin';
import { useSuperadminStore } from '@/stores/superadmin';
import { useModalStore } from '@/stores/modal';

import PaginationComponent from '@components/utils/PaginationComponent.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";

export default {
    components: {
        PaginationComponent,
        TextInput,
        SelectComponent,
    },
    setup() {
        const loading = ref(false);
        const loadingSearch = ref(false);
        const superadminStore = useSuperadminStore();
        const fundTypes= computed(() => superadminStore.fundTypes);
        const page = computed(() => superadminStore.page);
        const paginator = computed(() => superadminStore.paginator);
        const alertTypes = computed(() => superadminStore.alertTypes);
        const filters: AlertFilters = reactive({
            FundType: null,
            Alert: '',
        })

        watch(filters, async() => {
            loadingSearch.value = true;
            superadminStore.page = 0;
            await fetchData(false);
            loadingSearch.value = false;
        });

        const getTruncatedNotes = (notes: string) => {
            const maxLength = 30;
            return notes.length > maxLength ? notes.substring(0, maxLength) + '...' : notes;
        };

        const fetchData = async (isLoading: boolean) => {
            if (isLoading) {
                loading.value = true;
            }

            await superadminStore.fetchAlertTypes(filters, isLoading);

            if (isLoading) {
                filters.FundType = fundTypes.value[0] ?? null;
                loading.value = false;
            }
        };

        const fetchPaginationData = (pageNumber: number) => {
            superadminStore.page = pageNumber;
            fetchData(false);
        }

        const editAlert = (alert: AlertType) => {
            const matchedFundType = superadminStore.fundTypes.find(type => type.value == alert.FundType) ?? superadminStore.fundTypes[0];
            superadminStore.alertType = { ...alert, FundType: matchedFundType };

            useModalStore().openModal('edit-alert', 'Edit Alert', 'plan-upload  plan-card-info');
        }

        onMounted(async () => {
            await fetchData(true);
        });

        return {
            alertTypes, fundTypes, paginator, loading, filters, loadingSearch, fetchPaginationData, getTruncatedNotes, editAlert
        }
    }
}
</script>