<template>
  <div class="contributions-table-item">
    <h4> Coverage History</h4>
    <table>
        <thead>
            <tr>
                <th>Coverage Type</th>
                <th>Carrier Code</th>
                <th>Effective Date</th>
                <th>Term/Susp</th>
                <th>Reason</th>
            </tr>
        </thead>
        <tbody v-if="coverageHistory.length > 0">
            <tr v-for="coverage in coverageHistory" :key="coverage.CoverageID">
                <td>{{ coverage.FundName || 'N/A' }}</td>
                <td>{{ coverage.CarrierCode || 'N/A' }}</td>
                <td>{{ coverage.EffectiveDate }}</td>
                <td>{{ coverage.TerminationDate }}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="8">No coverage history found</td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { onMounted, defineComponent, ref, computed } from 'vue';
import axios from 'axios';
import { CoverageHistory } from '@/interfaces/health';

export default defineComponent({
  name: 'CoverageHistory',

  setup() {
    const loading = ref(true);
    const coverageHistory = ref<CoverageHistory[]>([]);

    const fetchData = () => {
        axios.get('api/participant/health-welfare/coverage-history')
            .then(response => {
                coverageHistory.value = response.data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            })
            .finally(() => loading.value = false);
    };

    onMounted(fetchData);

    return {
        loading,
        coverageHistory
    };
  }
});
</script>