import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "info-card" }
const _hoisted_2 = { class: "info-card__icon" }
const _hoisted_3 = { class: "info-card__text" }
const _hoisted_4 = {
  key: 0,
  class: "iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip icon-tooltip-info fs-lg ml-10"
}
const _hoisted_5 = { class: "tooltip-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dashboard-detail-item pdl-0", {'position-relative': _ctx.error }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.iconClass)
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", {
          class: _normalizeClass({'text-danger': _ctx.error })
        }, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.detail), 1),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("i", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.error), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ])
  ], 2))
}