import { defineStore } from "pinia";
import { GrandTotal, YearHistory, ContributionFilters, MemberWorkHistory, YearHistoryItem } from "@/interfaces/local";
import { Paginator } from "@/interfaces/admin";

type ContributionsYears = Record<string, {
    Results: Record<string, YearHistoryItem>;
    YearTotals: Record<string, number>;
}>;

export const useContributionsStore = defineStore({
    id: "contributions",

    state: (): {
        totals: GrandTotal | null;
        filters: ContributionFilters;
        contributions: MemberWorkHistory | null;
        paginator: Paginator;
    } => ({
        totals: null,
        filters: {
            StartDate: '',
            EndDate: '',
            Employers: null,
            Productions: null,
            Funds: null,
            Payors: null,
        },
        contributions: null,
        paginator: { per_page: 0, total: 0, last_page: 0, current_page: 0 },
    }),

    getters: {
        fetchContributions(): MemberWorkHistory | null {
            return this.contributions;
        },

        fetchTotals(): GrandTotal | null {
            return this.totals;
        },

        getPaginator(): Paginator {
            return this.paginator;
        },

        getFilters(): ContributionFilters {
            return this.filters;
        },

        getTotalRows(): number | null {
            return this.paginator.total;
        },
    },

    actions: {
        setContributions(history: MemberWorkHistory | null) {
            this.contributions = history;
        },

        setTotals(grandTotals: GrandTotal | null) {
            this.totals = grandTotals;
        },

        setPaginator(pag: Paginator) {
            this.paginator = pag;
        },

        setFilters(filterData: ContributionFilters) {
            this.filters = filterData;
        },

        toggleDetails(index: string) {
            if (this.contributions) {
                Object.entries(this.contributions).forEach(([year, history]) => {
                    Object.entries(history || {}).forEach(([key, entry]) => {
                        entry.showDetails = key === index ? !entry.showDetails : false;
                    });
                });
            }
        },

        resetData() {
            this.contributions = null;
            this.totals = null;
            this.filters = {
                StartDate: '',
                EndDate: '',
                Employers: null,
                Productions: null,
                Funds: null,
                Payors: null,
            };
            this.paginator = {
                per_page: 0,
                total: 0,
                last_page: 0,
                current_page: 0,
            };
        },
    },
});
