<template>
    <AlertsComponent :error="error" />
    <MessageCard
        message="Note that you are still in Step 1 of the form adding beneficiaries. IATSE NBF will not received your request until you click the “Assign Funds” button and proceed through the remaining steps of this form."
        type="danger" iconClass="iatse-icon-alarm" classes="info_card_full"
    />
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <transition name="slide-toggle">
            <BeneficiaryCard v-if="newBeneficiariesCard.length && newBeneAdded.card && !isEditAction"
                title="Beneficiary" mode="multiple" :data="newBeneficiariesCard" :deleteItem="true" :hasDivider="true"
                customClass="green-card beneficiary-card" @delete="deleteItem" @edit="editItem" />
        </transition>

        <form id="add-beneficiary-form" class="form" method="POST">
            <!-- <div v-if="dependents && !isEditAction" class="plan-general-details full-width">
                <div class="plan-general-detail-container w-100">
                    <div class="plan-general-detail-line plan-general-detail-line_column w-100 justify-content-between px-4">
                        <h5> You can also add your dependents as beneficiaries </h5>
                        <p></p>
                        <button class="btn btn-primary plan-modal"
                            @click.prevent="openModal('create-from-dependents', 'My Dependents')">
                            Choose from existing dependents
                        </button>
                    </div>
                </div>
            </div> -->
            <SelectComponent label="Relationship" v-model:selectedOption="newBene.Relationship" :options="relationships"
                :single-arrow="true"
                :hasDefaultOption=true />
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="newBene.FirstName" :readonly="newBene.SSN" :required="true" :isRequired="true"
                :error="inputErrors?.FirstName" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="newBene.LastName" :readonly="newBene.SSN" :error="inputErrors?.LastName" :isRequired="true"/>
            <SelectComponent label="Suffix" v-model:selectedOption="newBene.Suffix" :options="suffixes"
                :single-arrow="true" :hasDefaultOption="true" :readonly="newBene.SSN" />
            <DateInput label="DOB" v-model:modelValue="newBene.DateofBirth"
                :placeholder="newBene.SSN ? 'dd/mm/yyyy' : ''" input-type="date" :readonly="newBene.SSN"
                :error="inputErrors?.DateofBirth" :isRequired="true" />
            <TextInput type="text" name="mixed" label="Address" placeholder="Enter Address 1" :max=30
                v-model:modelValue="newBene.Address.Street1" :required="true" :readonly="newBene.SSN"/>
            <TextInput type="text" name="mixed"  placeholder="Enter City" :label="['US', 'CA', 'MX'].includes(newBene.Address?.Country?.key) ? 'City' : 'City/State'" :max=25 v-model:modelValue="newBene.Address.City"
                :error="inputErrors?.City" :readonly="newBene.SSN" :required="true"/>
            <SelectComponent label="Country" v-model:selectedOption="newBene.Address.Country"
                :options="profileStore.countries" :single-arrow="true" :readonly="newBene.SSN"/>
            <SelectComponent label="State" v-model:selectedOption="newBene.Address.State" :options="filteredStates"
                :error="inputErrors?.State" :readonly="!['US', 'CA', 'MX'].includes(newBene.Address?.Country?.key) || newBene.SSN" />
            <TextInput type="text" label="Postal Code" name="number" placeholder="Enter Postal Code" :max=9
                v-model:modelValue="newBene.Address.Zip" :required="true" :readonly="newBene.SSN"/>
            <SelectComponent label="Document Type" v-model:selectedOption="selectedDocType"
                :options=documentTypes />
            <div class="text-input hide-mobile"></div>
            <DragDrop customClasses="grey large w-50 sm-w-full" @change="handleFileSelection"
                key="drag-drop-bene" :reset="reset" />

            <!-- Documents Uploaded Table -->
            <div class="w-50 pt-0 align-self-start" v-if="newBene.Documents?.length">
                <p class="mb-4">DOCUMENTS CURRENTLY ON FILE/PENDING ADMIN PREVIEW</p>
                <table class="small mb-4">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(document, index) in newBene.Documents" :key="index">
                            <td>
                                <a href="javascript;"
                                    @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document.DocumentID })">
                                    {{ document?.Type?.Description || document?.DocumentDescription }}
                                </a>
                            </td>
                            <td>{{ document.Date }}</td>
                            <td :class="{ 'text-danger': document.Status === 'Pending' }">
                                {{ document.Status }}
                            </td>
                            <td v-if="document.Status === 'Pending'">
                                <i class="iatse-icon-trash plan-modal"
                                    @click="handleDeleteDoc(document.DocumentID)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <transition name="slide-toggle">
                <div class="action-buttons" v-if="newBeneAdded.actions">
                    <button type="button" href="" class="btn btn-secondary" @click.prevent="!isEditAction && resetModal()" :disabled="loadingUpload" tabindex="-1">
                        Discard
                    </button>
                    <button type="button" class="btn full-width"
                        :class="{ 'btn-primary': isEditAction, 'btn-secondary': !isEditAction }"
                        @click.prevent="handleSubmit" :disabled="loadingUpload">
                        {{ saveButtonText }}
                    </button>
                    <button v-if="!isEditAction" class="btn btn-primary plan-modal" @click.prevent="handleAssign"
                        :disabled="loadingUpload" @keydown.enter="handleAssign">
                        Save and Assign Funds
                    </button>
                </div>
            </transition>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, reactive, computed, defineComponent, watch } from 'vue';
import { useProfileStore } from '@/stores/profile';
import { useModalStore } from '@/stores/modal';
import { Beneficiary, NewBeneficiary } from "@/interfaces/beneficiary";
import { SelectOption, CountryStates } from "@/interfaces/interfaces";
import { useDateFormatter } from '@/utils/dateFormatter';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { useParticipantStore } from '@/stores/participant';

import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import DragDrop from '@components/form/DragDrop.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import BeneficiaryCard from '@/components/cards/BeneficiaryCard.vue';
import axios from "axios";

export default defineComponent({
    components: {
        BeneficiaryCard,
        DateInput,
        DragDrop,
        SelectComponent,
        TextInput,
    },
    setup(_, { emit }) {
        const profileStore = useProfileStore();
        const modalStore = useModalStore();
        const beneStore = useBeneficiaryStore();
        const loading = ref(true);
        const loadingUpload = ref(false);
        const error: Ref<string | null> = ref(null);
        const success = ref('');
        const isEditAction = computed(() => beneStore.isEditAction || modalStore.currentModal?.title === 'Edit Beneficiary');
        const relationships = computed(() => profileStore.relationships);
        const suffixes = computed(() => profileStore.nameSuffixes);
        const documentTypes: Ref<SelectOption[] | null> = ref(beneStore.documentTypes);
        const selectedDocType: Ref<SelectOption> = ref({ key: 0, value: '-Select Document Type-' });
        const reset = ref(false);
        const newBene = computed(() => beneStore.newBeneficiary);
        const newBeneficiaries = computed(() => beneStore.newBeneficiaries);
        const newBeneficiariesCard = ref(beneStore.newBeneficiariesCard);
        const openModal = modalStore.openModal;

        const initialRequiredInputs = {
            FirstName: null,
            LastName: null,
            DateofBirth: null,
        };

        const inputErrors = ref<Record<string, string|null>>({ ...initialRequiredInputs });
        const formData = new FormData();

        const newBeneAdded = reactive({
            actions: true,
            card: beneStore.newBeneficiariesCard.length > 0
        });

        const saveButtonText = computed(() => {
            if (loadingUpload.value) {
                return 'Loading...'
            } else if (isEditAction.value) {
                return 'Save Beneficiary '
            }

            return 'Save and Add Another Beneficiary';
        })

        const handleAssign = () => {
            beneStore.isAddAction = true;

            if (!isEditAction.value && validateFields()) {
                handleSubmit();

                if (newBeneficiaries.value?.length > 1) {
                    openModal('success-modal', null, 'modal-success', null, beneStore.getModalProps);
                } else if (newBeneficiaries.value?.length) {
                    openModal(
                        "assign-fund",
                        "Assign Fund To Beneficiary",
                        "plan-upload"
                    );
                } else {
                    error.value = 'You must add at least one beneficiary to proceed to the Assign Fund page. Otherwise, go to the main page and click on Edit Assignments.'

                    setTimeout(()=> error.value = '', 3000)
                }
            }
        }

        watch(beneStore.newBeneficiariesCard, (newCards) => {
            newBeneficiariesCard.value = newCards;
            handleNewBeneAddedState();
        });

        watch(() => beneStore.newBeneficiary, (newVal) => {
            if (beneStore.dependentEdit) {
                beneStore.newBeneficiary = newVal;
                beneStore.dependentEdit = false;
            }
        }, { deep: true });

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const states = profileStore.statesByCountry;
            const selectedCountry = newBene.value?.Address?.Country?.key ?? '';
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        watch(filteredStates, () => {
            if (newBene.value.Address?.State) {
                newBene.value.Address.State = null;
            }
        });

        const handleSubmit = async () => {
            if (!isEditAction.value && validateFields()) {
                handleNewBeneAddedState();

                if (beneStore.editNewBene) {
                    const beneInEditMode = beneStore.newBeneficiaries.find(ben => ben.InEditMode);
                    const index = beneStore.newBeneficiaries.findIndex(
                        (bene) => bene === beneInEditMode
                    );

                    console.log(beneInEditMode, index, '>>bene EDIT MODE');

                    if (beneInEditMode) {
                        Object.assign(beneInEditMode, {
                            ...newBene.value, InEditMode: false  // Update with all the newBene properties
                        });

                        beneStore.newBeneficiariesCard[index] = {
                            Name: newBene.value.FirstName + ' ' + newBene.value.LastName,
                            DOB: newBene.value.DateofBirth ? useDateFormatter(newBene.value.DateofBirth) : '-',
                            Relation: newBene.value.Relationship?.value ?? '-',
                            Address: beneStore.getAddress(newBene.value) ?? '-',
                        };
                    }
                    else {
                        beneStore.newBeneficiaries.push(JSON.parse(JSON.stringify(newBene.value)));
                        beneStore.newBeneficiariesCard.push({
                            Name: newBene.value.Name ?? '',
                            DOB: newBene.value.DateofBirth ? useDateFormatter(newBene.value.DateofBirth) : '-',
                            Relation: newBene.value.Relationship?.value ?? '-',
                            Address: beneStore.getAddress(newBene.value) ?? '-',
                        })
                    }

                    beneStore.editNewBene = false;
                } else {
                    beneStore.newBeneficiaries.push(JSON.parse(JSON.stringify(newBene.value)));
                    beneStore.newBeneficiariesCard.push({
                        Name: newBene.value.FirstName + ' ' + newBene.value.LastName,
                        DOB: newBene.value.DateofBirth ? useDateFormatter(newBene.value.DateofBirth) : '-',
                        Relation: newBene.value.Relationship?.value ?? '-',
                        Address: beneStore.getAddress(newBene.value) ?? '-',
                    })
                }

                beneStore.resetNewBene();
                inputErrors.value = { ...initialRequiredInputs }
                reset.value = true;
                selectedDocType.value = { key: 0, value: '-Select Document Type-' };
                return true;
            }
            else if (isEditAction.value) {
                loadingUpload.value = true;
                inputErrors.value = await beneStore.handleEditBene(newBene.value);
                console.log(inputErrors.value, '>>errors');
                loadingUpload.value = false;
                return true;
            }

            return false;
        };

        const deleteItem = (index: number) => {
            handleNewBeneAddedState();
            beneStore.newBeneficiaries.splice(index, 1);
            beneStore.newBeneficiariesCard.splice(index, 1);
            beneStore.reloadAssignments();
        }

        const editItem = (index: number) => {
            beneStore.editDependent(index);
            beneStore.dependentEdit = true;
        }

        const handleDeleteDoc = async (docId: number | string) => {
            try {
                const response = await axios.delete(`/api/smartsearch/beneficiary-file/${docId}`);
                if (response.status === 200) {
                    newBene.value.Documents = newBene.value.Documents?.filter((doc: any) => doc.DocumentID !== docId);
                } else {
                    console.error('Failed to delete the document');
                }
            } catch (error) {
                console.error('An error occurred while deleting the document:', error);
            }
        }

        const handleNewBeneAddedState = () => {
            newBeneAdded.actions = false;
            newBeneAdded.card = false;

            setTimeout(() => {
                newBeneAdded.actions = true;
                newBeneAdded.card = true;
            }, 300);
        }

        // Validate Fields
        const validateFields = () => {
            let isValid = true;
            inputErrors.value = { ...initialRequiredInputs }

            Object.keys(inputErrors.value).forEach((key) => {
                const value = newBene.value[key as keyof NewBeneficiary];

                if (!value) {
                    isValid = false;
                    inputErrors.value[key] = `${key.replace(/([a-z])([A-Z])/g, '$1 $2')} is required`;
                } else if (key === 'DateofBirth') {
                    const date = new Date(value as string);
                    console.log('date', date);

                    if (isNaN(date.getTime())) {
                        isValid = false;
                        inputErrors.value[key] = `${key.replace(/([a-z])([A-Z])/g, '$1 $2')} must be a valid date`;
                    } else if (date.getFullYear() < 1900 || date > new Date()) {
                        isValid = false;
                        inputErrors.value[key] = `${key.replace(/([a-z])([A-Z])/g, '$1 $2')} must be between 1900 and today`;
                    } else {
                        inputErrors.value[key] = null;
                    }
                } else {
                    inputErrors.value[key] = null;
                }
            });

            return isValid;
        };

        const resetModal = () => {
            // beneStore.isAddAction = false;
            // beneStore.isEditAction = false;
            beneStore.resetNewBene();
        }

        const fetchData = async () => {
            if (!profileStore.generalData) {
                loading.value = true;
                await profileStore.fetchGeneralData();
            }

            if (!profileStore.countries || !profileStore.statesByCountry) {
                loading.value = true;
                await profileStore.fetchCountriesStates();
            }

            if (beneStore.isAddAction) {
                beneStore.resetNewBene();
            }

            loading.value = false;
        };

        const handleFileSelection = async (event: Event) => {
            loadingUpload.value = true;
            reset.value = false;

            if (!selectedDocType.value.key) {
                error.value = 'The selected document type is invalid.';
                loadingUpload.value = false;
                reset.value = true;

                setTimeout(() => {
                    error.value = '';
                }, 3000);

                return;
            }

            formData.append('DocumentType', String(selectedDocType.value.key));
            formData.append('BeneficiaryID', String(newBene.value.BeneficiaryID));

            const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;

            if (files) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('Files[]', files[i]);
                }

                await beneStore.uploadFiles(formData);
                reset.value = true;
            }

            loadingUpload.value = false;
        };

        onMounted(async () => {
            useParticipantStore().currentStep = 1;
            await fetchData();
        });

        return {
            profileStore,
            reset,
            filteredStates,
            documentTypes,
            selectedDocType,
            beneStore,
            relationships,
            dependents: beneStore.dependents,
            suffixes,
            loading,
            loadingUpload,
            newBene,
            success,
            error,
            isEditAction,
            newBeneficiariesCard,
            inputErrors,
            newBeneAdded,
            saveButtonText,
            handleDeleteDoc,
            handleFileSelection,
            handleSubmit,
            handleAssign,
            resetModal,
            deleteItem,
            editItem,
            openModal,
        };
    }
})
</script>