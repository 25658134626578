import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_3 = { class: "input-date-wrapper" }
const _hoisted_4 = ["type", "id", "placeholder", "maxlength", "max", "min", "value", "readonly"]
const _hoisted_5 = { class: "d-flex gap-10" }
const _hoisted_6 = {
  key: 0,
  class: "text-secondary-red sm-fs-xxs"
}
const _hoisted_7 = {
  key: 1,
  class: "text-secondary-red sm-fs-xxs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['text-input', { 'error': _ctx.localError || _ctx.error?.length || _ctx.hasChanges }, _ctx.customClass])
  }, [
    _createElementVNode("label", {
      class: "body-text",
      for: _ctx.label
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.isRequired)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " * "))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        ref: "inputRef",
        type: _ctx.currentType,
        id: _ctx.label,
        placeholder: _ctx.placeholder,
        class: _normalizeClass(['links', 'date-input', { 'has-icon': _ctx.icon }]),
        maxlength: _ctx.maxlength,
        max: _ctx.maxDate,
        min: _ctx.minDate,
        value: _ctx.inputValue,
        readonly: _ctx.readonly,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
      }, null, 42, _hoisted_4),
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "custom-date-icon iatse-icon-calendar",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", _hoisted_5, [
      (_ctx.error && _ctx.error?.length)
        ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(Array.isArray(_ctx.error) ? _ctx.error.join('. ') : _ctx.error), 1))
        : _createCommentVNode("", true),
      (_ctx.localError && !_ctx.error?.length)
        ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.localError), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}