import { AlertType, AlertFilters, ChangeRequestsFilters } from "@/interfaces/admin";
import { Paginator, SelectOption } from "@/interfaces/interfaces";
import { defineStore } from "pinia";
import { useOptionsFormatter } from "@/utils/optionFormatter";
import axios from "axios";

export const useSuperadminStore = defineStore("superadmin", {
    state: () => ({
        alertTypes: [] as AlertType[],
        alertType: null as AlertType | null,
        fundTypes: [] as SelectOption[],
        reviewReq: null as number | null,
        autoresponder: null as number | null,
        paginator: {
            per_page: 15,
            total: 300,
            last_page: 300,
            current_page: 1,
        } as Paginator,
        page: 1,
        counts: {
            infoChange: 0,
            submissionsData: 0,
            claimSubmissions: 0
        },
        requestsPage: {
            requests: null as AlertType | null,
            statuses: [] as SelectOption[],
            requestTypes: null,
            filters: null as ChangeRequestsFilters | null,
            paginator: {
                per_page: 15,
                total: 300,
                last_page: 300,
                current_page: 1,
            } as Paginator,
            page: 1,
        }
    }),
    actions: {
        async fetchAlertTypes(filters?: AlertFilters, isLoading?: boolean) {
            await axios
                .get("api/administrator/alerts", {
                    params: { page: this.page, Filters: filters },
                })
                .then((response) => {
                    this.alertTypes = response.data.AlertTypes;

                    if (isLoading) {
                        useSuperadminStore().fundTypes = useOptionsFormatter(
                            response.data.FundTypes
                        );
                    }

                    this.paginator = response.data.Paginator;
                    this.page = this.paginator.current_page;
                });
        },

        async fetchRequests() {
            await axios
                .get("api/administrator/change-requests", {
                    params: { Filters: this.requestsPage.filters, page: this.requestsPage.page, Export: 0 }
                })
                .then((response) => {
                    this.requestsPage.paginator = response.data.Paginator;
                    this.requestsPage.page = this.requestsPage.paginator.current_page;
                    this.requestsPage.requests = response.data.Requests;
                    this.requestsPage.statuses = response.data.Statuses ? useOptionsFormatter(response.data.Statuses) : [];
                    this.requestsPage.requestTypes = response.data.RequestTypes;
                });
        },

        reset() {
            this.$reset();
        },
    },
});
