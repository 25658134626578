<template>
    <EmployerAuth v-if="employerType === 'auth'" :states="states" :loadingSubmit="loadingSubmit"
        :errors="errors"  :success="success"/>
    <EmployerRegistration v-else-if="employerType === 'register'" :loadingSubmit="loadingSubmit"
        :securityQuestions="securityQuestions" :employerRequest="employerRequest" :errors="errors" :success="success" />
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { SecurityQuestion, SelectOption } from '@/interfaces/interfaces';
import { PendingEmployer } from '@/interfaces/admin';
import EmployerAuth from './EmployerAuth.vue';
import EmployerRegistration from './EmployerRegistration.vue';

export default defineComponent({
    components: {
        EmployerAuth,
        EmployerRegistration,
    },
    props: {
        securityQuestions: {
            type: Array as PropType<SecurityQuestion[]>,
            required: true,
        },
        states: Array as PropType<SelectOption[]>,
        employerRequest: Object as PropType<PendingEmployer | null>,
        employerType: {
            type: String as PropType<string | null>,
            required: true,
        },
        errors: Array as PropType<string[]>,
        loadingSubmit: Boolean,
        success: Boolean,
    },
});
</script>
