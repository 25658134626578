<template>
    <Alerts :success="success" :info="info" :errors="errors" @reset="resetAlerts"/>
    <MessageCard
        message="Enter the information you wish to update in the applicable field. These changes automatically update your account."
        type="danger" iconClass="iatse-icon-alarm" classes="info_card_full" />
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <form id="update-contact-information-form" class="form update-contact-information gap-4" method="post">
            <!-- Phone Numbers Section -->
            <div class="w-100 d-flex flex-column gap-10">
                <h4 class="w-100"> Phone Number </h4>
                <div v-for="(phone, key) in profileStore.phones" :key="key"
                    class="inputs-wrapper flex-nowrap align-items-center justify-content-start">
                    <div class="d-flex justify-content-between gap-10 col-md-6 col-sm-8">
                        <SelectComponent v-model:selectedOption="phone.TypeCode" :options="profileStore.phoneTypes"
                            :single-arrow="true" customClass="gap-0 col-md-4" />
                        <TextInput type="text" name="phone" placeholder="333-333-3333" :max="14"
                            v-model:modelValue="phone.ContactInfo" :required="true"
                            customClass="gap-0 flex-grow-1 col-md-4" />
                    </div>
                    <div class="radio-group w-auto">
                        <div class="radio-buttons">
                            <div class="radio-button">
                                <input type="radio" :id="`PrimaryPhone${key + 1}`" name="Primary"
                                    v-model="phone.Primary" @change="handlePrimaryChange(phone.CMIID)"
                                    :checked="phone.Primary" />
                                <label class="body-text" :for="`PrimaryPhone${key + 1}`">
                                    Primary
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-delete">
                        <i class="iatse-icon-trash icon_delete plan-modal fs-4" @click="handleDeleteAction('Phone', phone.CMIID)"></i>
                    </div>
                </div>
            </div>
            <div class="w-full" v-if="!newPhoneAdded">
                <a href="" class="btn btn-add" @click.prevent="handleAddPhone">
                    <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p> Add New Phone Number
                </a>
            </div>

            <!-- Web Emails Section -->
            <div class="w-100 d-flex flex-column" v-if="profileStore.userEmail">
                <h4 class="w-100"> Account E-mail Address </h4>
                <small class="text-underline fs-xs mt-10 plan-modal" @click="openModal('account-settings', 'Account Settings', 'plan-card-info plan-tables')" > Account email can be changed in the Account Settings
                    page.</small>
                <div class="inputs-wrapper">
                    <TextInput type="email" placeholder="Enter Web Email Address" :max="60"
                        v-model:modelValue="profileStore.userEmail" :required="true" :readonly="true" customClass="disabled"/>
                </div>
            </div>

            <div class="w-100 d-flex flex-column gap-10" v-if="profileStore.emails.length">
                <h4 class="w-100"> Alternate Web Email Address</h4>
                <template v-for="(email, key) in profileStore.emails" :key="key">
                    <div class="inputs-wrapper flex-nowrap align-items-center justify-content-start">
                        <TextInput type="email" placeholder="Enter Web Email Address" :max="60"
                            v-model:modelValue="email.ContactInfo" :required="true" />
                        <div class="radio-group w-auto">
                            <div class="radio-buttons">
                                <div class="radio-button">
                                    <input type="radio" :id="`PrimaryEmail${key + 1}`" name="PrimaryEmail"
                                        v-model="email.Primary" :checked="email.Primary"  @change="handleEmailPrimaryChange(email.CMIID)"/>
                                    <label class="body-text" :for="`PrimaryEmail${key + 1}`">
                                        Primary
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="btn-delete">
                            <i class="iatse-icon-trash icon_delete plan-modal fs-4"
                                @click="handleDeleteAction('Email', email.CMIID)"></i>
                        </div>
                    </div>
                </template>
            </div>

            <div class="w-full pdt-10">
                <a href="" class="btn btn-add" @click.prevent="profileStore.addEmailAddress()">
                    <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p>
                    {{ 'Alternate Email Address' }}
                </a>
            </div>

            <div class="action-buttons">
                <button @click.prevent="resetModal" class="btn btn-secondary"> Discard</button>
                <button @click.prevent="submitData" class="btn btn-primary">
                    {{ loadingSubmit ? 'Saving...' : 'Save Changes' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, reactive, Ref, defineComponent, computed, onMounted } from 'vue';
import { Contact } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import { useModalStore } from '@/stores/modal';
import useFormSubmit from '@composable/useFormSubmit';
import Alerts from '@components/utils/AlertsComponent.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";

type UpdateState = {
    phone: boolean;
    email: boolean;
};

type UpdateType = keyof UpdateState;

export default defineComponent({
    components: {
        Alerts,
        MessageCard,
        TextInput,
        SelectComponent,
    },
    setup(_, { emit }) {
        const profileStore = useProfileStore();
        const newPhoneAdded = ref(false);
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const info = ref('');
        const route = 'api/user/profile/my-contact-information';
        const initialEmails = ref([]);
        const initialPhones = ref([]);

        const update = ref<UpdateState>({
            phone: false,
            email: false,
        })

        const loadingUpdate = ref<UpdateState>({
            phone: false,
            email: false,
        })

        const resetAlerts = () => {
            errors.value = [];
            success.value = '';
            info.value = '';
        }

        const closeModal = async () => {
            emit('close');
        }

        const resetModal = () => {
            profileStore.emails =  JSON.parse(JSON.stringify(initialEmails.value));
            profileStore.phones =  JSON.parse(JSON.stringify(initialPhones.value));
            console.log('<<emails', profileStore.emails, initialEmails.value)
            console.log('<<phones', profileStore.phones, initialPhones.value)
        }

        const handleAddPhone = () => {
            newPhoneAdded.value = true;
            profileStore.addPhoneNumber();
        }

        const updateContact = (type: UpdateType) => {
            update.value[type] = !update.value[type];
        }

        const handlePrimaryChange = (id: string | number) => {
            profileStore.phones.forEach((phone) => {
                phone.Primary = phone.CMIID === id;
            });
        };

        const handleEmailPrimaryChange = (id: string | number) => {
            profileStore.emails.forEach((email) => {
                email.Primary = email.CMIID === id;
            });
        };

        // Handle Delete Phone
        const deletePhone = async (phoneId: string) => {
            if (phoneId) {
                await profileStore.deletePhoneNumber(phoneId);
                success.value = 'Phone sucessfully deleted';
            } else {
                profileStore.phones.pop();
                newPhoneAdded.value = false;
            }
        }

        // Handle Delete Email
        const deleteEmailAddress = async (id: string) => {
            if (profileStore.emails) {
                if (typeof id === 'string' && id.startsWith('new-')) {
                    profileStore.emails = profileStore.emails.filter(email => email.CMIID !== id);
                } else {
                    await profileStore.deleteEmail(id);
                    success.value = 'Email sucessfully deleted';
                }
            }
        }

        const handleDeleteAction = (type: string, id: string) => {
            const modalProps = {
                title: "Delete " + type,
                content: {
                    type: "text",
                    value: 'Are you sure you want to delete this ' + type.toLowerCase() + '?',
                    icon: "iatse-icon-user-verified",
                },
                confirmButtons: ["Cancel", "Confirm"],
                onConfirm: () => {
                    if(type === 'Phone') {
                        deletePhone(id);
                    } else if(type === 'Email') {
                        deleteEmailAddress(id);
                    }
                },
            }

            useModalStore().openModal('success-modal', null, 'modal-success', null, modalProps);
        }

        // Handle Form Submit
        const submitData = async () => {
            const formData = {
                Emails: profileStore.emails,
                Phones: profileStore.phones,
            }

            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                await profileStore.fetchContactData();
            }
        }

        onMounted(async () => {
            loading.value = true;
            await profileStore.fetchContactData();
            initialEmails.value = JSON.parse(JSON.stringify(profileStore.emails));
            initialPhones.value = JSON.parse(JSON.stringify(profileStore.phones));
            loading.value = false;
        });

        return {
            profileStore,
            update,
            errors,
            success,
            info,
            loading,
            loadingSubmit,
            loadingUpdate,
            newPhoneAdded,
            handleAddPhone,
            handlePrimaryChange,
            handleEmailPrimaryChange,
            updateContact,
            deletePhone,
            deleteEmailAddress,
            handleDeleteAction,
            submitData,
            closeModal,
            resetAlerts,
            resetModal,
            openModal: useModalStore().openModal
        };
    }
})
</script>