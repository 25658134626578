<template>
    <div class="range-slider">
        <label v-if="label" class="main-label">{{ label }}</label>

        <div v-for="slider in sliders" :key="slider.id" class="range-slider-item">
            <div class="range-slider-input">
                <div class="range-slider-input_wrapper">
                    <label class="label">{{ slider.label || 'Slider' }}</label>
                    <RadioButton :options="beneTypes" :name="`${title}-${slider.label}-Type`" v-model="slider.level"
                        :id="`${title}-${slider.label}-Type`" />
                </div>
                <input type="range" class="slider" v-model="values[slider.entityId]" :min="slider.min || 0"
                    :max="slider.max || 100" :step="slider.step || 1" :id="slider.id"
                    :style="{ '--value': sliderValuesAsPercentage[slider.entityId] + '%' }"
                    :disabled="!isEditable || !slider.editable" @input="updateValue(slider.entityId, slider.id)" />
            </div>

            <!-- Editable Input Field -->
            <input type="number" class="slider-value" v-model="values[slider.entityId]"
                :min="slider.min || 0" :max="100" step=".01"
                :disabled="!isEditable || !slider.editable" @input="updateValue(slider.entityId, slider.id)" />
            <!-- <span
                class="slider-value">{{ sliderValuesAsPercentage[slider.entityId] > 100 ? 100.00 : sliderValuesAsPercentage[slider.entityId] }}%</span> -->
            <a v-if="deleteButton" href="" class="btn btn-secondary"
                @click.prevent="deleteSlider(slider.entityId, slider.id)">Delete</a>
        </div>
    </div>
</template>

<script lang="ts">
import { RadioOption, SliderConfig, Allocations } from '@/interfaces/interfaces';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { defineComponent, ref, computed, PropType, onMounted, watch } from 'vue';

export default defineComponent({
    props: {
        sliders: {
            type: [] as PropType<SliderConfig[]>,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        deleteButton: {
            type: Boolean,
            default: false,
        },
        isEditable: {
            type: Boolean,
            default: true
        },
    },
    setup(props, { emit }) {
        const beneStore = useBeneficiaryStore();
        const values = ref<Record<string, number>>({});
        const initialValues = ref<Record<string, number>>({});
        const beneTypes: RadioOption[] = [
            { label: 'Primary', value: 'Primary' },
            { label: 'Secondary', value: 'Secondary' },
            { label: 'Tertiary', value: 'Tertiary' },
        ];

        const sliderValuesAsPercentage = computed(() => {
            const percentages: Record<string, number> = {};
            const allocations: Record<string, Allocations> = {};

            if (props.sliders) {
                Object.values(props.sliders).forEach(slider => {
                    const value = values.value[slider.entityId];
                    const percentage = ((value - (Number(slider?.min) || 0)) / ((Number(slider?.max) || 100) - (Number(slider?.min) || 0))) * 100;

                    percentages[slider.entityId] = parseFloat(percentage.toFixed(2)),  // Formatting to 2 decimal places

                        allocations[slider.entityId] = {
                            Percentage: parseFloat(percentage.toFixed(2)),  // Formatting to 2 decimal places
                            GroupLevel: slider.level,
                            Type: slider.entityType ?? null,
                        }
                });
            }

            emit('update', { type: 'percentages', value: allocations });

            return percentages;
        });

        const updateValue = (entityId: string, id: string) => {
            let value = values.value[entityId];
            if (value > 100) {
                values.value[entityId] = 100;  // Clamp value to 100
                return;
            }

            const slider = document.getElementById(id) as HTMLInputElement;
            const percentage = ((value - (Number(slider?.min) || 0)) / ((Number(slider?.max) || 100) - (Number(slider?.min) || 0))) * 100;
            const clampedPercentage = Math.min(percentage, 100).toFixed(2);
            values.value[entityId] = parseFloat(Number(value).toFixed(2))

            slider.style.setProperty('--value', clampedPercentage + '%');

            emit('edit');
        };

        const deleteSlider = (entityId: string, id: string) => {
            values.value[entityId] = 0;

            const slider = document.getElementById(id) as HTMLInputElement;
            slider.style.setProperty('--value', 0 + '%');
            slider.disabled = true;
            emit('edit');
        }

        watch(() => beneStore.discard, (newValue) => {
            console.log('Discard changed:', newValue);
        });

        // Initialize slider values
        onMounted(() => {
            Object.values(props.sliders).forEach(slider => {
                values.value[slider.entityId] = slider.value;
                initialValues.value[slider.entityId] = slider.value;
            });
        })

        return {
            values,
            beneTypes,
            sliderValuesAsPercentage,
            updateValue,
            deleteSlider,
        };
    },
});
</script>