<template>
  <div class="pdt-30">
    <div class="d-flex justify-content-between align-items-center pdb-20">
      <h2>Participants</h2>
    </div>
    <SearchParticipantsFilters :filters="filters" :searching="loading" @update-filters="updateFilters" />
    <LoaderComponent v-if="loading" />
    <template v-else>
      <SearchParticipantsList :persons="persons" />
      <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
    </template>
  </div>
</template>

<script lang="ts">
import { reactive, ref, onMounted, watch } from 'vue';
import { SearchPerson, SearchParticipantsFormFilters } from '@/interfaces/admin';
import { Paginator } from '@/interfaces/interfaces';
import axios from 'axios';
import SearchParticipantsList from '@/components/superadmin/SearchParticipantsList.vue';
import SearchParticipantsFilters from '@/components/superadmin/SearchParticipantsFilters.vue';
import PaginationComponent from '@components/utils/PaginationComponent.vue';

export default {
  components: {
    SearchParticipantsList,
    SearchParticipantsFilters,
    PaginationComponent
  },

  setup() {
    const loading = ref(true);
    const isLoading = ref(true);
    const searching = ref(false);
    const page = ref(0);
    const persons = ref<SearchPerson[]>([]);
    const filters = reactive<SearchParticipantsFormFilters>({
      PersonID: null,
      FirstName: '',
      LastName: '',
    });

    const initialFilters = {
      PersonID: '',
      FirstName: '',
      LastName: '',
      Email: '',
      BirthDate: '',
      LastLogin: '',
    }

    const paginator: Paginator = reactive({
      per_page: 0,
      total: 0,
      last_page: 0,
      current_page: 0,
    });

    const fetchData = async () => {
      if (isLoading.value)
        loading.value = true;

      try {
        const response = await axios.get('api/administrator/users/participants', { params: { ...filters, page: page.value } });
        persons.value = response.data.Participants;
        Object.assign(paginator, response.data.Paginator);
      } catch (error) {
        console.error('Error:', error);
      }

      loading.value = false;
    };

    // Fetch Data For Selected Page
    const fetchPaginationData = (pageNumber: number) => {
      page.value = pageNumber;
      isLoading.value = false;
      fetchData();
    };

    const updateFilters = (newFilters: SearchParticipantsFormFilters, loading: boolean) => {
      page.value = 1;
      isLoading.value = loading;
      Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
      Object.assign(filters, newFilters);
    };

    watch(filters, async () => {
      searching.value = true;
      await fetchData();
      searching.value = false;
    }, { deep: true });

    onMounted(async () => await fetchData());

    return {
      filters,
      paginator,
      loading,
      persons,
      fetchPaginationData,
      updateFilters
    };
  },
};
</script>