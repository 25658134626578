import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "d-flex justify-start flex-column w-100 gap-20" }
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhoneField = _resolveComponent("PhoneField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.errors && _ctx.errors.General)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.errors?.General), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_PhoneField, {
        type: "tel",
        label: "Add Phone Number",
        placeholder: "x (xxx) xxx-xxxx",
        max: 17,
        modelValue: _ctx.phone,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event))
      }, null, 8, ["modelValue"]),
      _createElementVNode("a", {
        href: "",
        class: "btn btn-primary w-100 mt-20",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, "Update")
    ])
  ]))
}