export function useNumberFormat() {
    function formatNumber(value: string | number): string {
        const numValue = typeof value === 'string' ? parseFloat(value) : value;

        if (isNaN(numValue)) {
            return '-';
        }

        return numValue?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return {
        formatNumber,
    };
}
