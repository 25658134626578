<template>
    <FundPanel cardId="card-vacation">
        <template #icon>
            <i class="iatse-icon-fund-vacation"></i>
        </template>
        <template #title>
            Vacation
        </template>
        <template #fund-cover>
            <div class="col-md-9">
                <div class="fund-cover fund-cover__vacation d-flex align-items-start flex-column">
                    <div class="fund-cover-header">
                        <div class="coverage-column">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-receipt"></i>
                                <p class="links"><strong>Contributions:</strong></p>
                            </div>
                            <p>
                                <strong>{{ vacationContributions?.Total }}</strong>
                                <small> ({{ vacationContributions?.Label + vacationContributions?.Date }})</small>
                            </p>
                        </div>
                        <div class="divider"> </div>
                        <div class="coverage-column" :class="{'position-relative': vacationContributions?.Error }">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-map"></i>
                                <p class="links text-underline plan-modal" @click="openModal('check-destination', 'Payment Destination Setup', 'plan-form', 'vacation')">Vacation
                                    Check Destination</p>
                            </div>
                            <p :class="{'text-danger': vacationContributions?.Error }">
                                <strong>{{ vacationContributions?.CheckDestination }}</strong>
                                <i v-if="vacationContributions?.Error" class="iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip fs-lg ml-10">
                                    <span class="tooltip-text">{{ vacationContributions?.Error }}</span>
                                </i>
                            </p>
                        </div>
                    </div>
                    <div class="fund-cover-buttons">
                        <button class="btn btn-primary plan-modal"
                            @click="openModal('work-history', 'Vacation Work History', '', 'vacation')">Work History</button>
                            <!-- @click="openModal('work-history', 'Vacation Work History', '', 'vacation', null, null, undefined, { Funds: [6] })">Work History</button> -->
                        <button class="btn btn-secondary plan-modal"
                            @click="openModal('vacation', 'Vacation', 'plan-tables')">Payment History</button>
                    </div>
                </div>
            </div>
        </template>
        <template #related-links>
            <button v-if="vacationContributions?.IsAvailableForCheck" class="btn btn-tertiary btn-large plan-modal"
                @click="openModal('check-replacement', 'Check Replacement', 'plan-form', 'vacation')"> Check
                Replacement
            </button>
            <button class="btn btn-tertiary btn-large plan-modal"
                @click="openModal('check-destination', 'Payment Destination Setup', 'plan-form', 'vacation')">
                ACH/Check
            </button>
            <a href="" class=" btn btn-tertiary btn-large plan-modal" @click.prevent="openModal(
                'beneficiaries',
                'My Beneficiaries',
                'plan-tables-striped beneficiary',
                null,
                null,
                null,
            )">
                Beneficiaries
            </a>
        </template>
        <a href="" @click.prevent="openModal('plan-resources', 'Vacation Fund Resources', null, 'vacation')" class="btn btn-tertiary btn-large plan-modal">Funds Resources</a>
    </FundPanel>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useVacationStore } from '@/stores/vacation';

import FundPanel from '@/components/cards/FundPanel.vue';

export default defineComponent({
    components: {
        FundPanel
    },
    setup() {
        const modalStore = useModalStore();
        const vacationStore = useVacationStore();
        const vacationContributions = computed(() => vacationStore.generalInfo);

        return {
            vacationContributions,
            openModal: modalStore.openModal
        };
    }
});
</script>