<template>
    <form @submit.prevent="login">
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">Login</div>
              <div class="card-body">
                <div class="mb-3">
                  <label for="email" class="form-label">Email address</label>
                  <input
                    v-model="credentials.email"
                    type="email"
                    id="email"
                    class="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input
                    v-model="credentials.password"
                    type="password"
                    id="password"
                    name="password"
                    class="form-control"
                    placeholder="Password"
                  />
                </div>
                <div class="form-check pdb-10">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="remember"
                    name="remember"
                  />
                  <label class="form-check-label" for="remember">
                    Remember me
                  </label>
                </div>
                <p class="pdt-10 pdb-20 text-danger fs-sm" v-if="error"> {{ error }}</p>

                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="loading"
                >
                  <span v-if="loading">Logging in...</span>
                  <span v-else>Login</span>
                </button>
                <p class="mt-10">
                  Don't have an account?
                  <router-link :to="{ name: 'register' }">
                    Click here to register
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </template>

<script lang="ts">
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import axios, { AxiosError } from 'axios';

  export default {
    setup() {
      const authStore = useAuthStore();
      const error = ref('');
      const credentials = ref({ email: '', password: '' });
      const loading = ref(false);

      const login = async () => {
        loading.value = true;
        error.value = '';

        try {
          await authStore.authenticateUser(credentials.value);
          loading.value = false;
        }
        catch (loginError: unknown) {
          loading.value = false;

          if (axios.isAxiosError(loginError)) {
            error.value = 'Login failed: ' + loginError.response?.data?.message;
          } else {
            error.value = 'An unknown error occurred';
          }
        }
      };

      return { credentials, login, loading, error };
    },
  };
  </script>
