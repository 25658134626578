<template>
    <div class="modal-body">
      <div class="d-flex justify-center flex-column w-100">
        <p>
          We have sent you a verification code to your please check and add the code below in the boxes.
        </p>
        <div class="mt-10">
            <VerificationCodeInput v-model="verificationCode" :error="errors" />
        </div>
        <div v-if="success" class="mt-10">
            <p>{{ success }}</p>
        </div>
        <a href="" class="btn btn-primary w-100 mt-20" @click.prevent="handleSubmit">Enter Code</a>
        <div class="text-center mt-10">
            <a href="#" @click.prevent="regenerateCode" class="links">Resend Code ({{ timer }} seconds remaining)</a>
        </div>
      </div>
    </div>
  </template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useVerificationCode } from '@/composable/useVerificationCode';
import axios from 'axios';
import VerificationCodeInput from '@components/form/VerificationCodeField.vue';

export default defineComponent({
components: {
    VerificationCodeInput,
},

setup() {
    const modalStore = useModalStore();
    const method: string|null = modalStore.currentModal?.queryParams?.method as string;
    // const timer = ref(0);
    const timerInterval = ref<number | null>(null);
    const expirationTime = ref(120);
    // const errors = ref('');
    // const success = ref('');

    // const verificationCode = ref('');

    const {
      verificationCode,
      timer,
      success,
      errors,
      generateCode,
      regenerateCode,
      submitCode,
    } = useVerificationCode(method ?? '', 3);

    const handleSubmit = async () => {
        try {
            submitCode();
            modalStore.closeLastModal();
            modalStore.openModal(
            "success-modal",
            null,
            "modal-success",
            null,
            {
                title: "Preferred method changed successfully",
                content: {
                    type: "text",
                    value: 'Your preferrred method has been successfully updated, all upcoming codes will be sent to your new method',
                        icon: "iatse-icon-user-verified"
                    },
                    onConfirmButton: "Close",
                },
            );
        }
        catch (error: any) {
            if (error.response && error.response.data) {
                errors.value = error.response.data.error || 'An error occurred';
            }
            else {
                errors.value = 'Unexpected error occurred';
            }
        }
    };

    // const submitCode = async () => {
    //     try {
    //         const response = await axios.post('/api/authorization/change-method/submit-code', {
    //             verification_code: verificationCode.value,
    //             method: method,
    //         });
    //         modalStore.closeLastModal();

    //         modalStore.openModal(
    //         "success-modal",
    //         null,
    //         "modal-success",
    //         null,
    //         {
    //             title: "Preferred method changed successfully",
    //             content: {
    //                 type: "text",
    //                 value: 'Your preferrred method has been successfully updated, all upcoming codes will be sent to your new method',
    //                     icon: "iatse-icon-user-verified"
    //                 },
    //                 onConfirmButton: "Close",
    //             },
    //         );
    //     }
    //     catch (error: any) {
    //         if (error.response && error.response.data) {
    //             // Capture API errors
    //             errors.value = error.response.data.error || 'An error occurred';
    //         }
    //         else {
    //             errors.value = 'Unexpected error occurred';
    //         }
    //     }
    // };

    // const generateCode = async () => {
    //     const response = await axios.post(
    //         'api/authorization/change-method/generate-code', {
    //             method: method
    //         }
    //     );

    //     if (response.data.success) {
    //         if (response.data.expirationTime) {
    //             expirationTime.value = response.data.expirationTime;
    //         }
    //         else {
    //             expirationTime.value = 120;
    //         }

    //         startTimer();
    //     }
    // };

    // const regenerateCode = async () => {
    //     try {
    //         const response = await axios.post('/api/authorization/change-method/regenerate-code', {
    //             method: method
    //         });

    //         if (response.data.success) {
    //             // Use new expiration time if provided
    //             if (response.data.expirationTime) {
    //                 expirationTime.value = response.data.expirationTime;
    //             }
    //             else {
    //                 expirationTime.value = 120; // Default to 60 seconds
    //             }

    //             success.value = 'New code was sent.';
    //             errors.value = '';
    //             startTimer();
    //         }
    //         else {
    //         // Handle error
    //         errors.value = response.data.message || 'An error occurred while resending the code.';
    //         }
    //     }
    //     catch (error: any) {
    //         console.error(error);
    //         errors.value = error.response?.data?.message || 'An error occurred.';
    //     }
    // };

    // const startTimer = () => {
    //     timer.value = expirationTime.value;
    //     if (timerInterval.value) {
    //         clearInterval(timerInterval.value);
    //     }
    //     timerInterval.value = setInterval(() => {
    //         if (timer.value > 0) {
    //           timer.value--;
    //         }
    //         else {
    //           clearInterval(timerInterval.value!);
    //           timerInterval.value = null;
    //         }
    //       }, 1000);
    // };

    // onMounted(() => {
    //     generateCode();
    // });

    return {
        timer,
        expirationTime,
        method,
        verificationCode,
        handleSubmit,
        regenerateCode,
        success,
        errors
    };
},
});
</script>
