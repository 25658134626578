import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "plan-content justify-content-between"
}
const _hoisted_2 = { class: "d-flex justify-content-center align-items-center w-100 mt-20 mb-20 pdl-5 pdr-5" }
const _hoisted_3 = { class: "d-flex gap-5 flex-wrap" }
const _hoisted_4 = { class: "w-100 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_CoverageStep1 = _resolveComponent("CoverageStep1")!
  const _component_CoverageStep2 = _resolveComponent("CoverageStep2")!
  const _component_DownloadStep = _resolveComponent("DownloadStep")!
  const _component_CoverageStep3 = _resolveComponent("CoverageStep3")!
  const _component_CoverageStep4 = _resolveComponent("CoverageStep4")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, { errors: $setup.errors }, null, 8, ["errors"]),
    _createVNode(_component_MessageCard, {
      message: $setup.messageCardInfo,
      type: "danger",
      iconClass: "iatse-icon-alarm",
      classes: "info_card_full"
    }, null, 8, ["message"]),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.steps, (step) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: step.id,
                  class: _normalizeClass(['fw-semibold', 'ribbon ribbon_secondary', $setup.currentStep === step.id ? 'active text-white' : 'text-dark', $setup.currentStep > step.id ? 'completed text-white' : 'text-dark'])
                }, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(step.label), 1)
                ], 2))
              }), 128))
            ])
          ]),
          ($setup.currentStep === 1)
            ? (_openBlock(), _createBlock(_component_CoverageStep1, {
                key: 0,
                onHandleNext: $setup.handleNext
              }, null, 8, ["onHandleNext"]))
            : _createCommentVNode("", true),
          ($setup.currentStep === 2)
            ? (_openBlock(), _createBlock(_component_CoverageStep2, {
                key: 1,
                ipAddress: $setup.ipAddress,
                download: $setup.download,
                onHandleBack: $setup.handleBack,
                onHandleNext: $setup.handleNext
              }, null, 8, ["ipAddress", "download", "onHandleBack", "onHandleNext"]))
            : _createCommentVNode("", true),
          ($setup.currentStep === 3 && Number($setup.download))
            ? (_openBlock(), _createBlock(_component_DownloadStep, {
                key: 2,
                requests: $setup.requests
              }, null, 8, ["requests"]))
            : _createCommentVNode("", true),
          ($setup.currentStep === 3 && !Number($setup.download))
            ? (_openBlock(), _createBlock(_component_CoverageStep3, {
                key: 3,
                onHandleBack: $setup.handleBack,
                onHandleNext: $setup.handleNext
              }, null, 8, ["onHandleBack", "onHandleNext"]))
            : _createCommentVNode("", true),
          ($setup.currentStep === 4)
            ? (_openBlock(), _createBlock(_component_CoverageStep4, {
                key: 4,
                onHandleBack: $setup.handleBack,
                onHandleSubmit: $setup.handleSubmit,
                ssnOption: $setup.ssnOption,
                excelModel: $setup.excelModel,
                submitting: $setup.submitting
              }, null, 8, ["onHandleBack", "onHandleSubmit", "ssnOption", "excelModel", "submitting"]))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}