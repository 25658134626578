<template>
    <form class="form" v-if="person">
        <TextInput type="text" label="Participant ID" v-model:modelValue="person.PersonID" :required="true"
            :error="changes?.PersonID" :readonly="true" customClass="w-100"/>
        <div class="w-100 d-flex gap-4">
            <TextInput type="text" label="Salutation" placeholder="Salutation" :max=12
                v-model:modelValue="person.Salutation" :readonly="true" :required="true" :has-changes="changes?.Salutation" />
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="person.FirstName" :readonly="true" :required="true" :has-changes="changes?.FirstName" />
            <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12
                v-model:modelValue="person.MiddleName" :readonly="true" :has-changes="changes?.MiddleName" />
        </div>
        <div class="w-100 d-flex gap-4">
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="person.LastName" :readonly="true" :has-changes="changes?.LastName" />
            <TextInput type="text" label="Salutation" placeholder="Suffix" v-model:modelValue="person.NameSuffix"
                :readonly="true" :required="true" :has-changes="changes?.NameSuffix" />
            <DateInput label="Date of Birth" v-model:modelValue="person.DateofBirth" :placeholder="'dd/mm/yyyy'"
                input-type="date" :readonly="true" :has-changes="changes?.DateofBirth" />
        </div>
        <div class="w-100 d-flex gap-4">
            <TextInput type="text" label="Gender" v-model:modelValue="person.Sex" :readonly="true" :has-changes="changes?.Sex" />
            <TextInput type="text" label="Marital Status" v-model:modelValue="person.MaritalStatus" :readonly="true" :has-changes="changes?.MaritalStatus" />
            <TextInput type="text" label="Home Local" v-model:modelValue="person.HomeLocal" :readonly="true" :has-changes="changes?.HomeLocal" />
        </div>
    </form>
    <div class="form" v-else>
        Data is not available
    </div>
</template>

<script lang="ts">
import { ReviewDependentData } from '@/interfaces/interfaces';
import { PropType, defineComponent } from 'vue';
import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';

export default defineComponent({
    components: {
        DateInput,
        TextInput,
    },
    props: {
        data: {
            type: Object as PropType<ReviewDependentData | null>,
            required: true
        },
    },
    setup(props) {
        const person = props.data?.Person ? JSON.parse(JSON.stringify(props.data?.Person)) : null;
        const changes = props.data?.Changes ? JSON.parse(JSON.stringify(props.data?.Changes)) : null;

        return { person, changes }
    }
})
</script>
