<template>
     <div class="pdl-10 pdr-10 mt-30">
        <h2 class="fw-bold mb-30">ACA Mandate Related Reports</h2>
        <LoaderComponent v-if="loading" />
        <div v-else class="d-flex gap-15 flex-md-row flex-column">
            <div class="bordered-card bordered-card_tertiary justify-content-between pdt-25 pdb-25 gap-25 flex-row">
                <div class="d-flex flex-column align-items-start gap-10 flex-2">
                    <h4 class="fw-medium">Request Employee Health Coverage Date </h4>
                    <p class="text-primary plan-modal d-flex gap-10 align-items-center" @click.prevent="openModal('employer-aca-report', 'Employee Health Coverage Information Request', null, null, null, null, null, { download: '0' })"> Request <i class="iatse-icon-arrow fs-lg"> </i></p>
                </div>
                <img src="@/assets/images/Childcare_expenses_2_@2x.png" width=90 height="89" alt="Childcare expenses"/>
            </div>
            <div class="bordered-card bordered-card_tertiary justify-content-between pdt-25 pdb-25 gap-25 flex-row">
                <div class="d-flex flex-column align-items-start gap-10 flex-2">
                    <h4 class="fw-medium">Download requested Health coverage Data</h4>
                    <p class="text-primary plan-modal d-flex gap-10 align-items-center" @click.prevent="openModal('employer-aca-report', 'Employee Health Coverage Information Request', null, null, null, null, null, { download: '1' })"> Download <i class="iatse-icon-arrow fs-lg"> </i></p>
                </div>
                <img src="@/assets/images/Relationship_status_2_.png" width=83 height="89" alt="Relationship status"/>
            </div>
            <div class="bordered-card bordered-card_tertiary justify-content-between pdt-25 pdb-25 gap-25 flex-row">
                <div class="d-flex flex-column align-items-start gap-10 flex-2">
                    <h4 class="fw-medium">Information about ACA Mandate </h4>
                    <a :href="pdf" target="_blank" class="text-primary d-flex gap-10 align-items-center"> See Information <i class="iatse-icon-arrow fs-lg"> </i></a>
                </div>
                <img src="@/assets/images/Contract_of_carriage_1_.png" width=95 height="89" alt="Contract of carriage"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';

export default defineComponent({
    setup() {
        const loading = ref(true);
        const pdf = ref('#');

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/employer/aca-information')
                .then(response => {
                    pdf.value = response.data;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            loading,
            pdf,
            openModal: useModalStore().openModal
        }
    }
})
</script>