import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex w-100 flex-column flex-md-row" }
const _hoisted_2 = { class: "action-buttons justify-content-start" }
const _hoisted_3 = { class: "action-buttons justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_DateInput = _resolveComponent("DateInput")!

  return (_openBlock(), _createElementBlock("form", {
    method: "GET",
    class: "form bordered-card bordered-card_secondary mb-20 form-columns_3 justify-content-between",
    onSubmit: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.submitFilters(0)), ["prevent"])),
    onKeydown: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.submitFilters(0)), ["enter"]))
  }, [
    _createVNode(_component_TextInput, {
      type: "text",
      name: "number",
      label: "NBF Participant ID",
      max: 20,
      modelValue: _ctx.filters.PersonID,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.PersonID) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_TextInput, {
      type: "text",
      label: "First Name",
      max: 50,
      modelValue: _ctx.filters.FirstName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.FirstName) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_TextInput, {
      type: "text",
      label: "Last Name",
      max: 50,
      modelValue: _ctx.filters.LastName,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.LastName) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_DateInput, {
      label: "Birth Date",
      modelValue: _ctx.filters.BirthDate,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters.BirthDate) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_TextInput, {
      type: "email",
      label: "Email",
      max: 50,
      modelValue: _ctx.filters.Email,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters.Email) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_DateInput, {
      label: "Last Login",
      modelValue: _ctx.filters.LastLogin,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.LastLogin) = $event)),
      reset: _ctx.reset,
      "onUpdate:reset": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.reset) = $event))
    }, null, 8, ["modelValue", "reset"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.openModal('superadmin-create-user', 'Create User', 'plan-upload plan-card-info plan-c-mrp-claims')), ["prevent"])),
          class: "btn btn-primary plan-modal"
        }, "Create User")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-secondary",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.submitFilters(1)))
        }, " Clear Filter "),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.submitFilters(0)))
        }, _toDisplayString(_ctx.searching ? 'Searching...' : 'Apply Filters'), 1)
      ])
    ])
  ], 32))
}