<template>
    <div class="action-buttons" v-if="otherCoverages">
      <a
        v-if="otherCoverages?.Retiree?.Show"
        class="btn btn-primary plan-modal"
        @click.prevent="openModal('hw-retiree-payments-history', 'Payments History', 'plan-retiree')"
      >
        Retiree Payments History
      </a>

      <a
        v-if="otherCoverages.A.Show"
        class="btn btn-primary plan-modal"
        @click.prevent="openModal('hw-plan-a-summary', 'Plan A Summary', 'plan-a-family', null, null, null, null, { FromDate: otherCoverages.A.From })"
      >
        Plan A
      </a>
    </div>
  </template>
  
<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { OtherCoverages, OtherCoverageParams } from '@/interfaces/health';
  import { useModalStore } from '@/stores/modal';
  
  export default defineComponent({
    name: 'OtherCoverages',
    props: {
      otherCoverages: {
        type: Object as PropType<OtherCoverages>,
        required: true,
      },
    },
    setup(props, { emit }) {
        const openModal = useModalStore().openModal;
      // Emitting the openModal event when the link is clicked
      const handleOpenModal = () => {
        emit('openModal', 'hw-retiree-payments-history', 'Payments History', 'plan-retiree');
      };
  
      return {
        openModal, handleOpenModal
      };
    },
  });
</script>
