<template>
    <div class="plan-content">
        <div class="container-fluid">
            <div class="row">
                <!-- Payment Form -->
                <div class="col-md-6">
                    <form @submit.prevent="payCobra">
                        <!-- Postal/Zip Code -->
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="postalCode" class="body-text">Postal/Zip Code</label>
                                    <input type="text" id="postalCode" v-model="postalCode" class="links" />
                                </div>
                            </div>
                        </div>
                        <!-- Card Type -->
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="cardType" class="body-text">Card Type</label>
                                    <input type="text" id="cardType" v-model="cardType" class="links" />
                                </div>
                            </div>
                        </div>
                        <!-- Cardholder's First Name -->
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="cardholderFirstName" class="body-text">Cardholder's First Name</label>
                                    <input type="text" id="cardholderFirstName" v-model="cardholderFirstName" class="links" />
                                </div>
                            </div>
                        </div>
                        <!-- Cardholder's Last Name -->
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="cardholderLastName" class="body-text">Cardholder's Last Name</label>
                                    <input type="text" id="cardholderLastName" v-model="cardholderLastName" class="links" />
                                </div>
                            </div>
                        </div>
                        <!-- Card Number -->
                        <div class="row">
                            <div class="col-12">
                                <CardNumberField v-model="cardNumber" />
                            </div>
                        </div>
                        <!-- Card Expiration Date -->
                        <div class="row">
                            <div class="col-12">
                                <CardExpirationField v-model="cardExpiration" />
                            </div>
                        </div>
                        <!-- CVV -->
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="cvv" class="body-text">CVV</label>
                                    <input type="text" id="cvv" v-model="cvv" placeholder="Add CVV" class="links" />
                                </div>
                            </div>
                        </div>
                        <!-- Submit Button -->
                        <div class="row mt-20">
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary">Pay Now</button>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Invoice Summary -->
                <div class="col-md-6">
                    <div class="cobra-summary">
                        <table class="primary-table">
                            <thead>
                                <tr>
                                    <th>Invoice</th>
                                    <th>Coverage Period</th>
                                    <th>Amount Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(invoice, index) in selectedInvoicesDetails"
                                    :key="index"
                                    >
                                    <td>{{ invoice.PlanName }}</td>
                                    <td>
                                        {{
                                        invoice.InsuranceStartDate +
                                        " - " +
                                        invoice.InsuranceEndDate
                                        }}
                                    </td>
                                    <td>{{ formatCurrency(invoice.Amount) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            class="d-flex justify-content-between align-items-center"
                            v-if="payInAdvanceSelected"
                            >
                            <p>Additional Amount</p>
                            <h4>{{ payInAdvance !== null ? formatCurrency(payInAdvance) : 0 }}</h4>
                        </div>
                        <!-- Total Amount -->
                        <div class="d-flex justify-content-between align-items-center">
                            <p>Total Amount</p>
                            <h4>{{ formatCurrency(total) }}</h4>
                        </div>
                        <a href="#" @click.prevent="closeLastModal" class="btn btn-primary">Edit COBRA Payment</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { ref, computed } from 'vue';
import axios from 'axios';
import AxiosError from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCoverageStore } from '@/stores/coverage'; 
import { useCurrencyFormatter } from '@/composable/useCurrencyFormatter';
import { useInvoicesStore } from '@/stores/cobraInvoices';
import CardNumberField from '@/components/form/CardNumberField.vue';
import CardExpirationField from '@/components/form/CardExpirationField.vue';

export default {
    components: {
        CardNumberField,
        CardExpirationField
    },
    setup() {
    const invoicesStore = useInvoicesStore();
    const modalStore = useModalStore();
    const openModal = modalStore.openModal;
    const closeLastModal = modalStore.closeLastModal;
    const { formatCurrency } = useCurrencyFormatter();
    const coverageStore = useCoverageStore();
    const {
        selectedInvoicesDetails,
        total,
        payInAdvanceSelected,
        payInAdvance,
        selectedInvoices,
    } = invoicesStore;

    // Reactive variables for form inputs
    const postalCode = ref('');
    const cardType = ref('');
    const cardholderFirstName = ref('');
    const cardholderLastName = ref('');
    const cardNumber = ref('');
    const cardExpiration = ref('');
    const error = ref('');
    const cvv = ref('');

    const payCobra = async () => {
        try {
            // Prepare POST data
            const data = {
                invoices: selectedInvoices.join(','),
                postal_code: postalCode.value,
                card_type: cardType.value,
                cardholder_first_name: cardholderFirstName.value,
                cardholder_last_name: cardholderLastName.value,
                card_number: cardNumber.value,
                card_expiration: cardExpiration.value,
                card_cvv: cvv.value,
                additional_pay: 0
            };

            if (payInAdvanceSelected && payInAdvance !== null) {
                data.additional_pay = payInAdvance;
            }

            // Make the POST request
            const response = await axios.post('/api/checkout/cobra', data);

            // Check if the response status indicates success (2xx)
            if (response.status >= 200 && response.status < 300) {
                // Validate the response data
                const {
                    success,
                    transaction_id,
                    error_code,
                    error_message
                } = response.data.data;

                if (success) {
                    invoicesStore.clearStore();
                    modalStore.closeAllModals();

                    // Refetch health data after payment success
                    await coverageStore.fetchHealthData();

                    // Payment was successful
                    openModal('payment-status', 'x', 'modal-success', null, null, undefined, undefined, {
                        success: true,
                        transactionId: transaction_id,
                    });
                } else {
                    // Payment was unsuccessful, but the request was successful
                    openModal('payment-status', 'x', 'modal-success', null, null, undefined, undefined, {
                        success: false,
                        errorCode: error_code,
                        message: error_message || 'Payment was unsuccessful.',
                    });
                }
            } else {
                // Handle unexpected HTTP status codes
                console.error(`Unexpected HTTP status: ${response.status}`);
                openModal('payment-status', 'x', 'modal-success', null, null, undefined, undefined, {
                    success: false,
                    message: 'An unexpected error occurred. Please try again later.',
                });
            }
        }
        catch (error) {
            // Handle network errors or exceptions thrown during the request
            let errorMessage = 'An error occurred during payment processing.';

            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Server responded with a status other than 2xx
                    const {
                        status,
                        data
                    } = error.response;
                    console.error(`Error response status: ${status}`, data);

                    errorMessage = data?.error_message || data?.message || errorMessage;
                } else if (error.request) {
                    // Request was made but no response received
                    console.error('No response received:', error.request);
                    errorMessage = 'No response from the server. Please check your network connection.';
                } else {
                    // Something else happened while setting up the request
                    console.error('Error', error.message);
                    errorMessage = error.message;
                }
            } else {
                // Non-Axios error
                console.error('Unexpected error:', error);
            }

            openModal('payment-status', 'x', 'modal-success', null, null, undefined, undefined, {
                success: false,
                message: errorMessage,
            });
        }
    };

    return {
        openModal,
        closeLastModal,
        selectedInvoicesDetails,
        total,
        formatCurrency,
        payInAdvanceSelected,
        payInAdvance,
        payCobra,
        // Form input variables
        postalCode,
        cardType,
        cardholderFirstName,
        cardholderLastName,
        cardNumber,
        cardExpiration,
        cvv,
        error
    };
    }
};
</script>