<template>
    <div class="bordered-card bordered-card_secondary gap-10">
        <h4 class="fw-bold">Reports</h4>
        <div class="d-flex gap-10 w-100 flex-md-row flex-column">
            <div class="bordered-card bordered-card_secondary justify-content-between pdt-25 pdb-25 gap-25 flex-md-row flex-wrap">
                <div class="d-flex flex-column align-items-start gap-10 flex-2">
                    <p class="fw-bold">Submit eRemittance </p>
                    <h6 class="card-title"> Lorem ipsum dolor sit amet consectetur. Malesuada diam velit etiam etiam
                        consequat. </h6>
                </div>
                <a class="btn btn-primary border-radius-8 pdl-30 pdr-30 w-sm-100 w-fit flex-1">
                    Submit
                </a>
            </div>
            <div class="bordered-card bordered-card_secondary justify-content-between pdt-25 pdb-25 gap-25 flex-md-row flex-wrap">
                <div class="d-flex flex-column align-items-start gap-10 flex-2">
                    <p class="fw-bold">Submit ACA Mandate </p>
                    <h6 class="card-title"> Lorem ipsum dolor sit amet consectetur. Malesuada diam velit etiam etiam
                        consequat. </h6>
                </div>
                <a href="javascript;" @click.prevent="openModal('employer-aca-report', 'Employee Health Coverage Information Request', null, null, null, null, null, { download: '0' })" class="plan-modal btn btn-primary border-radius-8 pdl-30 pdr-30 w-sm-100 w-fit flex-1">
                    Submit
                </a>
            </div>
            <div class="bordered-card bordered-card_secondary justify-content-between pdt-25 pdb-25 gap-25 flex-md-row flex-wrap">
                <div class="d-flex flex-column align-items-start gap-10 flex-2">
                    <p class="fw-bold">Download Health Coverage Report </p>
                    <h6 class="card-title"> Lorem ipsum dolor sit amet consectetur. Malesuada diam velit etiam etiam
                        consequat. </h6>
                </div>
                <a href="javascript;" @click.prevent="openModal('employer-aca-report', 'Employee Health Coverage Information Request', null, null, null, null, null, { download: '1' })" class="plan-modal btn btn-primary border-radius-8 pdl-30 pdr-30 w-sm-100 w-fit flex-1">
                    Download
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ContributionPreview } from '@/interfaces/local';
import { PropType, defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';

export default defineComponent({
    props: {
        contributions: {
            type: Object as PropType<ContributionPreview | null>,
            required: false
        }
    },
    setup() {
        return {
            openModal: useModalStore().openModal
        }
    }
})
</script>