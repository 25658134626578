<template>
    <div class="pdl-10 pdr-10 mt-30">
       eRemittance Report - Coming soon
   </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';

export default defineComponent({
   setup() {
       const loading = ref(true);

       return {
       }
   }
})
</script>