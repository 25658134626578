<template>
   <div class="plan-content">
        <h2 class="sm-fs-base">Select the Method of Request </h2>
        <div class="radio-group primary bold mt-20">
            <div class="radio-buttons gap-20 w-100 flex-column flex-md-row">
                <div class="radio-button flex-column enlarged h-100 bordered-card bordered-card_secondary bg-tertiary w-25 w-sm-100 align-items-start">
                    <input type="radio" id="upload" name="RequestMethod" v-model="selectedOption" :value="0" />
                    <label class="body-text" for="upload">
                        Upload Excel file with Employee(s) SSN
                    </label>
                </div>
                <div class="radio-button flex-column enlarged h-100 bordered-card bordered-card_secondary bg-tertiary w-25 w-sm-100 align-items-start">
                    <input type="radio" id="web_form" name="RequestMethod" v-model="selectedOption" :value="1" />
                    <label class="body-text" for="web_form">
                        Enter Employee(s) SSN via Web Request Form
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-between gap-20">
        <button class="btn btn-secondary" @click="handleBack">Back</button>
        <button class="btn btn-primary" @click="handleNext">Next</button>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    setup(_, { emit }) {
        const selectedOption = ref('0');

        const handleBack = () => {
            emit('handle-back');
        }

        const handleNext = () => {
            emit('handle-next', selectedOption.value);
        }

        return {
            selectedOption,
            handleBack,
            handleNext
        }
    }
})
</script>