<template>
  <aside class="main-sidebar" :class="{'active': active, 'slide-out': !active, 'large': isLocal || isEmployer}">
    <div class="aside-logo-menu">
      <LogoComponent />
      <MenuComponent @open-menu="openMenu" />
    </div>
    <div class="divider"> </div>
    <ContactComponent />
  </aside>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LogoComponent from './LogoComponent.vue';
import MenuComponent from './MenuComponent.vue';
import ContactComponent from './ContactComponent.vue';

export default defineComponent ({
  name: 'SidebarComponent',
  components: {
    LogoComponent,
    MenuComponent,
    ContactComponent
  },
  props: {
    active: Boolean,
    isLocal: Boolean,
    isEmployer: Boolean,
  },
  setup(_, { emit }) {
    const openMenu = () => {
        emit('open-menu');
    }

    return {
      openMenu
    }
  }
})
</script>
