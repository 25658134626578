<template>
    <div class="plan-content">
        <div class="container-fluid" v-if="dataLoaded">
            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <h3>Coverage Options</h3>
                    <p>
                        Your coverage selection and any applicable payment is due no later than <b>{{ dueDate }}</b>
                        for the coverage period starting <b>{{ coveragePeriod }}</b>
                    </p>
                </div>
                <div class="col-md-4 col-sm-12">
                    <a href="#" class="links">Benefit at a Glance</a>
                </div>
            </div>

            <!-- Display Plan C Options -->
            <div class="row row-cols-1 row-cols-md-4 mt-20">
                <!-- v-for over groupedOptions, skipping MRP (shown separately) -->
                <div 
                    class="col" 
                    v-for="(planData, plan) in groupedOptions" 
                    :key="plan"
                >
                    <div class="capp-statement">

                        <!-- TOGGLE BUTTONS: only show if there is both Single AND Family -->
                        <div class="capp-statement__toggle d-flex justify-content-center" 
                             v-if="hasFamilyAndSingle(coverageOptions[plan])">
                            <div class="toggle-switch">
                                <button 
                                    :class="{ active: !familyToggle[plan] }" 
                                    @click="familyToggle[plan] = false"
                                >
                                    Single
                                </button>
                                <button 
                                    :class="{ active: familyToggle[plan] }" 
                                    @click="familyToggle[plan] = true"
                                >
                                    Family
                                </button>
                            </div>
                        </div>
                        
                        <!-- PLAN DETAILS -->
                        <div class="capp-statement__details mt-30">
                            <h3>{{ planData.current.Title }}</h3>

                            <div class="capp-statement__owed mt-10">
                                <p>Amount Owed</p>
                                <h4>{{ formatCurrency(planData.current.AmountOwed) }}</h4>
                            </div>
                        </div>

                        <!-- ACTION BUTTON -->
                        <div class="capp-statement__action mt-30">
                            <button 
                                class="btn btn-primary w-100"
                                :class="{ 'btn-selected': isInvoiceSelected(planData.current.OptionKey) }"
                                @click="selectOption(plan, planData.current)"
                            >
                                {{ planData.current.IsCurrent 
                                    ? 'Continue this coverage' 
                                    : 'Select this coverage'
                                }}
                            </button>
                        </div>
                    </div>
                </div>

                <!-- MRP option (if available) -->
                <div class="col" v-if="coverageOptions['MRP']">
                    <div class="capp-statement">
                        <div class="capp-statement__details">
                            <h3>{{ coverageOptions['MRP'].Title }}</h3>
                            <div class="capp-statement__owed">
                                <p>Amount Owed</p>
                                <h4>{{ formatCurrency(coverageOptions['MRP'].AmountOwed) }}</h4>
                            </div>
                        </div>
                        <div class="capp-statement__action mt-30">
                            <button 
                                class="btn btn-primary w-100" 
                                :class="{ 'btn-selected': isInvoiceSelected(coverageOptions['MRP'].OptionKey) }"
                                @click="selectOption('MRP', coverageOptions['MRP'])"
                            >
                                <span v-if="hasMrpDocuments">
                                    {{ coverageOptions['MRP'].IsCurrent ? 'Continue this coverage' : 'Select this coverage' }}
                                </span>
                                <span v-else>Related Instructions</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> <!-- end row -->
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { CappStatementData, PlanCOptions, CappStatementOption, CoverageOptions } from '@/interfaces/health';
import { useCappInvoicesStore } from '@/stores/cappInvoice';
import { useCoverageStore } from '@/stores/coverage'; 

export default {
    setup() {
        const dataLoaded = ref(false);
        const coverageOptions = ref<CoverageOptions>({});
        const dueDate = ref('');
        const coveragePeriod = ref('');
        const hasMrpDocuments = ref(false);

        // familyToggle[plan] = true means "Family" is selected for that plan, false => "Single"
        const familyToggle = ref<{ [key: string]: boolean }>({});

        const openModal = useModalStore().openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const cappInvoicesStore = useCappInvoicesStore();
        const coverageStore = useCoverageStore();

        // --- Fetch Data ------------------------------------------------------
        const fetchData = () => {
            axios
                .get('/api/participant/health-welfare/capp-statement')
                .then((response) => {
                    coverageOptions.value = response.data.PlanCStatement.CoverageOptions;
                    hasMrpDocuments.value = response.data.HasMrpDocuments;
                    dueDate.value = response.data.PlanCStatement.DueDate;
                    coveragePeriod.value = response.data.PlanCStatement.CoveragePeriod;
                    dataLoaded.value = true;

                    initializeFamilyToggle();
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        // --- Initialize toggles based on the presence of a current Family plan
        const initializeFamilyToggle = () => {
            // If there's any plan where Family is current, we set that plan's toggle to Family.
            // Otherwise default to false (Single).
            Object.keys(coverageOptions.value).forEach((plan) => {
                // skip MRP for toggles
                if (plan === 'MRP') return;

                const planObj = coverageOptions.value[plan];
                // If plan has both Single & Family, set toggle to whichever is currently IsCurrent = true
                if (hasFamilyAndSingle(planObj)) {
                    if (planObj.Family?.IsCurrent) {
                        familyToggle.value[plan] = true;
                    } else {
                        // fallback if Single is current or no coverage is "current"
                        familyToggle.value[plan] = false;
                    }
                } else {
                    // If there's only Single or only Family, default to false for Single in the store
                    // but in practice, the toggle won't appear in the UI if only one coverage is present.
                    familyToggle.value[plan] = false;
                }
            });
        };

        // --- Utility: check if this plan has both Single & Family
        function hasFamilyAndSingle(option: any): option is PlanCOptions {
            return (
                option && 
                option.Single !== undefined && 
                option.Family !== undefined
            );
        }

        function isPlanCOptions(obj: unknown): obj is PlanCOptions {
            // A minimal check: if it has 'Single' or 'Family', it’s PlanCOptions
            return (
                typeof obj === 'object' &&
                obj !== null &&
                ('Single' in obj || 'Family' in obj)
            );
            }

        // --- "groupedOptions": pick correct coverage object based on toggle
        const groupedOptions = computed(() => {
            const grouped: { [plan: string]: { current: CappStatementOption } } = {};

            Object.keys(coverageOptions.value)
            .filter((plan) => plan !== 'MRP') 
            .forEach((plan) => {
                const planOptions = coverageOptions.value[plan];
                if (!planOptions) return;

                if (isPlanCOptions(planOptions)) {
                    // Use the toggler to pick which coverage to show
                    grouped[plan] = {
                        current: familyToggle.value[plan]
                            ? planOptions.Family!  // force non-null
                            : planOptions.Single!,
                    };
                } else {
                    // For MRP (or any single coverage object), just show it directly
                    grouped[plan] = {
                        current: planOptions
                    };
                }
            });

            return grouped;
        });


        // --- Selecting an option triggers either a zero-balance process or checkout modal
        const selectOption = (plan: string, option: CappStatementOption) => {
            const userConfirmed = confirm(`You have selected ${option.Title}. Are you sure?`);
            if (userConfirmed) {
                cappInvoicesStore.setSelectedInvoice(option);

                if (plan === 'MRP') {
                    if (!hasMrpDocuments.value) {
                        openModal('hw-mrp-terms', 'Certification', 'plan-c-mrp active');
                    } else {
                        processZeroBalance();
                    }
                } else {
                    if (parseFloat(String(option.AmountOwed)) === 0) {
                        processZeroBalance();
                    } else {
                        openModal('hw-capp-checkout', 'Make Capp Co-Payment', 'plan-c-mrp active');
                    }
                }
            }
        };

        // --- If plan's AmountOwed is zero, call the endpoint directly
        const processZeroBalance = async () => {
            try {
                const data = {
                    option: cappInvoicesStore.selectedInvoice?.OptionKey ?? null,
                };
                const response = await axios.post('/api/checkout/capp', data);

                if (response.status >= 200 && response.status < 300) {
                    const { success, transaction_id, error_code, error_message } = response.data.data;
                    if (success) {
                        openModal('payment-status', '', 'modal-success', null, null, undefined, undefined, {
                            success: true,
                            transactionId: transaction_id,
                            message: 'Payment Successful!',
                            type: 'capp'
                        });

                        // Refresh coverage data
                        await coverageStore.fetchHealthData();
                    } else {
                        openModal('payment-status', '', 'modal-success', null, null, undefined, undefined, {
                            success: false,
                            errorCode: error_code,
                            message: error_message || 'Payment was unsuccessful.',
                            type: 'capp'
                        });
                    }
                } else {
                    openModal('payment-status', '', 'modal-success', null, null, undefined, undefined, {
                        success: false,
                        message: 'An unexpected error occurred. Please try again later.',
                        type: 'capp'
                    });
                }
            } catch (err) {
                let errorMessage = 'An error occurred during payment processing.';
                if (axios.isAxiosError(err)) {
                    if (err.response) {
                        const { status, data } = err.response;
                        console.error(`Error response status: ${status}`, data);
                        errorMessage = data?.error_message || data?.message || errorMessage;
                    } else if (err.request) {
                        console.error('No response received:', err.request);
                        errorMessage = 'No response from the server. Please check your network connection.';
                    } else {
                        console.error('Error', err.message);
                        errorMessage = err.message;
                    }
                } else {
                    console.error('Unexpected error:', err);
                }

                openModal('payment-status', '', 'modal-success', null, null, undefined, undefined, {
                    success: false,
                    message: errorMessage,
                    type: 'capp'
                });
            }
        };

        onMounted(() => {
            fetchData();
        });

        return {
            dataLoaded,
            coverageOptions,
            dueDate,
            coveragePeriod,
            hasMrpDocuments,

            // toggles
            familyToggle,

            // computed & utilities
            groupedOptions,
            hasFamilyAndSingle,

            // composable
            formatCurrency,

            // actions
            selectOption,
            isInvoiceSelected: cappInvoicesStore.isInvoiceSelected
        };
    },
};
</script>
