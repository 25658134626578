<template>
    <MessageCard
        :message="message"
        type="danger"
        iconClass="iatse-icon-alarm"
        classes="info_card_full"
    />
    <AlertsComponent :error="error" @reset="() => error = ''"/>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content h-auto">
        <SelectComponent v-if="!isEditAction" label="Choose Document Type" v-model:selectedOption="selectedDocType"
            :options=docTypes customClass="full-width" />
        <DragDrop v-if="!isEditAction" customClasses="grey large w-100" @change="handleFileSelection" key="drag-drop-dep"
            :reset="reset" :loading="loadingUpload" />

        <div class="bordered-card w-100">
            <h4>Documents Currently On File/Pending Admin Preview</h4>
            <table class="small mb-4">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody v-if="uploadedDocs.length > 0">
                    <tr v-for="document in uploadedDocs" :key="document.DocumentID">
                        <td>
                            <a href="javascript;"
                                @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document?.DocumentID, FilePreview: document?.FilePreview, Type: 'dependent' })">
                                {{ document?.type_detail?.Description || document?.Description }}
                            </a>
                        </td>
                        <td>{{ new Date(document.Date).toLocaleDateString() }}</td>
                        <td :class="{ 'text-danger': document.Status === 'Pending' }">{{ document.Status }}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="3">
                            No Business Associate Agreement documents have been uploaded for this local.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button class="btn btn-primary" :disabled="loading || loadingUpload" @click.prevent="submitFiles">Submit</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed, onMounted } from 'vue';
import { Document, SelectOption } from '@/interfaces/interfaces';
import { useDependentStore } from '@/stores/dependent';
import { useModalStore } from '@/stores/modal';
import { useLocalStore } from '@/stores/local';

import DragDrop from '@components/form/DragDrop.vue';
import SelectComponent from '@components/form/SelectComponent.vue';

import axios from 'axios';

export default defineComponent({
    components: {
        DragDrop,
        SelectComponent
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const dependentStore = useDependentStore();
        const localStore = useLocalStore();
        const loading = ref(true);
        const uploadedDocs: Ref<Document[]> = ref([]);
        const fileNames = ref('');
        const fileInput = ref<HTMLInputElement | null>(null);
        const error: Ref<string | null> = ref(null);
        const selectedDocType = ref<SelectOption | null>(null);
        const message = ref<string | null>(null);
        const docTypes = ref<SelectOption[]>([]);
        const loadingUpload = ref(false);
        const reset = ref(false);
        const uploadedNew = ref(false);

        const handleFileSelection = async (event: Event) => {
            loadingUpload.value = true;
            reset.value = false;
            const formData = new FormData();

            formData.append('DocumentType', String(selectedDocType.value?.key));
            const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;

            if (files) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('Files[]', files[i]);
                }

                try {
                    const response = await axios.post(
                        "api/local/baa-file",
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    );

                    uploadedDocs.value = [...uploadedDocs.value, ...response.data.Files];
                    uploadedNew.value = true;
                } catch (err) {
                    console.error("There was an error uploading document!", err);
                }

                reset.value = true;
            }

            loadingUpload.value = false;
        };

        const fetchData = async () => {
            await axios
                .get('api/local/baa-file')
                .then(response => {
                    selectedDocType.value = response.data.Type;
                    docTypes.value = [response.data.Type];
                    uploadedDocs.value = response.data.Documents;
                    message.value = localStore.getBAAMessage();
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        const submitFiles = async () => {
            if(uploadedNew.value) {
                useModalStore().openModal('success-modal', null, 'modal-success', null, {
                    title: 'Upload H&W BAA',
                    content: {
                        type: "text",
                        value: 'Thank You we have received your request. It will be submitted to Fund office admin staff for review, and that data is not updated until the review is completed.',
                        icon: 'iatse-icon-user-verified'
                    },
                })
            } else {
                error.value = 'You must upload at least one document to create a request.'
            }

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            documentTypes: dependentStore.documentTypes,
            existingPersonID: false,
            selectedDocType,
            docTypes,
            fileNames,
            uploadedDocs,
            message,
            fileInput,
            error,
            reset,
            loading,
            loadingUpload,
            handleFileSelection,
            submitFiles,
            openModal: useModalStore().openModal
        };
    },
});
</script>