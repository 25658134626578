<template>
    <div class="plan-content">
        <form id="missing-contribution-form" class="form missing-contribution align-items-end gap-20" method="post" @submit.prevent>
            <div class="custom-select flex-row gap-20 align-items-end justify-content-start">
                <DateInput label="Work Period" v-model:modelValue="filters.WorkPeriod.StartDate" :is-required="true" :error="errors['WorkPeriod.StartDate']"/>
                <DateInput label="" v-model:modelValue="filters.WorkPeriod.EndDate" :error="errors['WorkPeriod.EndDate']"/>
            </div>
            <div class="custom-select links">
                <label class="body-text"> Employer(s) <span class="text-danger"> *</span></label>
                <Multiselect v-model="filters.Employers" :options="employers" placeholder="Type to search"
                    :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                    @select="handleEmployerChange" label="Name" track-by="ID"
                    @search-change="handleEmployersSearch" @remove="handleProductions"
                    :loading="loadingEmployers">
                </Multiselect>
                <small v-if="errors.Employers?.length" class="text-secondary-red"> {{ errors.Employers.join('. ') }} </small>
            </div>
            <div class="custom-select links">
                <label class="body-text"> Production(s) <span v-if="hasEmployers"> for selected
                        Employer(s)</span>: </label>
                <Multiselect v-model="filters.Productions" :options="productions" placeholder="Type to search"
                    :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                    @select="handleProductionChange" label="Name" track-by="ID" @open="handleProductionsOpen"
                    @search-change="handleProductionsSearch" :loading="loadingProductions">
                </Multiselect>
            </div>
            <TextInput type="text" name="number" label="Member Name or SSN" placeholder="Enter Member Name or SSN" :max=12
                v-model:modelValue="filters.ParticipantID" />
            <Textarea label="Additional Info:" palceholder="Type here..." v-model:modelValue="filters.Additional" />

            <button type="submit" class="btn btn-primary ms-auto mt-10" :disabled="loadingSubmit" @click.prevent="handleSubmit">
                {{ loadingSubmit ? 'Submitting...' : 'Submit' }}
            </button>
        </form>
    </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useModalStore } from '@/stores/modal'

import Multiselect from 'vue-multiselect';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Filters {
    WorkPeriod: {
        StartDate: string,
        EndDate: string,
    };
    Employers: Employer[] | null;
    Productions: Employer[] | null;
    ParticipantID: string;
    Additional: string;
}

interface Employer {
    ID: string | number;
    Name: string;
}

export default {
    components: {
        Multiselect,
        TextInput,
        DateInput,
        Textarea
    },

    setup() {
        const loadingEmployers = ref(false);
        const loadingProductions = ref(false);
        const loadingSubmit = ref(false);
        const allOption = { ID: 0, Name: 'ALL' };
        const filters: Filters = reactive({
            WorkPeriod: {
                StartDate: '',
                EndDate: '',
            },
            Employers: [allOption],
            Productions: [allOption],
            ParticipantID: '',
            Additional: '',
        });
        const employers = ref([]);
        const productions: Ref<Employer[]> = ref([]);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const router = useRouter();

        const hasEmployers = computed(() => filters.Employers?.some(employer => employer.ID !== 0));
        const hasProductions = computed(() => filters.Productions?.some(prod => prod.ID !== 0));

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            loadingEmployers.value = true;

            try {
                const response = await axios.get(`api/local/employers?query=${query}`);
                employers.value = response.data;
            }
            catch (error) {
                console.error('Error searching employer:', error);
            }

            loadingEmployers.value = false;
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            loadingProductions.value = true;
            const employerIds = filters.Employers?.map((employer: Employer) => {
                if (employer.ID !== 0)
                    return employer.ID
            });

            try {
                const response = await axios.get(`api/local/productions?query=${query}&&EmployerIDs=${employerIds}`);
                productions.value = response.data;
            }
            catch (error) {
                console.error('Error searching production:', error);
            }

            loadingProductions.value = false;
        };

        const handleEmployerChange = (newSelection: Employer) => {
            if (newSelection.ID === 0) {
                filters.Employers = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(filters.Employers)).filter((type: Employer) => type.ID !== 0)
                filters.Employers = [...filteredTypes];
                handleProductions();
            }
        }

        const handleProductions = async () => {
            await handleProductionsSearch('');

            if (filters.Productions && filters.Productions[0]?.ID !== 0) {
                filters.Productions = filters.Productions?.filter(prod => productions.value.some(p => p.ID === prod.ID)) || [];
            }
        }

        const handleProductionChange = (newSelection: Employer) => {
            if (newSelection.ID === 0) {
                filters.Productions = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(filters.Productions)).filter((type: Employer) => type.ID !== 0)
                filters.Productions = [...filteredTypes];
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = filters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                handleProductionsSearch(keyword);
            }
        };

        const handleSubmit = async () => {
            loadingSubmit.value = true;
            const formData = { ...filters };

            if (!hasEmployers.value) {
                formData.Employers = null;
            }

            if (!hasProductions.value) {
                formData.Productions = null;
            }

            await axios
                .post('api/local/missing-contributions', {
                    ...formData
                })
                .then(response => {
                    success.value = response.data.success;

                    const modalProps = {
                        title: "Successful",
                        content: {
                            type: "text",
                            value: success.value,
                            icon: "iatse-icon-user-verified",
                        },
                        onConfirmButton: "Back to Dashboard",
                    }

                    useModalStore().openModal('success-modal', null, 'modal-success', null, modalProps);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = error.response.data.errors;
                    }
                })
                .finally(() => {
                    // setTimeout(() => {
                    //     success.value = ''
                    //     errors.value = []
                    // }, 3500);
                })

            loadingSubmit.value = false;
        }

        return {
            loadingSubmit,
            loadingEmployers,
            loadingProductions,
            errors,
            success,
            filters,
            employers,
            productions,
            hasEmployers,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleProductionsOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handleSubmit,
        };
    }
}
</script>