import { defineStore } from "pinia";
import { PersonDemographicInfo } from "@/interfaces/employer";

import axios from "axios";

export const useEmployerStore = defineStore({
    id: "employer",
    state: () => ({
        demographics: [] as PersonDemographicInfo[],
        general: null
    }),

    actions: {
        async fetchDemographics() {
            await axios
                .get("api/user/demographic-information")
                .then((response) => {
                    this.demographics = response.data.Records;
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        async fetchGeneralInfo() {
            await axios
                .get("api/employer/profile")
                .then((response) => {
                    this.general = response.data.Data;
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },


        reset() {
            this.$reset();
        }
    },
});