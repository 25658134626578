<template>
    <AlertsComponent :errors="errors" :info="info" :success="success" />
    <div class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Missing Demographic Information</h2>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <table class="small striped toggle no-underline vertical-align-start mt-30">
                <thead class="large large_padding">
                    <tr class="">
                        <th>NBF ID</th>
                        <th>Member Name</th>
                        <th>Most Recent Employer/ <br> Production</th>
                        <th>D.O.B</th>
                        <th>Gender</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody v-if="data.length > 0">
                    <tr class="large" v-for="person in data" :key="person.PersonID">
                        <td>
                            <a v-if="isLocal" href="javascript;" class="plan-modal"
                                @click.prevent="openModal('local-member-profile', 'Member Profile', 'plan-profile plan-tables profile', null, { PersonID: person.PersonID })">
                                {{ person.PersonID }}
                            </a>
                            <p v-else class="plan-modal">
                                {{ person.PersonID }}
                            </p>
                        </td>
                        <td>{{ person.Name }} </td>
                        <td>{{ person.Employer }} <br />
                            {{ person.Production }}
                        </td>
                        <td>
                            {{ person.DateofBirth ?? '--'}}
                        </td>
                        <td>
                            {{ person.Sex ?? '--'}}
                        </td>
                        <td class="d-flex gap-30 flex-column">
                            <div class="d-flex gap-80 w-100">
                                <div class="d-flex flex-column gap-30 w-100">
                                    <p> Street 1: {{ person.Street1 ?? '--' }} </p>
                                    <p> City: {{ person.City ?? '--'}} </p>
                                    <p> Postal Code: {{ formatZipCode(person.ZipCode) }} </p>
                                </div>
                                <div class="d-flex flex-column gap-30 w-100">
                                    <p> Street 2: {{ person.Street2 ?? '--'}} </p>
                                    <p> State: {{ person.State ?? '--'}} </p>
                                    <p> Country: {{ person.Country ?? '--'}} </p>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary border-radius-8 w-fit lh-1 align-self-end plan-modal" @click="openModal('user-edit-demographics', 'Missing Demographics Information', 'plan-upload plan-card-info plan-profile-update', null, { PersonID: person.PersonID })">
                                Update
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="text-center">
                        <td colspan="6">
                            Currently, there are no employees who require demographic data updates.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useLocalStore } from '@/stores/local';
import { useAuthStore } from '@/stores/auth';
import { useFormatZipCode } from '@/composable/useFormatZipCode';
import { PersonDemographicInfo } from '@/interfaces/employer';

export default {
    setup() {
        const loading = ref(true);
        const modalStore = useModalStore();
        const localStore = useLocalStore();
        const isLocal = useAuthStore().isLocal;
        const data: Ref<PersonDemographicInfo[] | []> = computed(() => localStore.demographics);
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const info = ref(null);

        const formatZipCode = (code: string) => {
            const { formatZipCode} = useFormatZipCode(code);

            return formatZipCode.value;
        }

        const fetchData = async () => {
            if(!localStore.demographics.length) {
                await localStore.fetchDemographics()
            }

            loading.value = false;
        };


        onMounted(async () => await fetchData());

        return {
            isLocal,
            loading,
            data,
            success,
            info,
            errors,
            formatZipCode,
            openModal: modalStore.openModal,
        };
    }
}
</script>