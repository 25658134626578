<template>
    <MessageCard message="If you have misplaced your vacation check or it is stale-dated, you can request a replacement by completing the information below."
    type="danger" iconClass="iatse-icon-alarm"  classes="info_card_full" />
    <Alerts :errors="generalErrors" />
    <LoaderComponent v-if="loading"/>
    <div v-else class="plan-content">
        <!-- Form -->
        <form v-if="person && person.DateofBirth" id="check-replacement-form" class="form" method="POST"
            @submit.prevent="submitData">
            <SelectComponent label="Check Type" v-model:selectedOption="requestType" :options="checkTypes"
                :singleArrow="true" :readonly="true" />
            <SelectComponent label="Type" v-model:selectedOption="checkType" :options="paymentMethods"
                :singleArrow="true" :is-required="true" :error="errors?.CheckType"/>
            <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
                v-model:modelValue="person.PersonID" :readonly="true" />
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="person.FirstName" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="person.LastName" :readonly="true" />
            <DateInput label="DOB" v-model:modelValue="person.DateofBirth" :readonly="true" />
            <div class="w-full" v-if="checkType.key === 'check'">
                <SelectComponent label="Check List" v-model:selectedOption="selectedCheck" :options="checkOptions"
                        :singleArrow="true" @change="handleSelectedCheck" />
                <DateInput label="Check Date" v-model:modelValue="checkDate" />
                <TextInput type="text" name="number" label="Check #" v-model:modelValue="checkNumber" />
            </div>
            <div class="headers">
                <h4 :class="{'position-relative': addressErrors}">Address for Check Destination currently on File
                    <i v-if="addressErrors" class="iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip icon-tooltip-secondary fs-lg">
                        <span class="tooltip-text left-20">{{ addressErrors }}</span>
                    </i>
                </h4>
                <h5> This is your Primary address for all correspondences from the Fund Office </h5>
            </div>

            <div class="w-full justify-content-start" v-if="address">
                <TextInput type="text" label="Address 1" placeholder="Enter Address 1" :max=30 name="mixed"
                    v-model:modelValue="address.Street1" :required="true" :is-required="true" :error="errors?.Street1"/>
                <TextInput type="text" label="Address 2" placeholder="Enter Address 2" :max=30 name="mixed"
                    v-model:modelValue="address.Street2"/>
                <TextInput type="text" label="Address 3" placeholder="Enter Address 3" :max=30 name="mixed"
                    v-model:modelValue="address.Street3"/>
                <TextInput type="text" label="Address 4" placeholder="Enter Address 4" :max=30 name="mixed"
                    v-model:modelValue="address.Street4"/>
                <TextInput type="text" name="mixed" placeholder="Enter City" :label="['US', 'CA', 'MX'].includes(address.Country?.key) ? 'City' : 'City/State'" :max=25 v-model:modelValue="address.City"
                    :required="true" :error="errors?.City" :isRequired="true" />
                <SelectComponent label="Country" v-model:selectedOption="address.Country" :options="profileStore.countries"
                    :single-arrow="true" />
                <SelectComponent label="State" v-model:selectedOption="address.State" :options="filteredStates"
                    :single-arrow="true" :error="errors?.State" :isRequired="['US', 'CA', 'MX'].includes(address.Country?.key)" :readonly="!['US', 'CA', 'MX'].includes(address.Country?.key)" />
                <TextInput type="text" label="ZIP Code" name="number" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="address.Zip" :required="true" :is-required="true" :error="errors?.Street1"/>
            </div>

            <Textarea label="Notes" v-model:modelValue="comments" :max="500" message="Notes field accepts up to 500 characters" />

            <div class="action-buttons">
                <a href="" @click.prevent="resetModal" class="btn btn-secondary"> Discard </a>
                <button type="submit" class="btn btn-primary"> Submit </button>
            </div>
        </form>
        <div v-else class="d-flex flex-column gap-4">
            <h3>To Continue Please...</h3>
            <h6>Please <a href="#" @click="openModal('profile', 'My Profile', 'plan-profile plan-tables')"> update your personal information</a> to include your correct date of birth.</h6>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, toRefs, onMounted, watch, computed, Ref, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';
import { useParticipantStore } from '@/stores/participant';
import { useVacationStore } from '@/stores/vacation';
import { usePensionStore } from '@/stores/pension';
import { CountryStates, Person, AddressInfo, VacationCheckRequestLine, SelectOption } from '@/interfaces/interfaces';
import Alerts from '@/components/utils/AlertsComponent.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';

export default defineComponent({
    components: {
        Alerts,
        TextInput,
        DateInput,
        Textarea,
        SelectComponent
    },
    props: {
        fund: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const modalStore = useModalStore();
        const profileStore = useProfileStore();
        const statesByCountry: Ref<CountryStates[] | null> = ref(null);
        const person = ref<Person>();
        const initialAddress = ref({
            Street1: null,
            Street2: null,
            Street3: null,
            Street4: null,
            Country: null,
            City: null,
            State: null,
            ZipCode: null,
        })
        const address = ref<AddressInfo>({...initialAddress.value});
        const addressErrors = computed(() => useParticipantStore().addressErrors)

        const checkTypes = [
            { key: 'vacation', value: 'Vacation' },
            { key: 'medicare', value: 'Medicare Reimbursement' },
            { key: 'pension', value: 'Pension' }
        ];

        const paymentMethods = [
            { key: 0, value: 'Select Type' },
            { key: 'directdeposit', value: 'Direct Deposit' },
            { key: 'check', value: 'Check' }
        ];

        const checks = ref<VacationCheckRequestLine[]>([]);
        const checkOptions = ref<SelectOption[]>([]);
        const selectedCheck = ref<SelectOption | null>({ key: 0, value: '-Select check' });
        const requestType = ref<SelectOption|null>(checkTypes[0]);
        const checkType = ref<SelectOption|null>(paymentMethods[0]);
        const checkDate = ref('');
        const checkNumber: Ref<string> = ref('');
        const { fund } = toRefs(props);

        const comments = ref('');
        const errors = ref<Record<string, string[]|null> | null>(null);
        const generalErrors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);

        // Methods
        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/check-replacement');
                person.value = response.data.Person;
                address.value = response.data.Address;
                checks.value = response.data.Checks;
                checkOptions.value = response.data.CheckOptions;
                initialAddress.value = JSON.parse(JSON.stringify(response.data.Address));

                if (!profileStore.countries?.length || !profileStore.statesByCountry) {
                    await profileStore.fetchCountriesStates();
                }

            }
            catch (error) {
                console.error('Error:', error);
            }
            finally {
                loading.value = false;
            }
        }

        const updateRequestTypeFromQuery = () => {
            const queryParamValue = fund.value;

            if (typeof queryParamValue === 'string' && ['vacation', 'medicare', 'pension'].includes(queryParamValue)) {
                requestType.value = checkTypes.find(type => type.key === queryParamValue) || null;
            }
        };

        const handleSelectedCheck = (option: SelectOption) => {
            if (option) {
                const check: VacationCheckRequestLine | undefined = checks.value.find((check: VacationCheckRequestLine) => check.Number === option.key);

                if (check && check.Date) {
                    checkDate.value = new Date(check.Date).toISOString().split('T')[0];
                }
            } else {
                checkDate.value = '';
            }

            checkNumber.value = option?.key as string;
        }

        watch(() => fund.value, () => {
            updateRequestTypeFromQuery();
        });

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = address.value.Country?.key;
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter(state => state.CountryKey === selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        watch(() => address.value.Country?.key, (newValue, oldValue) => {
            if(!loading.value) {
                const isStateValid = filteredStates.value.some(state => state.key == address.value.State?.key);

                if (newValue !== oldValue && address.value.State && !isStateValid) {
                    address.value.State = { key: 0, value: "Select State" };
                }
            }

            if(!['US', 'CA', 'MX'].includes(address.value.Country?.key as string) && errors.value) {
                errors.value.State = null;
            }
        })

        const resetModal = () => {
            address.value = { ...initialAddress.value};
            checkType.value = paymentMethods[0];
            comments.value = '';
            selectedCheck.value = { key: 0, value: '-Select check' };
            checkDate.value = '';
            checkNumber.value = '';
        }

        const submitData = async () => {
            const formData = {
                PersonID: person.value?.PersonID,
                FirstName: person.value?.FirstName,
                LastName: person.value?.LastName,
                DateofBirth: person.value?.DateofBirth,
                CheckCategory: requestType.value?.key ?? '',
                CheckType: checkType.value?.key,
                CheckDate: checkDate.value,
                CheckNumber: checkNumber.value,
                Street1: address.value.Street1,
                Street2: address.value.Street2,
                Street3: address.value.Street3,
                Street4: address.value.Street4,
                State: address.value.State,
                City: address.value.City,
                Country: address.value?.Country,
                ZipCode: address.value.Zip,
                Notes: comments.value,
                SelectedCheck: selectedCheck.value?.value ?? '',
            };

            const route = 'api/participant/check-replacement';

            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit, generalErrors, reset: false });
            const successSubmit = await handleSubmit();

            if (success.value) {
                useModalStore().openModal('success-modal', null, 'modal-success', null, {
                    title: "Successful",
                    content: {
                        type: "text",
                        value: success.value,
                        icon: 'iatse-icon-user-verified'
                    },
                    onConfirmButton: 'Ok',
                    onConfirm: () => resetModal()
                })
            }

            if (successSubmit) {
                errors.value = null;

                await fetchData();
                await profileStore.fetchAddress();
                await useParticipantStore().fetchGeneralData();

                if (fund.value === 'vacation') {
                    await useVacationStore().getPanelDetails();
                }

                if (fund.value === 'pension') {
                    await usePensionStore().getPanelDetails();
                }
            }
        }

        // Mounted
        onMounted(async() => await fetchData());

        updateRequestTypeFromQuery();

        return {
            addressErrors,
            profileStore,
            statesByCountry,
            filteredStates,
            person,
            comments,
            address,
            checks,
            checkOptions,
            selectedCheck,
            requestType,
            checkTypes,
            paymentMethods,
            checkType,
            checkDate,
            checkNumber,
            success,
            generalErrors,
            errors,
            loading,
            handleSelectedCheck,
            submitData,
            resetModal,
            openModal: modalStore.openModal
        }
    }
});
</script>
