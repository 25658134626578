<template>
    <div class="modal-body">
      <div class="d-flex justify-center flex-column w-100 gap-20">
        <small v-if="errors && errors.General" class="text-danger">{{ errors?.General }}</small>
        <TextInput type="email" label="Add Email Address" placeholder="Add email here..." :max=100
            v-model:modelValue="email" :error="errors?.email ?? null" :isRequired="true" />
        <!-- <TextInput type="email" label="Add Email Confirmation Address" placeholder="Add email confirmation here..." :max=100
            v-model:modelValue="emailConfirmation" :error="errors?.email_confirmation ?? null" :isRequired="true" /> -->
        <a href="" class="btn btn-primary w-100 mt-20" @click.prevent="handleSubmit">Update</a>
      </div>
    </div>
  </template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import TextInput from '@components/form/TextInput.vue';
import axios from 'axios';

export default defineComponent({
components: {
    TextInput,
},

setup() {
    const modalStore = useModalStore();
    const email = ref('');
    const emailConfirmation = ref('');
    const errors = ref<Record<string, string>>({General: ''});
    const success = ref('');

    const validateEmail = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        errors.value.General = 'Invalid email. Please check and ensure it meets the required validation criteria.';

        if(email === useParticipantStore().userEmail) {
            errors.value.General = 'Email should be different from the old one.';
            return false;
        }

        return emailRegex.test(email);
    }

    const handleSubmit = () => {
        if (validateEmail(email.value)) {
            errors.value.General = '';

            useModalStore().openModal(
                'validate-user-2fa',
                'Verify Email Address',
                'modal-success',
                null,
                {
                    onConfirm: () => {
                        submitData();
                        useModalStore().closeAbove(0);
                    }
                },
                null,
                null,
                {email: email.value, method: 'E'}

            );
        }
    }

    const submitData = async () => {
        const formData = {
            email: email.value,
            // email_confirmation: emailConfirmation.value,
        };

        axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
            .then(response => {
                success.value = response.data.success;
                errors.value = {General: ''};

                setTimeout(() => {
                    success.value = '';
                }, 3500);

                modalStore.openModal(
                    "success-modal",
                    null,
                    "modal-success",
                    null,
                    {
                        title: "Updated Successfully",
                        content: {
                            type: "text",
                            value: 'Your Email has been updated successfully!',
                            icon: "iatse-icon-user-verified"
                        },
                        onConfirmButton: "Close",
                        onConfirm: () => {
                            useParticipantStore().getUserEmail();
                        },
                    },);
            })
            .catch((error: any) => {
                if (error.response) {
                    errors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                setTimeout(() => {
                    if(errors.value && errors.value?.General) {
                        errors.value.General = '';
                    }
                }, 3500);
            })
    };

    return {
        email,
        emailConfirmation,
        success,
        errors,
        handleSubmit,
    };
},
});
</script>
