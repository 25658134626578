export function useSSNValidator() {
    const formatSSN = (value: string): { ssn: string | null, error: string | null } => {

        const ssn = value?.replace(/\D/g, ''); // Remove non-numeric characters
        let error = null;

        if (!ssn || ssn.length !== 9) {  // Check if SSN length is exactly 9
            error = 'SSN must be exactly 9 digits long.';
        }

        // Format the SSN
        let ssnValue = '';
        if (ssn.length <= 3) {
            ssnValue = ssn;
        } else if (ssn.length <= 5) {
            ssnValue = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
        } else {
            ssnValue = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
        }

        // Perform validation checks
        const areaNumber = parseInt(ssn.slice(0, 3), 10);
        const groupNumber = parseInt(ssn.slice(3, 5), 10);
        const serialNumber = parseInt(ssn.slice(5, 9), 10);

        // Check for invalid area numbers
        if (areaNumber === 0 || areaNumber === 666 || (areaNumber >= 900 && areaNumber <= 999)) {
            error = 'Invalid area number';
        }

        // Check for invalid group numbers
        if (groupNumber === 0) {
            error = 'Invalid group number';
        }

        // Check for invalid serial numbers
        if (serialNumber === 0) {
            error = 'Invalid serial number';
        }

        // Check for all zeros in any segment
        if (/^(000|666|900|999)-\d{2}-\d{4}$/.test(ssnValue) || /^000-\d{2}-\d{4}$/.test(ssnValue)) {
            error = 'SSN cannot contain a segment with all zeros.';
        }

        // Check for nine identical digits
        if (/^(\d)\1{8}$/.test(ssn)) {
            error = 'SSN cannot consist of identical digits in all positions.';
        }

        // Check for nine consecutive digits
        if (/^(012345678|123456789)$/.test(ssn)) {
            error = 'SSN cannot contain all consecutive digits.';
        }

       // Return formatted SSN and error if validation fails
       return { ssn: ssnValue, error };
    };

    const isSSNValid = (value: string): boolean => {
        return formatSSN(value) !== null;
    };

    return {
        formatSSN,
        isSSNValid
    };
}
