<template>
  <LoaderComponent v-if="loading" />
  <template v-else>
    <!-- For viewing PDFs in the browser -->
    <iframe v-if="isPdf" :src="documentUrl" width="100%" height="1000" id="smartsearch"></iframe>

    <!-- For Word Documents, display a download link -->
    <a class="text-center w-100" v-else-if="isDocx" :href="documentUrl" download>Download Word Document</a>

    <!-- For Excel Documents, display a download link -->
    <a class="text-center w-100" v-else-if="isExcel" :href="documentUrl" download>Download Excel Document</a>

    <!-- For Images, display the image in an <img> tag -->
    <img v-else-if="isImage" :src="documentUrl" alt="Image" width="100%" />
    <p class="text-center w-100" v-else>Couldn't load the document.</p>
  </template>

</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from 'vue';
import DocumentService from '@/services/documentsService';

export default defineComponent({
  props: {
    modalProps: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const loading = ref(true);
    const documentUrl = ref<string | null>(null);
    const isPdf = ref(false);
    const isImage = ref(false);
    const isDocx = ref(false);
    const isExcel = ref(false);
    const isHtml = ref(false);
    const blob = ref<Blob | null>(null);

    const fetchDocument = async () => {
      // View SmartSearch uploaded document
      if (props.modalProps.DocumentID) {
        try {
          blob.value = await DocumentService.viewDocument(props.modalProps.DocumentID);
          documentUrl.value = URL.createObjectURL(blob.value);

        } catch (error) {
          if (error instanceof Error) {
            console.error(error.message);
          } else {
            console.error('An error occurred', error);
          }
        }
      } else if (props.modalProps.FilePreview) { // View storage uploaded document
        try {
          blob.value = await DocumentService.viewStorageDocument(props.modalProps.FilePreview, props.modalProps?.Type);
          documentUrl.value = URL.createObjectURL(blob.value);
        } catch (error) {
          if (error instanceof Error) {
            console.error(error.message);
          } else {
            console.error('An error occurred', error);
          }
        }
      }

      const contentType = blob.value?.type;

      if (contentType === 'application/pdf') {
        isPdf.value = true;
      } else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        isDocx.value = true;
      } else if (contentType?.startsWith('image/')) {
        isImage.value = true;
      } else if (contentType === 'application/vnd.ms-excel' || contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        isExcel.value = true;
      } else if (contentType === 'text/html') {
        isHtml.value = true;
      }

      loading.value = false;
    };

    onMounted(async () => await fetchDocument());

    return {
      documentUrl, loading, isPdf, isDocx, isImage, isExcel, isHtml
    };
  },
});
</script>