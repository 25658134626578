import axios from 'axios';

export const getFundResources = async (queryParams = {}) => {
  try {
    const response = await axios.get(`api/user/resources`, {
      params: queryParams,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching fund resources:', error);
    throw error;
  }
};

export const getAnnouncements = async () => {
    try {
      const response = await axios.get(`api/user/announcements`);
      return response.data;
    } catch (error) {
      console.error('Error fetching announcements:', error);
      throw error;
    }
};

export const getFunds = async () => {
  try {
    const response = await axios.get(`api/user/funds`);
    return response.data;
  } catch (error) {
    console.error('Error fetching funds:', error);
    throw error;
  }
};

export const getFaqs = async () => {
  try {
    const response = await axios.get(`api/user/faqs`);
    return response.data;
  } catch (error) {
    console.error('Error fetching faqs:', error);
    throw error;
  }
};
