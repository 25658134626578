<template>
    <FundPanel cardId="card-hw" :alerts="alerts">
        <template #icon>
            <i class="iatse-icon-fund-hw"></i>
        </template>
        <template #title>
            Health &amp; Welfare
        </template>
        <template #fund-cover>
            <div class="col-md-9">
                <div class="fund-cover fund-cover__hw">
                    <div class="fund-cover-header">
                        <h4>Current Coverage</h4>
                        <a href="" v-if="showNextCoverageLink" @click.prevent="emitUpcomingCoverage" class="btn btn-secondary plan-modal upcoming-coverage">Upcoming Coverage</a>
                    </div>
                    <div class="fund-coverages" v-if="hasPlanCCurrentInvoice">
                        <div class="coverage-row">
                            <div class="coverage-column">
                                <h3 class="plan-modal">
                                    {{ 
                                    data.PlanCInvoices?.Current?.Paid ?
                                        'Your cov. selection of ' + data.PlanCInvoices?.Current?.SelectedPlanCCoverage + ' for ' 
                                        + data.PlanCInvoices?.Current?.CappCoveragePeriod  + ' - ' + data.PlanCInvoices?.Current?.CappCoveragePeriodEnd
                                        + ' is in process'
                                        : 'Not Currently Enrolled'
                                    }}
                                </h3>
                            </div>
                            <div class="coverage-column-items">
                                <div class="coverage-column">
                                    <i class="iatse-icon-calendar"></i>
                                    <p class="links">Coverage Period: TBD </p>
                                </div>
                            </div>
                        </div>
                        <div class="coverage-column__actions d-flex align-items-center" v-if="!data.PlanCInvoices.Current.Paid">
                            <a href="#" 
                                class="btn btn-primary plan-modal"
                                 v-if="!data.PlanCInvoices?.Current?.Paid && !data.HasPendingMrpDocs" 
                                @click.prevent="openModal('hw-capp-statement', 'Make Coverage Selection / Co-Payment', 'plan-upload plan-card-info plan-c-mrp-claims')">
                                Select/Pay Coverage 
                            </a>
                            <p class="pdl-30">Due Date: {{ data.PlanCInvoices?.Current?.DueDate }}</p>
                        </div>
                    </div> <!-- #end -->
                    <div class="fund-coverages" v-if="hasPlanCobraCurrentInvoice">
                        <div class="coverage-row">
                            <div class="coverage-column">
                                <h3 class="plan-modal">{{ data.CobraInvoices?.Current?.IsPaid ? 'Your payment was successfully and will be processed soon.' : 'Not Currently Enrolled' }}</h3>
                            </div>
                            <div class="coverage-column-items">
                                <div class="coverage-column">
                                    <i class="iatse-icon-calendar"></i>
                                    <p class="links">Coverage Period: TBD </p>
                                </div>
                            </div>
                        </div>
                        <div class="coverage-column__actions d-flex align-items-center" v-if="!data.CobraInvoices?.Current?.IsPaid">
                            <a href="#" class="btn btn-primary plan-modal"
                                @click.prevent="openModal('hw-cobra-statements', 'Make COBRA Payment', 'plan-upload plan-card-info plan-c-mrp-claims')">
                                Make COBRA Payment
                            </a>
                            <p class="pdl-30">Due Date: {{ data.CobraInvoices?.Current?.DueDate }}</p>
                        </div>
                    </div> <!-- #end -->
                    <div class="fund-coverages" v-if="hasPlanCUpcomingInvoice">
                        <div class="coverage-row">
                            <div class="coverage-column pdr-15">
                                <h3 class="plan-modal">
                                    {{
                                        data.PlanCInvoices?.Upcoming?.Paid ?
                                         'Your cov. selection of ' + data.PlanCInvoices?.Upcoming?.SelectedPlanCCoverage + ' for ' 
                                         + data.PlanCInvoices?.Upcoming?.CappCoveragePeriod + ' - ' + data.PlanCInvoices?.Upcoming?.CappCoveragePeriodEnd
                                         + ' is in progress.'
                                        : 'Not Currently Enrolled'
                                    }}
                                </h3>
                            </div>
                            <div class="coverage-column-items">
                                <div class="coverage-column">
                                    <i class="iatse-icon-calendar"></i>
                                    <p class="links">Coverage Period: TBD </p>
                                </div>
                            </div>
                        </div>
                        <div class="coverage-column__actions d-flex align-items-center" v-if="!data.PlanCInvoices?.Upcoming?.Paid">
                            <a href="#" 
                                class="btn btn-primary plan-modal" 
                                @click.prevent="openModal('hw-capp-statement', 'Make Coverage Selection / Co-Payment', 'plan-upload plan-card-info plan-c-mrp-claims')">
                                Select/Pay Coverage
                            </a>
                            <p v-if="!data.PlanCInvoices?.Upcoming?.Paid" class="pdl-30">Due Date: {{ data.PlanCInvoices?.Upcoming?.DueDate }}</p>
                        </div>
                    </div> <!-- #end -->
                    <div class="fund-coverages"  v-else-if="hasPlanCobraUpcomingInvoice">
                        <div class="coverage-row">
                            <div class="coverage-column-items">
                                <div class="coverage-column">
                                    <i class="iatse-icon-calendar"></i>
                                    <p class="links">Coverage Period: TBD </p>
                                </div>
                            </div>
                        </div>
                        <div class="coverage-column__actions">
                            <a href="#" class="btn btn-primary plan-modal"
                                @click.prevent="openModal('hw-cobra-statements', 'Make COBRA Payment', 'plan-upload plan-card-info plan-c-mrp-claims')">
                                Make COBRA Payment
                            </a>
                            <p v-if="!data.CobraInvoices?.Upcoming?.Paid" class="pdl-30">Due Date: {{ data.CobraInvoices?.Upcoming?.DueDate }}</p>
                        </div>
                    </div> <!-- #end -->
                    <div class="fund-coverages" v-if="!hasPlanCCurrentInvoice && !hasPlanCobraCurrentInvoice && !hasPlanCUpcomingInvoice && !hasPlanCobraUpcomingInvoice">
                        <div class="coverage-row">
                            <div class="coverage-column">
                                <h3 class="plan-modal">Not Currently Enrolled</h3>
                            </div>
                        </div>
                    </div> <!-- #end -->
                    <div v-if="data.HasRetireePaymentsHistory" class="mt-10">
                        <a class="btn btn-secondary plan-modal inline-block" @click.prevent="openModal('hw-retiree-payments-history', 'Payments History', 'plan-retiree')">Retiree Payments History</a>
                    </div>
                </div>
            </div>
         </template>
         <template #related-links>
             <a href="" class="btn btn-tertiary btn-large plan-modal" v-if="data.IsAvailableForClaim"
                @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')">View/Submit
             Claims</a>
             <a href="" class="btn btn-tertiary btn-large plan-modal"
                 @click.prevent="openModal('dependents', 'My Dependents', null)"> Dependents
             </a>
             <a href="" @click.prevent="openModal('plan-resources', 'Health & Welfare Resources', null, 'Health and Welfare')" class="btn btn-tertiary btn-large plan-modal">Funds Resources</a>
         </template>
     </FundPanel>
 </template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { HealthDashboardInfo } from '@/interfaces/health';
import { useModalStore } from '@/stores/modal';
import FundPanel from '@/components/cards/FundPanel.vue';
export default defineComponent({
    props: {
        alerts: Array,
        data: {
            type: Object as () => HealthDashboardInfo,
            required: true
        },
        showNextCoverageLink: {
            type: Boolean,
            required: true
        },
        hasPlanCUpcomingInvoice: {
            type: Boolean,
            required: true
        },
        hasPlanCCurrentInvoice: {
            type: Boolean,
            required: true
        },
        hasPlanCobraUpcomingInvoice: {
            type: Boolean,
            required: true
        },
        hasPlanCobraCurrentInvoice: {
            type: Boolean,
            required: true
        }
    },
    emits: ['upcoming-coverage'],
    setup(props, { emit }) {
        const { data } = toRefs(props);
        const openModal = useModalStore().openModal;
        const emitUpcomingCoverage = () => {
            emit('upcoming-coverage');
        };
        return {
            openModal, emitUpcomingCoverage
        };
    },
    components: {
        FundPanel
    }
});
</script>