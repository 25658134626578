<template>
    <AlertsComponent :error="error" />
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>
    <div class="plan-content">
        <h2 class="sm-fs-base">Download </h2>
        <div class="bordered-card bordered-card_secondary w-100 overflow-auto">
            <table class="small mb-4">
                <thead>
                    <tr class="text-center">
                        <th>Date/Time Requested</th>
                        <th>For Coverage <br /> Year</th>
                        <th># of Employees <br /> in Req</th>
                        <th>Requestor</th>
                        <th>File Name</th>
                        <th>Download As</th>
                    </tr>
                </thead>
                <tbody v-if="requests?.length">
                    <tr v-for="request in requests" :key="request.RequestID">
                        <td> {{ request.Date }}</td>
                        <td> {{ request.Year }}</td>
                        <td> {{ request.EmployeesNumber }}</td>
                        <td> {{ request.Requestor }}</td>
                        <td> {{ request.FileName }}</td>
                        <td>
                            <i class="iatse-icon-csv fs-xxl mr-10 plan-modal" @click="handleDownload(request, 'xlsx')"></i>
                            <i class="iatse-icon-pdf-file fs-xxl plan-modal" @click="handleDownload(request, 'pdf')"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="6" class="text-center"> No Available Requests</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="action-buttons pdt-100">
        <button type="button" class="btn btn-secondary" @click="handleBack">
            Back to Home
         </button>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
import { HealthCoverageRequest } from '@/interfaces/employer';
import { useModalStore } from '@/stores/modal';
import { saveAs } from 'file-saver';
import axios from 'axios';

export default defineComponent({
    props: {
        requests: Array as PropType<HealthCoverageRequest[]>
    },
    setup() {
        const error = ref<string | null>(null);
        const loading = ref(false);

        const handleDownload = async (request: HealthCoverageRequest, type: string) => {
            request.Downloading = true;
            loading.value = true;

            try {
                const response = await axios.get(`api/employer/health-coverage-data/download-file/${request.FileName}?type=${type}`, {
                    responseType: 'blob',
                });

               // Ensure the response is of the correct content type
                const contentType = response.headers['content-type'];
                // Create a Blob with the correct type
                const blob = new Blob([response.data], { type: contentType });

                const modifiedFileName = request.FileName.replace(/\.[^/.]+$/, '') + '.' + type;

                saveAs(blob, modifiedFileName);
            }
            catch (res) {
                error.value = 'File not found';
                setTimeout(() => error.value = null, 3000)
                console.error('Error downloading file:', res);
            }

            request.Downloading = false;
            loading.value = false;
        }

        const handleBack = () => {
           useModalStore().closeLastModal();
        }

        return {
            error,
            loading,
            handleDownload,
            handleBack
        }
    }
})
</script>