<template>
    <Alerts :success="success" :info="info" :errors="errors" />

    <LoaderComponent v-if="loading" />
    <div v-else class="table-wrapper">
        <h4> View or modify current Mailing Address and Payment setup</h4>

        <!-- Change Mailing/Deposit Cards -->
        <div class="table-item">
            <TextInput type="text" label="My Mailing Address" placeholder="Enter My Mailing Address" :max=50
                v-model:modelValue="address" :readonly="true" />
            <a href="" class="btn btn-secondary plan-modal"
                @click.prevent="openModal('check-destination', 'Payment Destionation Setup', 'plan-form', 'medicare', 'check')">
                Change my Mailing Address
            </a>
        </div>

        <div class="table-item">
            <TextInput type="text" label="My Payment Setup Currently is"
                placeholder="Enter My Payment Setup Currently is" :max=50 v-model:modelValue="directDeposit" :readonly="true"/>
            <a href="" class="btn btn-secondary plan-modal" @click.prevent="handleSwitch">
                <span v-if="!switchCheck">Switch to check </span>
                <span v-else>Switch to Direct Deposit </span>
            </a>
            <!-- <a href="" class="btn btn-secondary plan-modal" @click.prevent="handleChangeDeposit">
                Change Direct Deposit Information
            </a> -->
        </div>
    </div>

    <!-- Update Direct Deposit Form -->
    <!-- <div v-if="changeDeposit && !switchCheck" class="card">
        <UpdateDeposit :checkDeposit="checkDeposit" :closeForm="closeForm" :loadingSave="loadingSave"
            @handle-cancel="handleChangeDeposit" @save-deposit="handleSaveDeposit" />
    </div> -->
</template>

<script lang="ts">
import { Ref, ref, defineComponent, onMounted, watch } from 'vue';
import { useModalStore } from '@/stores/modal';
import { CheckDeposit } from '@/interfaces/health';
import axios from 'axios';

import Alerts from "@components/utils/AlertsComponent.vue";
import TextInput from '@components/form/TextInput.vue';

export default defineComponent({
    components: {
        Alerts,
        TextInput,
    },
    setup() {
        const loading = ref(true);
        const loadingSave = ref(false);
        const closeForm = ref(false);
        const success = ref(null);
        const info = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const address: Ref<string | null> = ref(null);
        const checkDeposit: Ref<CheckDeposit | null> = ref(null);
        const directDeposit: Ref<string | null> = ref(null);
        const changeDeposit = ref(false);
        const switchCheck = ref(false);

        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get('api/participant/claims-new')
                .then(response => {
                    address.value = response.data.Address;
                    checkDeposit.value = response.data.CheckDirectDeposit;
                    switchCheck.value = response.data.CheckDirectDeposit?.BankClassDescription;
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const handleSaveDeposit = async (deposit: CheckDeposit) => {
            loadingSave.value = true;

            await axios
                .post('api/participant/claims-update-deposit', { ...deposit, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                    info.value = response.data?.info;

                    if (success.value) {
                        closeForm.value = true;
                        fetchData(false);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        success.value = null;
                        errors.value = []
                    }, 3500);

                    loadingSave.value = false;
                })
        }

        watch(() => switchCheck.value, (newValue) => {
            console.log(newValue, 'test')
            const deposit = checkDeposit.value?.BankClassDescription ?? 'N/A';
            directDeposit.value = newValue ? `DIRECT DEPOSIT to ${deposit}` : 'CHECK to mailing address';
        });

        const handleChangeDeposit = (payload: boolean) => {
            changeDeposit.value = payload;
        }

        const handleSwitch = async () => {
            if (!switchCheck.value) {
                await axios.post('api/participant/claims-switch-deposit', { _method: "PATCH" })
                    .then(response => {
                        success.value = response.data.success;
                    })
                    .catch(error => {
                        const res = error.response.data;

                        if (res.error) {
                            errors.value = res.error;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            success.value = null;
                            errors.value = []
                        }, 3500);
                    });
            }

            switchCheck.value = !switchCheck.value;
        }

        onMounted(async () => await fetchData(true));

        return {
            loading,
            address,
            checkDeposit,
            changeDeposit,
            directDeposit,
            switchCheck,
            closeForm,
            loadingSave,
            success,
            info,
            errors,
            handleSwitch,
            handleSaveDeposit,
            handleChangeDeposit,
            openModal: useModalStore().openModal
        }
    }
})
</script>