import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 1,
  class: "col-md-12"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_DependentStep1 = _resolveComponent("DependentStep1")!
  const _component_DependentStep2 = _resolveComponent("DependentStep2")!
  const _component_SuccessModal = _resolveComponent("SuccessModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, {
      success: $setup.success,
      info: _ctx.info,
      errors: $setup.errors
    }, null, 8, ["success", "info", "errors"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Alerts, { errors: $setup.errors }, null, 8, ["errors"]),
      _createElementVNode("div", _hoisted_2, [
        ($setup.loading)
          ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("form", null, [
                (!$setup.isValidStep1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_DependentStep1, {
                        data: $setup.data,
                        onCheckData: $setup.checkData
                      }, null, 8, ["data", "onCheckData"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_DependentStep2, {
                        documentTypes: $setup.documentTypes,
                        onCheckData: $setup.checkData,
                        onSubmitData: $setup.handleSubmit
                      }, null, 8, ["documentTypes", "onCheckData", "onSubmitData"])
                    ]))
              ])
            ]))
      ]),
      ($setup.isModalVisible)
        ? (_openBlock(), _createBlock(_component_SuccessModal, {
            key: 0,
            message: $setup.success,
            title: "Success",
            route: "dependents"
          }, null, 8, ["message"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}