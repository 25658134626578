<template>
    <div class="pdl-10 pdt-30 pdr-10">
        <LoaderComponent v-if="loading" />
        <div v-else class="d-flex flex-column gap-25">
            <div class="d-flex gap-25 flex-md-row flex-column">
                <div class="w-35 d-flex flex-column gap-25 w-sm-100">
                    <ProfilePanel :data="dashboard?.Profile" type="Local" />
                    <MembersPanel :cappReport="dashboard?.CappReport" />
                </div>
                <div class="w-70 w-sm-100">
                    <RecentAnnouncements :announcements="announcements"/>
                </div>
            </div>
            <ReportsPanel :contributions="dashboard?.ContributionPreview" />
            <ResourcesPanel :resources="resources" />
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useMembersStore } from '@/stores/members'
import { useContributionsStore } from '@/stores/contributions';
import { useParticipantStore } from '@/stores/participant';
import { useLocalStore } from '@/stores/local';
import { useAuthStore } from '@/stores/auth';

import ReportsPanel from '@components/local/dashboard/ReportsPanel.vue';
import MembersPanel from '@/components/local/dashboard/MembersPanel.vue';
import ResourcesPanel from '@components/local/dashboard/ResourcesPanel.vue';
import ProfilePanel from '@components/user/dashboard/ProfilePanel.vue';
import RecentAnnouncements from '@components/user/dashboard/RecentAnnouncements.vue';
import router from '@/router';

export default {
    components: {
        ProfilePanel,
        MembersPanel,
        RecentAnnouncements,
        ReportsPanel,
        ResourcesPanel
    },
    setup() {
        const participantStore = useParticipantStore();
        const localStore = useLocalStore();
        const loading = ref(true);
        const route = useRoute();
        const success: Ref<string | null> = ref(null);
        const alerts = computed(() => participantStore.alerts?.fundData);
        const dashboard = computed(() => localStore.dashboard);
        const resources = computed(() => localStore.resources);
        const announcements =computed(() => localStore.announcements);

        const fetchData = async () => {
            loading.value = true;
            success.value = route.params.successMessage as string;
            useMembersStore().setList([]);
            useContributionsStore().resetData();

            if (success.value) {
                setTimeout(() => success.value = null, 3500);
            }

            if (!alerts?.value) {
                participantStore.fetchAlerts();
            }

            if (!dashboard?.value) {
                localStore.fetchDashboard();
            }

            if (!resources?.value || !announcements?.value) {
                localStore.fetchResources();
            }

            loading.value = false;
        };

        onMounted(async() => {
            if(!useAuthStore().twoFaValidated && useAuthStore().user?.active2FA) {
                if(useAuthStore().user?.preferredMethod) {
                    await router.push({ name: '2fa.verify'});
                } else {
                    await router.push({ name: '2fa.setup'});
                }
            } else {
                await fetchData();
            }
        })

        return {
            loading,
            success,
            dashboard,
            resources,
            announcements,
        };
    }
}
</script>