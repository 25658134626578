import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "action-buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.otherCoverages)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.otherCoverages?.Retiree?.Show)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "btn btn-primary plan-modal",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.openModal('hw-retiree-payments-history', 'Payments History', 'plan-retiree')), ["prevent"]))
            }, " Retiree Payments History "))
          : _createCommentVNode("", true),
        (_ctx.otherCoverages.A.Show)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              class: "btn btn-primary plan-modal",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.openModal('hw-plan-a-summary', 'Plan A Summary', 'plan-a-family', null, null, null, null, { FromDate: _ctx.otherCoverages.A.From })), ["prevent"]))
            }, " Plan A "))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}