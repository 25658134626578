<template>
  <div class="container mt-4">
    <Alerts :errors="errors" :success="success" />
    <div class="col-md-12 text-center">
      <h1>Update Contact Information</h1>
    </div>
    <div class="row mb-4">
      <span>Enter the information you wish to update in the applicable field. These changes automatically update your
        account.
      </span>
    </div>
    <div v-if="loading">Loading...</div>
    <form v-else @submit.prevent="handleSubmit">
      <div class="col-md-12 mb-4">
        <h3>Phone Number</h3>
        <div id="phoneContainer" class="mb-4">
          <PhoneNumber v-for="phone in phones" :key="phone.CMIID" :phone="phone" :phoneTypes="phoneTypes"
            :selectedPrimary="selectedPrimary" @update-data="updateData" @delete-phone="deletePhone" />
        </div>
        <div v-if="showNewPhoneTemplate" class="mb-4">
          <PhoneNumber :key="'newPhoneTemplate'" :phone="newPhone" :phoneTypes="phoneTypes"
            :selectedPrimary="selectedPrimary" @update-data="updateData" is-new />
        </div>
        <button v-if="!showNewPhoneTemplate" type="button" class="btn btn-primary mb-4" @click="addPhone"> + Add another
          phone number</button>
        <div v-if="emails" class="col-md-12">
          <h3>Web Email Address</h3>
          <WebEmail v-for="email in emails" :key="email.CMIID" :email="email" @update-data="updateData" />
        </div>
        <div class="form-group row mb-4">
          <router-link :to="{ name: 'profile' }" class="col-md-8">
            <button type="button" class="btn btn-primary col-md-2">{{ ('Back') }}</button>
          </router-link>
          <button type="submit" class="btn btn-primary col-md-4">
            {{ loadingSubmit ? 'Submitting...' : 'Update Information' }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, reactive, Ref } from 'vue';
import { Contact } from "@interfaces/interfaces";
import useFormSubmit from '@composable/useFormSubmit';
import PhoneNumber from '@components/profile/contact-info/PhoneNumber.vue';
import WebEmail from '@components/profile/contact-info/WebEmail.vue';
import Alerts from "@/components/utils/AlertsComponent.vue";
import axios from 'axios';

interface FormData {
  Phones: Contact[];
  Emails: Contact[],
  PhoneTypes: string[],
}

export default {
  components: {
    PhoneNumber,
    WebEmail,
    Alerts
  },

  setup() {
    const route = 'api/user/profile/my-contact-information';
    const formData: FormData = reactive({ Phones: [], Emails: [], PhoneTypes: [] });
    const phones: Ref<Contact[]> = ref([]);
    const emails: Ref<Contact[]> = ref([]);
    const phoneTypes: Ref<string[]> = ref([]);
    const errors: Ref<string[]> = ref([]);
    const success = ref('');
    const info = ref('');
    const loading = ref(false);
    const loadingSubmit = ref(false);
    const showNewPhoneTemplate = ref(false);
    const selectedPrimary: Ref<number> = ref(0);
    const newPhone: Ref<Contact> = ref({
      CMIID: 0,
      ContactInfo: '',
      TypeCode: 8,
      PrimaryFlag: '1',
    });

    // Fetch Data
    const fetchData = async () => {
      loading.value = true;

      await axios.get(route)
        .then(response => {
          phones.value = [...response.data.Phones];
          emails.value = response.data.Emails;
          phoneTypes.value = response.data.PhoneTypes;
          Object.assign(formData, response.data);
          const existingPrimaryPhone = (formData as FormData)?.Phones?.find(phone => phone.PrimaryFlag == 1);

          if (existingPrimaryPhone) {
            selectedPrimary.value = Number(existingPrimaryPhone.CMIID);
          }

          loading.value = false;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    };

    // Handle Display New Phone Template
    const addPhone = () => {
      showNewPhoneTemplate.value = true;
    }

    // Handle Form Submit
    const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });

    // Handle Updates
    const updateData = (newData: Contact, action: string) => {
      const id = Number(newData.CMIID);
      let phoneExists = false;

      switch (action) {
        case 'update-phone':
          formData.Phones?.forEach((phone, index) => {
            if (phone.CMIID === id) {
              formData.Phones[index] = { ...formData.Phones[index], ...newData };
              phoneExists = true;
            }
          });

          if (!phoneExists) {
            formData.Phones.push(newData);
            selectedPrimary.value = id;
          }
          break;
        case 'update-email':
          formData.Emails.forEach((email: Contact, index: number) => {
            if (email.CMIID === id) {
              formData.Emails[index] = { ...formData.Emails[index], ...newData };
            }
          });
          break;
        case 'set-primary':
          selectedPrimary.value = id;

          formData.Phones?.forEach((phone: Contact, index: number) => {
            formData.Phones[index].PrimaryFlag = phone.CMIID === id ? 1 : 0;
          });
          break;
        default:
          break;
      }
    }

    // Handle Delete Phone
    const deletePhone = async (phoneId: number) => {
      try {
        const response = await axios.get(`api/user/profile/my-contact-information/delete-phone/${phoneId}`);
        success.value = response.data.success;
        phones.value = phones.value.filter(phone => phone.CMIID !== phoneId); //Filter Phones to not include anymore the deleted one
      } catch (error) {
        console.error('Error deleting phone:', error);
      }
    }

    onMounted(async () => await fetchData());

    return {
      phones,
      emails,
      phoneTypes,
      errors,
      success,
      loading,
      loadingSubmit,
      selectedPrimary,
      showNewPhoneTemplate,
      newPhone,
      handleSubmit,
      updateData,
      deletePhone,
      addPhone,
    };
  },
}
</script>
