import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form"
}
const _hoisted_2 = { class: "w-100 d-flex gap-4" }
const _hoisted_3 = { class: "w-100 d-flex gap-4" }
const _hoisted_4 = { class: "w-100 d-flex gap-4" }
const _hoisted_5 = {
  key: 1,
  class: "form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_DateInput = _resolveComponent("DateInput")!

  return (_ctx.person)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createVNode(_component_TextInput, {
          type: "text",
          label: "Participant ID",
          modelValue: _ctx.person.PersonID,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.person.PersonID) = $event)),
          required: true,
          error: _ctx.changes?.PersonID,
          readonly: true,
          customClass: "w-100"
        }, null, 8, ["modelValue", "error"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Salutation",
            placeholder: "Salutation",
            max: 12,
            modelValue: _ctx.person.Salutation,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.Salutation) = $event)),
            readonly: true,
            required: true,
            "has-changes": _ctx.changes?.Salutation
          }, null, 8, ["modelValue", "has-changes"]),
          _createVNode(_component_TextInput, {
            type: "text",
            label: "First Name",
            placeholder: "Enter First Name",
            max: 12,
            modelValue: _ctx.person.FirstName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.person.FirstName) = $event)),
            readonly: true,
            required: true,
            "has-changes": _ctx.changes?.FirstName
          }, null, 8, ["modelValue", "has-changes"]),
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Middle Name",
            placeholder: "Enter Middle Name",
            max: 12,
            modelValue: _ctx.person.MiddleName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.person.MiddleName) = $event)),
            readonly: true,
            "has-changes": _ctx.changes?.MiddleName
          }, null, 8, ["modelValue", "has-changes"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Last Name",
            placeholder: "Enter Last Name",
            max: 25,
            modelValue: _ctx.person.LastName,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.person.LastName) = $event)),
            readonly: true,
            "has-changes": _ctx.changes?.LastName
          }, null, 8, ["modelValue", "has-changes"]),
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Salutation",
            placeholder: "Suffix",
            modelValue: _ctx.person.NameSuffix,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.person.NameSuffix) = $event)),
            readonly: true,
            required: true,
            "has-changes": _ctx.changes?.NameSuffix
          }, null, 8, ["modelValue", "has-changes"]),
          _createVNode(_component_DateInput, {
            label: "Date of Birth",
            modelValue: _ctx.person.DateofBirth,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.person.DateofBirth) = $event)),
            placeholder: 'dd/mm/yyyy',
            "input-type": "date",
            readonly: true,
            "has-changes": _ctx.changes?.DateofBirth
          }, null, 8, ["modelValue", "has-changes"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Gender",
            modelValue: _ctx.person.Sex,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.person.Sex) = $event)),
            readonly: true,
            "has-changes": _ctx.changes?.Sex
          }, null, 8, ["modelValue", "has-changes"]),
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Marital Status",
            modelValue: _ctx.person.MaritalStatus,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.person.MaritalStatus) = $event)),
            readonly: true,
            "has-changes": _ctx.changes?.MaritalStatus
          }, null, 8, ["modelValue", "has-changes"]),
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Home Local",
            modelValue: _ctx.person.HomeLocal,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.person.HomeLocal) = $event)),
            readonly: true,
            "has-changes": _ctx.changes?.HomeLocal
          }, null, 8, ["modelValue", "has-changes"])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, " Data is not available "))
}