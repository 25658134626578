import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import { PersonDemographicInfo } from "@/interfaces/employer";
import { ProfileData } from '@interfaces/interfaces';
import { getFundResources, getAnnouncements } from '@/services/publicService';
import { FundMaterials, Announcements, Member, PendingMember } from '@interfaces/local';

import axios from "axios";

interface LocalDashboard {
    Profile: ProfileData | null,
    CappReport: string| null,
}

export const useLocalStore = defineStore("local", () => {
    const demographics = ref<PersonDemographicInfo[]>([]);
    const general = ref(null);
    const dashboard = ref<LocalDashboard | null>(null);
    const resources = ref<FundMaterials | null>(null);
    const announcements = ref<Announcements | null>(null);
    const representative = reactive({
        members: [] as Member[],
        pendingMembers: [] as PendingMember[],
    });

    // Actions
    const fetchDashboard = async () => {
        try {
            const response = await axios.get("api/local/dashboard");
            dashboard.value = response.data;
        } catch (error) {
            console.error("There was an error fetching the dashboard!", error);
        }
    };

    // Actions
    const fetchResources = async () => {
        resources.value =await getFundResources();
        announcements.value = await getAnnouncements();
    };

    const fetchDemographics = async () => {
        try {
            const response = await axios.get("api/user/demographic-information");
            demographics.value = response.data.Records;
        } catch (error) {
            console.error("There was an error fetching demographics!", error);
        }
    };

    const fetchGeneralInfo = async () => {
        try {
            const response = await axios.get("api/local/profile");
            general.value = response.data.Data;
        } catch (error) {
            console.error("There was an error fetching general info!", error);
        }
    };

    const fetchRepresentativeAccounts = async() => {
        try {
            const response = await axios.get("api/local/representative-accounts");
            representative.members = response.data.Members;
            representative.pendingMembers = response.data.PendingMembers;
        } catch (error) {
            console.error("There was an error fetching general info!", error);
        }
    }

    const getBAAMessage = () => {
        const template = dashboard.value?.Profile?.BaaTemplateDoc;

        if (template) {
            return `Click <a href="${template}" target="_blank">here</a> to download a BAA. You can also download the file from the Form & Documents section on your Local dashboard`;
        }

        return '';
    }

    const reset = () => {
        demographics.value = [];
        general.value = null;
        dashboard.value = null;
        resources.value = null;
        announcements.value = null;
    };

    return {
        // State
        demographics,
        general,
        dashboard,
        resources,
        announcements,
        representative,

        // Actions
        fetchDashboard,
        fetchDemographics,
        fetchGeneralInfo,
        fetchResources,
        fetchRepresentativeAccounts,
        getBAAMessage,
        reset,
    };
});
